import React from "react";
import { DataContext } from "./context/DataContext";
import { BarChart, Bar, XAxis, YAxis, Legend, Cell } from "recharts";
import { multiColor, generatePriceTicks } from "./utils";

export const MunicipalityBarChart1 = ({
  width = 730,
  height = 550,
  showLegend = true,
}) => {
  const { safelyGetData } = React.useContext(DataContext);
  const data = safelyGetData("neighb_combinatorial").map((n) => ({
    name: n[1],
    price: n[3],
  }));

  return (
    <div className={`${window.innerWidth < 1200 && "no-left-margin"}`}>
      <BarChart width={width} height={height} data={data}>
        <XAxis
          dataKey="name"
          interval={data.length > 3 ? 0 : ""}
          angle={data.length > 3 ? -80 : 0}
          tickSize={data.length > 3 ? 10 : 0}
          tickMargin={data.length > 3 ? 40 : 5}
          tickLine={true}
          height={data.length > 3 ? 100 : 30}
        />
        <YAxis
          height={400}
          type="number"
          domain={["dataMin -100", "dataMax"]}
          interval="preserveStartEnd"
          ticks={generatePriceTicks(data, "price")}
        ></YAxis>
        {showLegend && <Legend />}

        <Bar dataKey="price">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={multiColor(index)} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};
