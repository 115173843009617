import React from "react";
import { culcPercentage, reformForVericalBarChart } from "./utils";
import { LanguageContext } from "./context/LanguageContext";
import { MultipleAreaChart } from "./Shared/MultipleAreaChart";
import { VerticalBarChart2 } from "./Shared/VerticalBarChart2";
import { AsteriscSubnote } from "./Shared/AsteriscSubnote";

// slightly scalable
export const CombinedAreaChart = ({ width = 1000, data, height }) => {
  const { getLabels } = React.useContext(LanguageContext);

  const getLeftMargin = () => {
    if (width > 300) return width / culcPercentage(width, 0.5);
    else return 50 / culcPercentage(width, 0.5);
  };

  const culcWidth = () => {
    return window.innerWidth > 1500 ? width + 650 : width + 100;
  };

  return (
    <>
      <VerticalBarChart2
        data={reformForVericalBarChart(data)}
        width={culcWidth()}
        height={height}
        margin={{ left: getLeftMargin() }}
        label={getLabels().median_price}
        showLegend={false}
      />
      <MultipleAreaChart width={width} height={height} data={data} />

      <AsteriscSubnote target="same_region_price" />
    </>
  );
};
