/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect,useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../_metronic/layout";
import XMLParser from 'react-xml-parser';
import Loader from "react-loader-spinner";
export function GoogleNewsWidget({ className, symbolShape, baseColor }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);
  async function getContent() {
    let cached_data = sessionStorage.getItem('cached_data');
  //  console.log(cached_data);
    if (cached_data!==null) {
      var x=JSON.parse(cached_data);


       var last = new Date(x[x.length-1]['updated']);
       var now=new Date();
       var diff=now.getTime()-last.getTime();
       var mm = Math.floor(diff / 1000 / 60);
       console.log(x);
       x.pop();
        console.log(x);
       //update every 5 min
       setData(x);
       setLoading(false);
      if (mm<5) return;
    }
setLoading(true);
   const metadataResponse = await fetch(process.env.REACT_APP_SERVER_NEWS_URL+"?action=getNews", {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
   });

   const resp = await metadataResponse.json();
   //console.log(resp.results.slice(8,12));
   setData(resp.results);
   var d=resp.results;
  var dd={};
  dd['updated']=new Date();
   d.push(dd);
   sessionStorage.setItem('cached_data', JSON.stringify(d));
   setLoading(false);
  //var xml = new XMLParser().parseFromString(resp);
    //console.log(xml)

  }
  useEffect(() => {

    getContent();
    const element = document.getElementById("kt_stats_widget_11_chart");

    if (!element) {
      return;
    }


  }, [layoutProps]);


  return (<>

        {loading ? <Loader
    type="Bars"
    color="#00BFFF"
    height={20}
    width={50}
    />:(
    <div className={`card card-custom ${className}`}>
      <div className="card-body p-0">
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
           Greece Business News
          </h3>
          <div className="card-toolbar">

          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        {data.map!=undefined?data.slice(8,13).map(d => (

        <div className="card-body pt-2">
          {/* begin::Item */}
          <div className="d-flex flex-wrap align-items-center mb-3">
            {/* begin::Symbol */}


            <div className="d-flex flex-column flex-grow-0 my-lg-0 my-2 pr-3">
              <a
                href={d['elements'][1]['elements'][0]['text']}
                target="_blank"
                className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
              >
            {d['elements'][0]['elements'][0]['text']}
              </a>
              <span className="text-muted font-weight-bold font-size-sm my-1" >
                        {d['elements'][3]['elements'][0]['text']}
              </span>

            </div>



          </div>










        </div>
      )):( <></> )}

      </div>
      </div>
    </div>
  )}
  </>
  );
}
