import React,{useState} from "react";
import {useSubheader} from "../../_metronic/layout";
import { CommercialInventory } from '../modules/Hometrix_Commercial/CommercialInventory'

export const CommercialsInventory = () => {
  //const suhbeader = useSubheader();
  //suhbeader.setTitle("inventory page");

  const [childKey, setChildKey] = useState(0);

  return (
    <>

      <CommercialInventory  key={childKey}></CommercialInventory>
      </>

  );
};
