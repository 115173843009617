import React from "react";
import { getColor, reformForBarChart } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { BarChart, Bar, XAxis, YAxis, Legend } from "recharts";
import { NoData } from "./Shared/NoData";

export const YearBarChart = ({
  width = 500,
  height = 300,
  showLegend = true,
}) => {
  const { json, safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);

  return (
    // changing the width of the ΒarChart will provide more ticks (year of construction points)
    !!json.overview_yoc_stock ? (
      <BarChart
        width={width}
        height={height}
        data={reformForBarChart(safelyGetData("overview_yoc_stock"))}
        margin={{ left: 0 }}
      >
        <XAxis
          dataKey="name"
          interval={0}
          angle={-60}
          tickSize={30}
          tickLine={false}
          height={90}
          label={{
            value: showLegend ? getLabels().yoc : "",
            position: "insideBottom",
          }}
        />

        <YAxis
          tick={false}
          label={{
            value: showLegend ? getLabels().for_sale : "",
            angle: -90,
            offset: 40,
            position: "insideTopCenter",
          }}
        />
        {showLegend && <Legend verticalAlign="top" align="right" />}
        <Bar dataKey="houses" fill={getColor(1)} name={getLabels().houses} />
        <Bar dataKey="flats" fill={getColor(3)} name={getLabels().flats} />
      </BarChart>
    ) : (
      <NoData />
    )
  );
};
