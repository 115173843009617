/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { PieChartWithLabel } from "../PieChartWithLabel";
import { DataContext } from "../context/DataContext";
import { reformForPieChart, pickPallete } from "../utils";
import { NoData } from "../Shared/NoData";

export function PieChart1() {
  const { json, safelyGetData } = React.useContext(DataContext);
  const data = reformForPieChart(
    safelyGetData("overview_build_status")
  ).reverse();

  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body centered">
        {!!json.overview_build_status ? (
          <PieChartWithLabel
            palette={pickPallete(data).reverse()}
            height={140}
            showLegend={true}
            radius={70}
            data={data}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
