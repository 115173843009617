import { mergeObjects } from "../../utils";

const office_GR_1_ls = require("./Office/GR/listings_kpis_el_1.json");
const office_GR_1_els = require("./Office/GR/elstat_kpis_el_1.json");
const office_GR_2_ls = require("./Office/GR/listings_kpis_el_2.json");
const office_GR_2_els = require("./Office/GR/elstat_kpis_el_2.json");
const office_ENG_1_ls = require("./Office/ENG/listings_kpis_1.json");
const office_ENG_1_els = require("./Office/ENG/elstat_kpis_1.json");
const office_ENG_2_ls = require("./Office/ENG/listings_kpis_2.json");
const office_ENG_2_els = require("./Office/ENG/elstat_kpis_2.json");

export const office_JSONS = {
  json_1_gr: mergeObjects(office_GR_1_ls, office_GR_1_els),
  json_2_gr: mergeObjects(office_GR_2_ls, office_GR_2_els),
  json_1_eng: mergeObjects(office_ENG_1_ls, office_ENG_1_els),
  json_2_eng: mergeObjects(office_ENG_2_ls, office_ENG_2_els),
};
