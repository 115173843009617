const sum = (values) => values.reduce((a, b) => a + b);

export const findPercentages = (arr) => {
  const values = arr.map((o) => o[1]);
  const _sum = sum(values);
  arr.forEach((o) => {
    o[1] = Math.round((o[1] / _sum) * 100);
  });

  return arr;
};
