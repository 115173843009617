import { culcPercentage } from "./culculatePercentage";

const isFlat = (str) => ["Flat", "Διαμέρισμα"].includes(str);

/**
 * reusable function for extracting data from the json structure
 * @param  {array} data is the parsed data array
 * @param  {number} keyIdx=0 is the name to be extracted @default 0
 * @param  {number} typeIdx=1 is the index of the type @default 1
 * @param  {number} targetIdx=2 is the index of the number to be extracted @default 2
 * @returns an @type {array} of objects shaped for the graphs
 */

export const reformForBarChart = (
  data,
  keyIdx = 0,
  typeIdx = 1,
  targetIdx = 2
) => {
  let reformedData = {};
  data.forEach((apt) => {
    let key = apt[keyIdx];
    if (!reformedData[key]) {
      reformedData[key] = { name: key, flats: 0, houses: 0 };
    }

    if (isFlat(apt[typeIdx])) {
      reformedData[key].flats = Math.ceil(apt[targetIdx]);
    } else {
      reformedData[key].houses = Math.ceil(apt[targetIdx]);
    }
  });
  return Object.values(reformedData);
};

export const reformForVericalBarChart = (data) => {
  return data.map((o) => ({ name: o[0], sales: o[1], count: o[2] }));
};

export const reformForVericalBarChart3 = (data) => {
  return data.map((o) => ({
    name: o[0],
    year: o[1],
    count: o[2],
    sales: o[2],
  }));
};

export const generateYearTicks = (data, yearKey) => {
  data = data.sort((a, b) => a[yearKey] - b[yearKey]);
  const MAX = data[data.length - 1][yearKey] + 10;

  let ticks = [];
  for (let i = 1940; i <= MAX; ) {
    ticks.push(i);
    i += 10;
  }

  return ticks;
};

const round100 = (num) => {
  return Math.round(num / 100) * 100;
};

const round500 = (num) => {
  return Math.round(num / 500) * 500;
};

export const generatePriceTicks = (data, priceKey) => {
  if (data.length < 1) return [];
  data = data.sort((a, b) => b[priceKey] - a[priceKey]);
  const MAX = data[0][priceKey];
  const MIN = data[data.length - 1][priceKey] - 100;
  const GAP = MAX - MIN;
  let STEP;
  let ticks = [];

  if (GAP >= 3000) {
    STEP = 1000;
  } else if (GAP <= 1000) {
    STEP = 100;
  } else {
    STEP = 500;
  }

  for (let i = MIN; i <= MAX; ) {
    if (STEP === 1000 || STEP === 500) {
      ticks.push(round500(i));
    } else {
      ticks.push(round100(i));
    }
    i += STEP;
  }

  ticks.push(round100(ticks[ticks.length - 1] + STEP));

  return ticks;
};

// prevents auto sorting
export const reformForMunic4 = (d, name = 1, target = 3) => {
  let obj = {};

  for (let i = 0; i < d.length; i++) {
    obj[i] = { name: d[i][name], yoc: d[i][target] };
  }
  return Object.values(obj);
};

export const reformForMunic3 = (data) => {
  const reformed = {};

  data.forEach((d) => {
    if (!reformed[d[1]]) {
      reformed[d[1]] = {
        name: d[1],
        houses: 0,
        flats: 0,
        fPercent: "",
        hPercent: "",
      };
    }

    isFlat(d[2])
      ? (reformed[d[1]].flats = Math.ceil(culcPercentage(d[4], d[5], true)))
      : (reformed[d[1]].houses = Math.floor(culcPercentage(d[4], d[5], true)));

    isFlat(d[2])
      ? (reformed[d[1]].fPercent = Math.ceil(d[5]) + "%")
      : (reformed[d[1]].hPercent = Math.floor(d[5]) + "%");
  });
  return Object.values(reformed);
};
