import React from "react";
import { AreaChart, XAxis } from "recharts";
import {
  culcPercentage,
  generatePriceTicks,
  reformForVericalBarChart,
} from "../utils";

export const CustomXAxis = ({ width, height, data }) => {
  const culcSize = () => {
    return width === 500 ? width : culcPercentage(width, 70);
  };

  const ticks = generatePriceTicks(reformForVericalBarChart(data), "sales");

  return (
    <div style={{ position: "relative", marginTop: "-1vh" }}>
      <AreaChart
        width={culcSize()}
        height={culcPercentage(height, 10)}
        data={ticks.map((n) => ({ number: n }))}
      >
        <XAxis type="category" dataKey="number" />
      </AreaChart>
    </div>
  );
};
