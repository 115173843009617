import React from "react";
import { BarChart, Bar, XAxis, YAxis, Legend, Label } from "recharts";
import { DataContext } from "./context/DataContext";
import { getColor, generateYearTicks, reformForMunic4 } from "./utils";
import { LanguageContext } from "./context/LanguageContext";

export const MunicipalityBarChart4 = ({
  width = 730,
  height = 550,
  showLegend = true,
  showLabel = true,
}) => {
  const { safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);
  // prevents auto sorting
  const data = reformForMunic4(safelyGetData("neighb_median_yoc"));

  return (
    <div className="no-margin-left2">
      <BarChart width={width} height={height} data={data}>
        <XAxis
          dataKey="name"
          interval={data.length > 3 ? 0 : ""}
          angle={data.length > 3 ? -80 : 0}
          tickSize={data.length > 3 ? 10 : 3}
          tickMargin={data.length > 3 ? 40 : 5}
          tickLine={true}
          height={data.length > 3 ? 100 : 30}
        />

        <YAxis
          height={500}
          type="number"
          domain={["dataMin", "dataMax"]}
          ticks={generateYearTicks(data, "yoc")}
        >
          <Label
            value={showLabel ? getLabels().median_yoc : ""}
            angle={-90}
            offset={15}
            position="insideLeft"
          />
        </YAxis>

        {showLegend && <Legend />}

        <Bar dataKey="yoc" fill={getColor(3)} barSize={80} />
      </BarChart>
    </div>
  );
};
