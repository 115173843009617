import React from "react";
import { RetailReport } from "./RetailDashboard";
import { DataProvider } from "./context/DataContext";
import { LanguageProvider } from "./context/LanguageContext";

export function RetailPage(props ) {

  return (
    <DataProvider data={props}>
      <LanguageProvider>
        <RetailReport />
      </LanguageProvider>
    </DataProvider>
  );
}
