import React from "react";
import { CombinedAreaChart } from "../CombinedAreaChart";

export function CombinedChartTab({ name }) {
  return (
    <>
      {/* begin::Advance Table Widget 9 */}
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <h3 className="card-title flex-column mt-5 ml-5 mb-0">
          <span className="card-label font-weight-bolder text-dark">
            {name}
          </span>
        </h3>
        <div className="card-header border-0 py-5 table-responsive">
          <CombinedAreaChart width={window.innerWidth > 1500 ? 400 : 850} />
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}
