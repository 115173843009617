/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HeatMap2 } from "../HeatMap2";
import { DataContext } from "../context/DataContext";
import { NoData } from "../Shared/NoData";

export function HeatMap2Tab({ name }) {
  const { json } = React.useContext(DataContext);

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {name}
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body align-self-left">
          {/* begin::Table */}
          <div className="table-responsive">
            {!!json["overview_heatmap_price_area_yoc"] ? (
              <HeatMap2 />
            ) : (
              <NoData />
            )}
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
