import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete,Marker } from '@react-google-maps/api';
import { Form,Button,Col } from 'react-bootstrap';
import { TextField,makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { NavLink } from "react-router-dom";
const containerStyle = {
  width: '100%',
  height: '400px'
};

const libraries = ["places","geometry"];
const useStyles = makeStyles({
  margin_r: {
    margin: '0px 5px 0px 0px',
  },

});

export function BOG_Asset()  {
  const classes = useStyles();
  const [formData, updateFormData] = useState("");

  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();

  async function onSubmit(event) {

         event.preventDefault();
       const data = new FormData(event.target);
    //   console.log(data);
    //   console.log(data.get('collateralId'));
    //   console.log(data.get('lat'));
      data.append('user',user.email);

     setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=insertBOG";
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
     credentials: 'include',
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
    //  console.log(resp);
      setData(resp);
     setLoading(false);
  }



  const onLoad = () => {};
  const [map, setMap] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [district,setDistrict]=useState("");
  const [address,setAddress]=useState("");
  const [prefecture,setPrefecture]=useState("");
  const [googleAddress, setGoogleAddress] = useState("");
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  var places;


  var place=null;

  var center = {
    lat: 38.0572437,
    lng: 23.8481676
  };
  function onloadMap(ref){
    setMap(ref);
  }
  function onLoadMarker(ref) {
    setMarker(ref);
  }
  function onSearchBoxMounted(ref) {
      setAutocomplete(ref);
  }
  function onDragEnd(e) {
//    console.log("onPositionChanged");

    //map.panTo(new window.google.maps.LatLng( marker.getPosition().lat(), marker.getPosition().lng() ));
    //center.lat=marker.getPosition().lat();
  //  center.lng=marker.getPosition().lng();
      setLat(marker.getPosition().lat());
      setLng(marker.getPosition().lng());


    geocodePosition(marker.getPosition());
//    setLat(place.geometry.location.lat());
//    setLng(place.geometry.location.lng());
  }
  function geocodePosition(pos)  {



  var  geocoder = new window.google.maps.Geocoder();
   geocoder.geocode
    ({
        latLng: pos
    },
        function(results, status)
        {
            if (status ==window.google.maps.GeocoderStatus.OK)
            {


              updateAddress(results);
            }
            else
            {
            	//  setAddress(marker.getPosition().lat()+","+marker.getPosition().lng());


            }
        }
    );

	}
  function updateAddress(results) {
    setAddress(results[0].formatted_address);
      Object.keys(results[0].address_components).forEach(function(key) {
        var result=results[0].address_components[key];
        if (result.types.indexOf("locality") > -1) {
          setMunicipality(result.long_name);
        }
        if (result.types.indexOf("postal_code") > -1) {
          setPostalCode(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_32") > -1) {
          setDistrict(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_3") > -1) {
          setPrefecture(result.long_name);
        }

      });
  }
  function onPlacesChanged() {
  //  console.log('autocomplete: ', autocomplete)
//    console.log(searchBox.getPlaces());
      if (autocomplete !== null) {
  //     console.log(autocomplete.getPlace())
      place=autocomplete.getPlace();


      //setGoogleAddress(place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      center.lat=place.geometry.location.lat();
      center.lng=place.geometry.location.lng();

      marker.setPosition(new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );

      map.panTo( new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );
      geocodePosition(marker.getPosition());

      } else {
  //     console.log('Autocomplete is not loaded yet!')
      }
  }
 const [value, setValue] = useState('');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);


  function handleChange (e) {

    if (e.target.id=='lat') {
      setLat(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    if (e.target.id=='lng') {
      setLng(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }



 }


  return (
    <>
      <Form onSubmit={onSubmit}>
    <div className="row">
      <div className="col-xl-12">
        <div className={`row card `}>
        <div className="col-xl-12 card-header">
              <h3>Στοιχεία ακινήτου</h3>
        </div>
          <div className="col-xl-12  card-body">
              <Form.Group>
                <Form.Label>Μοναδικός κωδικός εγγραφής</Form.Label>
                <Form.Control type="text" name="collateralId" id="collateralId" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Μοναδικός κωδικός εγγραφής
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Kωδικός Ακινήτου</Form.Label>
                <Form.Control type="text" name="propertyID" id="propertyID" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Kωδικός Ακινήτου
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>  ΚΑΕΚ Ακινήτου</Form.Label>
                <Form.Control type="text" name="kaek" id="kaek" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                ΚΑΕΚ Ακινήτου
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>    Ιδιοκτήτης Ακινήτου</Form.Label>
                <Form.Control type="text" name="owner" id="owner" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                  Ιδιοκτήτης Ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                 <Form.Label>  Περιγραφή ακινήτου</Form.Label>
                 <Form.Control  as="textarea"  name="description" id="description" onChange={handleChange}  rows={10 } />
                 <Form.Text className="text-muted">
                  Περιγραφή ακινήτου
                 </Form.Text>
               </Form.Group>

          </div>

    </div>

  </div>

</div>
<div className="row gutter-t">


    <div className="col-xl-12 gutter-t">
      <div className="row card">
      <div className="col-xl-12 card-header">
            <h3>ΤΟΠΟΘΕΣΙΑ ΑΚΙΝΗΤΟΥ 					 </h3>
      </div>
        <div className="col-xl-12 card-body">

              <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
                  libraries={libraries}
                >
                  <GoogleMap
                    onLoad={onloadMap}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                  >
                    { /* Child components, such as markers, info windows, etc. */ }
                    <Autocomplete
                  onLoad={onSearchBoxMounted}
                  onPlaceChanged={onPlacesChanged}
                  onPlacesChanged={onPlacesChanged}
                  onFail={error => console.error(error)}

                >
                  <input

                    type="text"
                    placeholder="Εύρεση απο Google"
                    style={{
                      boxSizing: `border-box`,
                      border: `2px solid #E4E6EF`,
                      width: `100%`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: '0.42rem',
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      bottom:"0px",
                      marginLeft: "-50%"

                    }}
                  />
                </Autocomplete>

                  <Marker
                      onLoad={onLoadMarker}
                      draggable={true}
                      onDragEnd={onDragEnd}
                 />



                  </GoogleMap>
                </LoadScript>

      {/* end: MAP */}

                <Form.Group className="gutter-t">
                  <Form.Label>Γεωγραφικό πλάτος (Latitude)</Form.Label>
                  <Form.Control type="text"  name="lat" value={lat}  onChange={handleChange} id="lat" placeholder="" />
                  <Form.Text className="text-muted">
                  Γεωγραφικό πλάτος (Latitude)
                  </Form.Text>
                </Form.Group>

                  <Form.Group>
                    <Form.Label>  Γεωγραφικό μήκος (Longtitude)</Form.Label>
                    <Form.Control type="text" name="lng" id="lng"  value={lng}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Γεωγραφικό μήκος (Longtitude)
                    </Form.Text>
                  </Form.Group>


                  <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Διεύθυνση</Form.Label>
                    <Form.Control type="text" name="address" id="address" value={address} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Διεύθυνση
                    </Form.Text>
                  </Form.Group>
                  <Col xs={2}>
                  <Form.Group>
                    <Form.Label>ΤΚ</Form.Label>
                    <Form.Control type="text" name="postalCode" id="postalCode" value={postalCode}  placeholder="" />
                    <Form.Text className="text-muted">
                    ΤΚ
                    </Form.Text>
                  </Form.Group>
                  </Col>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label>Δήμος</Form.Label>
                    <Form.Control type="text" name="municipality" id="municipality"  value={municipality}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δήμος
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Δημοτική ενότητα</Form.Label>
                    <Form.Control type="text" name="district" id="district"    value={district}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δημοτική ενότητα
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Περιφέρεια</Form.Label>
                    <Form.Control type="text" name="prefecture" id="prefecture"  value={prefecture} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Περιφέρεια
                    </Form.Text>
                  </Form.Group>
                    <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού</Form.Label>
                    <Form.Control type="text" name="plotNumber" id="plotNumber"    onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού
                    </Form.Text>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Τοπωνύμιοs</Form.Label>
                    <Form.Control type="text" name="toponym" id="toponym"    onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Τοπωνύμιο
                    </Form.Text>
                  </Form.Group>
                    </Form.Row>
          </div>
          </div>
  </div>

</div>
<div className="row">
  <div className="col-xl-12">
    <div className="row">

      <div className="col-xl-12">

          {data!==null?
          <div>
          <br/>
          {data.ErrorCode!=0?<>
            <Alert severity="error">Υπάρχει ήδη αυτος ο κωδικός ακινήτου</Alert>
          </>:
          <>
          <Alert>Επιτυχής αποθήκευση με κωδικό   :{data.insertedId}</Alert>
          <br/>
          <NavLink className="btn btn-light-primary btn-bold" to={`/bog/asset/${data.insertedId}`}>
         Συνέχεια στη καταχώρηση δεδομένων
          </NavLink>
          </>
          }
          </div>
          :<></>}
          <br/><br/>
          {loading ?    <Loader
      type="Bars"
      color="#00BFFF"
      height={10}
      width={50}
      />:
              <button className="btn btn-light-primary btn-bold">
              Αποθήκευση</button> }
          <br/>
          </div>
        </div>
      </div>
      </div>
  </Form>
    </>

  );


}
export default BOG_Asset;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
