import React from "react";
import { BarChart, Bar, XAxis, YAxis, Label, Cell } from "recharts";
import { greyOutV2, generateYearTicks } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";

export const MunicipalityBarChart4 = ({
  data,
  width = 730,
  height = 550,
  showLegend = true,
  showLabel = true,
}) => {
  const { json } = React.useContext(DataContext);
  const { language } = React.useContext(LanguageContext);

  return (
    <div className="no-margin-left2">
      <BarChart width={width} height={height} data={data}>
        <XAxis
          dataKey="name"
          interval={data.length > 3 ? 0 : ""}
          angle={data.length > 3 ? 40 : 0}
          tickSize={data.length > 3 ? 10 : 3}
          tickMargin={data.length > 3 ? 30 : 5}
          tickLine={true}
          height={data.length > 3 ? 80 : 30}
        />

        <YAxis
          height={400}
          type="number"
          domain={["dataMin-20", "dataMax+10"]}
          ticks={generateYearTicks(data, "yoc")}
        >
          <Label
            value={language === "EN" ? "YoC" : "Έτος κατασκευής"}
            angle={-90}
            offset={15}
            position="insideBottomLeft"
          />
        </YAxis>

        <Bar dataKey="year">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={greyOutV2(entry, json)} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};
