/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
export function DropdownMenu1() {
    return (<>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            <li className="navi-header font-weight-bold py-5">
                <span className="font-size-lg">Choose Report:</span>
                <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right"
                   title="Click to learn more..."></i>
            </li>
            <li className="navi-separator mb-3 opacity-70"></li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-success">  <NavLink className="menu-link" to="/inspection-report">Inspection</NavLink></span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-danger">AVM Residential</span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-warning">AVM Office</span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-primary">Index Residential</span>
            </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
            <span className="navi-text">
                <span className="label label-xl label-inline label-light-dark">Index Office</span>
            </span>
                </a>
            </li>
            <li className="navi-separator mt-3 opacity-70"></li>

        </ul>
        {/*end::Navigation*/}
    </>);
}
