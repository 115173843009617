/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

export function BreadCrumbs({ items }) {
  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0">
      <h4 className="mr-4">
        <Link className="text-muted" to="/">
          {items.TITLES.mun_of}
        </Link>
      </h4>

      <Link className="breadcrumb-item" to="/dashboard">
        <h3>{items.MUNIC}</h3>
      </Link>
    </ul>
  );
}
