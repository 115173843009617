import React from "react";
import {
  PieChart1,
  PieChart2,
  NeighbBarChart1,
  NeighbBarChart2,
  NeighbBarChart3,
  NeighbBarChart4,
  HousesMetrics,
  FlatsMetrics,
  Αbbreviations,
  SurfaceBarChartTab,
  HeatMap1Tab,
  HeatMap2Tab,
  YearBarChartTab,
  PopulationMetrics,
  RadarChart1Tab,
  RadarChart2Tab,
  ForSaleTab,
  MedianPriceTab,
  MedianYocTab,
  CombinedChartTab,
  MedianPrice,
  BarrierLine,
  SubHeader,
} from "./";

import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { DropdownButton } from "./utils/dropdownButton";

export function ResidentialDashboard() {
  // causes the whole dashboard to re-render, hence widgets get new widths when resizing
  // eslint-disable-next-line
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  const { json } = React.useContext(DataContext);
  const { getTitles, getMunicipality } = React.useContext(LanguageContext);

  React.useEffect(() => {
    window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    return () => window.removeEventListener("resize", setInnerWidth);
  }, []);

  return (
    <>
      <SubHeader TITLES={getTitles()} MUNIC={getMunicipality()} />
      
      {/* begin::Dashboard */}
      <BarrierLine title={getTitles().overview} />

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-6">
          <MedianPrice />
        </div>
        <div className="col-xl-6">
          <PieChart1 />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-6">
          <HousesMetrics />
        </div>
        <div className="col-xl-6">
          <FlatsMetrics />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-6">
          {/*  eslint-disable-next-line */}
          <Αbbreviations />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-12">
          <SurfaceBarChartTab name={getTitles().surface_bar_chart} />
        </div>
      </div>
      {/* end::Row */}

      <div className="row">
        <div className="col-xl-12">
          <HeatMap1Tab name={getTitles().heatmap_1} />
        </div>
      </div>

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-12">
          <YearBarChartTab name={getTitles().year_bar_chart} />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <HeatMap2Tab name={getTitles().heatmap_2} />
        </div>
      </div>

      {/* end::Row */}

      <BarrierLine title={getTitles().census} subtitle={getMunicipality()} />

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-6">
          <PopulationMetrics />
        </div>
        <div className="col-xl-6">
          <PieChart2 />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-lg-6">
          <RadarChart1Tab name={getTitles().radar_chart_1} />
        </div>
        <div className="col-lg-6">
          <RadarChart2Tab name={getTitles().radar_chart_2} />
        </div>
      </div>
      {/* end::Row */}

      <BarrierLine
        title={getTitles().comp}
        subtitle={`${getTitles().within + " " + json.region?.toString()}`}
      />

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-12">
          <ForSaleTab name={getTitles().houses_fs_mun} />
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <MedianPriceTab name={getTitles().median_price_mun} />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <CombinedChartTab name={getTitles().combined_chart} />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-12">
          <MedianYocTab name={getTitles().median_yoc_mun} />
        </div>
      </div>
      {/* end::Row */}

      <BarrierLine
        title={getTitles().comp_analysis}
        subtitle={getTitles().per_neighb}
      />
      {/* begin::Row */}
      <div className="row">
        <div className="col-lg-6">
          <NeighbBarChart1 name={getTitles().median_price_neighb} />
        </div>
        <div className="col-lg-6">
          <NeighbBarChart2 name={getTitles().stock_percentage} />
        </div>
      </div>

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-12">
          <NeighbBarChart3 name={getTitles().stock_mun} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <NeighbBarChart4 name={getTitles().median_yoc_neighb} />
        </div>
      </div>
      {/* end::Row */}

      {/* end::Dashboard */}
    </>
  );
}
