import React from "react";
import { CustomAreaChart } from "../Shared/CustomAreaChart";
import { CustomXAxis } from "../Shared/CustomXAxis";
import { BorderLine } from "./BorderLine";
import { culcPercentage, formAreaChart } from "../utils";

export const MultipleAreaChart = ({ width, height, data }) => {
  let isShort = height < 300;
  height = isShort ? 260 : height;

  const getCurveHeight = () => {
    let h = height / data.length;
    return h > 30 ? h : 29.2;
  };

  return (
    // some math that weirdly work for making the graph partially scalable
    <div style={{ marginTop: -Math.abs(height), marginRight: width / 3 }}>
      {formAreaChart(data).map((p, i) => (
        <div key={i}>
          <CustomAreaChart
            data={p}
            width={p.mockData.length * culcPercentage(width, 5)}
            height={getCurveHeight()}
          />
          {/* hiding the last borderline */}
          {i <= data.length - 2 && <BorderLine />}
        </div>
      ))}
      <CustomXAxis width={width} height={isShort ? 400 : height} data={data} />
    </div>
  );
};
