/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { VerticalBarChart3 } from "../VerticalBarChart3";
import { getWidth } from "../utils";
import { DataContext } from "../context/DataContext";
import { LanguageContext } from "../context/LanguageContext";
import { AsteriscSubnote } from "../Shared/AsteriscSubnote";
import { NoData } from "../Shared/NoData";

export function ForSaleTab({ name }) {
  const { json, safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);

  const data = safelyGetData("same_region_stock").map((x) => ({
    name: x[0],
    year: x[1],
    count: x[1],
  }));

  const culcLeftMargin = () => {
    return window.innerWidth < 500 ? "1%" : "10%";
  };

  return (
    <>
      <div className={`card card-custom  card-stretch gutter-b`}>
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">{name}</span>
          </h3>
        </div>
        {/* Body */}
        {!!json.same_region_stock ? (
          <div
            className="card-body pt-4 table-responsive"
            style={{ marginLeft: culcLeftMargin() }}
          >
            <>
              <VerticalBarChart3
                data={data}
                width={getWidth(550, 800)}
                height={520}
                barSize={20}
                margin={{ right: -100 }}
                axis="right"
                showLegend={false}
                label={getLabels().for_sale}
                showTicks={false}
                domain={["dataMin-10", "dataMax + 10"]}
              />
              <AsteriscSubnote target="same_region_stock" />
            </>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}
