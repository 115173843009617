import React, { useContext } from "react";
import { DataContext } from "../context/DataContext";
import { LanguageContext } from "../context/LanguageContext";
import People from "../assets/icons/People.png";
import { getColor } from "../utils";

export const Population = () => {
  const { json } = useContext(DataContext);
  const { getContent } = useContext(LanguageContext);

  const culcMarginTop = () => {
    const w = window.innerWidth;
    if (w > 1200) return "-32%";
    if (w > 890) return "-23%";
    if (w > 790) return "-28%";
    if (w > 600) return "-35%";
    if (w > 400) return "-40%";
    return "-26%";
  };

  const CONTENT = getContent();
  return (
    <div>
      <div className="col metric">
        <img src={People} alt="people icon" width={100} height={200}></img>
      </div>
      <div className="col">
        <ul className="metric-list" style={{ marginTop: culcMarginTop() }}>
          <li>
            <h4>
              {CONTENT.metrics.mun_pop}{" "}
              <span style={{ color: getColor(3) }}>
                {json.munic_population.toString()}
              </span>
            </h4>
          </li>

          <li>
            <h4>
              {CONTENT.metrics.pop_dens}{" "}
              <span style={{ color: getColor(3) }}>
                {json.population_density + " " + CONTENT.metrics.pop_dens_unit}
              </span>
            </h4>
          </li>

          <li>
            <h4>
              {CONTENT.metrics.build_dens}{" "}
              <span style={{ color: getColor(3) }}>
                {json.building_density + " " + CONTENT.metrics.dens_unit}
              </span>
            </h4>
          </li>
        </ul>
      </div>
    </div>
  );
};
