/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { PieChartWithLabel } from "../PieChartWithLabel";
import { DataContext } from "../context/DataContext";
import { reformForPieChart, pickPallete } from "../utils";
import { NoData } from "../Shared/NoData";

export function PieChart2() {
  const { json, safelyGetData } = React.useContext(DataContext);
  const data = reformForPieChart(safelyGetData("elstat_buildings_use"))
    .sort((a, b) => a.value - b.value)
    .reverse();

  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body p-5 m-0 centered">
        {!!json.elstat_buildings_use ? (
          <PieChartWithLabel
            palette={pickPallete(data)}
            height={210}
            showLegend={true}
            radius={80}
            data={data.reverse()}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
