import React from "react";
import { BarChart, Bar, XAxis, YAxis, Legend, Cell, Label } from "recharts";
import { generatePriceTicks, greyOut } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";

export const MunicipalityBarChart1 = ({
  data,
  width = 730,
  height = 550,
  showLegend = true,
}) => {
  const { json } = React.useContext(DataContext);
  const { getTitles } = React.useContext(LanguageContext);

  return (
    <div className={`${window.innerWidth < 1200 && "no-left-margin"}`}>
      <BarChart width={width} height={height} data={data}>
        <XAxis
          dataKey="name"
          interval={data.length > 3 ? 0 : ""}
          angle={data.length > 3 ? 40 : 0}
          tickSize={data.length > 3 ? 10 : 0}
          tickMargin={data.length > 3 ? 25 : 5}
          tickLine={true}
          height={20}
        />
        <YAxis
          type="number"
          domain={["dataMin", "dataMax"]}
          interval="preserveStartEnd"
          ticks={generatePriceTicks(data, "sales")}
          height={200}
        >
          <Label
            style={{ marginLeft: "20px" }}
            value={getTitles().median_price_neighb}
            angle={-90}
            position="insideLeft"
          />
        </YAxis>
        {showLegend && <Legend />}

        <Bar dataKey="sales" name="Neighborhood">
          {data.map((entry, index) => (
            <Cell fill={greyOut(entry, json)} key={index} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};
