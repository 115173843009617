/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { VerticalBarChart3 } from "../VerticalBarChart3";
import { getWidth, reformForVericalBarChart3 } from "../utils";
import { DataContext } from "../context/DataContext";
import { AsteriscSubnote } from "../Shared/AsteriscSubnote";

export function MedianYocTab({ name }) {
  const { json } = React.useContext(DataContext);
  const data = reformForVericalBarChart3(
    JSON.parse(json.same_region_median_yoc).data
  );

  return (
    <>
      <div className={`card card-custom  gutter-b`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">{name}</span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-4 table-responsive">
          <div className="row" style={{ paddingLeft: "10%" }}>
            <VerticalBarChart3
              data={data}
              width={getWidth(900, 900)}
              margin={{ right: -100, left: -100 }}
              height={405}
              axis="right"
              showLegend={false}
              label={name}
              orientation={0}
            />
            <AsteriscSubnote target="same_region_median_yoc" />
          </div>
        </div>
      </div>
    </>
  );
}
