/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Population } from "../Metrics/Population";

export function PopulationMetrics() {
  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body p-5">
        <Population />
      </div>
    </div>
  );
}
