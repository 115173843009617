import React from "react";
import { PieChart, Pie, Legend, Cell } from "recharts";
import { renderCustomizedLabel, culcPercentage } from "./utils";

export const PieChartWithLabel = ({
  data,
  width = 430,
  height = 350,
  showLegend = true,
  radius = 40,
  palette = ["#347E84", "#78c6cd", "#57b5bd", "#ffb380", "#f7e5c5"],
}) => {
  return (
    <div>
      <PieChart width={width} height={height}>
        <Pie
          data={data.reverse()}
          cx={"25%"}
          innerRadius={culcPercentage(radius, 30)}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={radius}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={palette[index]} />
          ))}
        </Pie>
        {showLegend && (
          <Legend
            layout="verical"
            align="right"
            verticalAlign="top"
            height={200}
            width={200}
          />
        )}
      </PieChart>
    </div>
  );
};
