import React from "react";
import { CombinedAreaChart } from "../CombinedAreaChart";
import { DataContext } from "../context/DataContext";
import { NoData } from "../Shared/NoData";

export function CombinedChartTab2({ name }) {
  const { json } = React.useContext(DataContext);

  const getData = () => {
    return !!json.office_market_histograms_price
      ? JSON.parse(json.office_market_histograms_price.right_figure).data
      : [];
  };
  const data = getData();

  const getHeight = () => {
    const l = data.length;
    if (l > 10) {
      return l * 27.3;
    } else {
      return l * 38;
    }
  };

  return (
    <>
      {/* begin::Advance Table Widget 9 */}
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <h3 className="card-title flex-column mt-5 ml-5 mb-0">
          <span className="card-label font-weight-bolder text-dark">
            {name}
          </span>
        </h3>

        <div className="card-header border-0 py-5 table-responsive">
          {!!json.office_market_histograms_price ? (
            <CombinedAreaChart
              width={window.innerWidth > 1500 ? 400 : 850}
              data={data}
              height={getHeight()}
            />
          ) : (
            <NoData />
          )}
        </div>

        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}
