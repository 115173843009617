import { useRequestPreSend } from "@rpldy/uploady";
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export default function UseRequestPreSend({category,assetid}) {
  const testURL="https://cx6kgs66dc.execute-api.eu-central-1.amazonaws.com/Dev/hometrixUpload?action=uploadBOGContentGetSigned&category="+category+'&assetid='+assetid;
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();

  useRequestPreSend(async ({ items, options }) => {
    let method = options.method;
    const files = items.length > 0 ? items[0] : {};
    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
        let { file } = files;
        let { name, type } = file;
        let gateway = testURL;

            let data={};
            data['filename']=file.name;
            data['directory']=file.webkitRelativePath;

            const metadataResponse = await fetch(gateway, {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
           mode: 'cors', // no-cors, *cors, same-origin
           cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           body:JSON.stringify(data),
              headers: {
                 'Content-Type':'application/json',
                'authorization': 'Bearer '+accessToken,
              },
            });

            const resp = await metadataResponse.json();

              return {

                  options: {  destination: {
          url: resp.results,
          method: 'PUT',
          headers: {
          'Content-Type': type
          }
          }, } } //will be merged with the rest of the options
            });

  return null;
}
