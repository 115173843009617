import React from "react";
import { reformForBarChart, getColor } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { BarChart, Bar, XAxis, YAxis, Label } from "recharts";

export const SurfaceBarChart = ({
  width = 730,
  height = 250,
  showLegend = true,
}) => {
  const { safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);
  const data = reformForBarChart(safelyGetData("overview_area_stock"), 0, 0, 1);

  return (
    <BarChart width={width} height={height} data={data}>
      <XAxis
        dataKey="name"
        interval={0}
        angle={-60}
        tickSize={25}
        tickLine={false}
        height={60}
      >
        <Label value="m²" offset={5} position="insideBottom" />
      </XAxis>

      <YAxis height={400} tick={false} domain={["dataMin", "dataMax + 1"]}>
        <Label
          value={showLegend ? getLabels().for_sale : ""}
          angle={-90}
          offset={4}
          position="insideTopCenter"
        />
      </YAxis>

      <Bar dataKey="houses" fill={getColor(3)} />
    </BarChart>
  );
};
