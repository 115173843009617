import React from "react";

export const InsufficientData = ({ text, text2 = "" }) => {
  return (
    <div className="ins-data">
      <h1 className="ins-text">{text}</h1>
      <h1 className="ins-text">{text2}</h1>
    </div>
  );
};
