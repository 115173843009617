/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { useAuth0 } from '@auth0/auth0-react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { NavLink } from "react-router-dom";
import Loader from "react-loader-spinner";
import jwt from 'jwt-decode'
import {
  DropdownItemToggler,
  DropdownMenu4,
} from "../../../_partials/dropdowns";
import { FormattedMessage } from "react-intl";
export function AsideSearch({ isActive }) {
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState("");
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,getIdTokenClaims,
   } = useAuth0();

   async function getScopes() {

     const accessToken = await getAccessTokenSilently({
          audience:process.env.REACT_APP_AUTH0_AUDIENCE
        });
      const data = jwt(accessToken)

      setPermissions(data.permissions);
     setLoading(false);
     return;
   }
   useEffect(()=>{
      getScopes()
    }, []) // <-- empty dependency array
  return (<>
      {loading ?  <>   <Loader
    type="Bars"
    color="#00BFFF"
    height={10}
    width={50}
    /></>:
    <div
      className={`tab-pane p-3 px-lg-7 py-lg-5 fade ${isActive &&
        "show active"}`}
    >


      <h3 className="p-2 p-lg-3 my-1 my-lg-3">Dashboard</h3>

      {/* begin::List */}





            {/* begin::Item */}
            <div className="list-item hoverable p-2 p-lg-3 mb-2">
              <div className="d-flex align-items-center">
                {/* begin::Symbol */}
                <div className="symbol symbol-40 symbol-light mr-4">
                  <span className="symbol-label bg-hover-white">
                    <span className="svg-icon h-50 align-self-center">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-upload.svg")} />
                    </span>
                  </span>
                </div>
                {/* end::Symbol */}
                {/* begin::Text */}
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="text-dark-75 font-size-h6 mb-0">
                   <FormattedMessage id="MENU.PERSONAL" />
                  </span>
                  <NavLink className="menu-link" to="/content"> <FormattedMessage id="MENU.STORAGE" /></NavLink>
                </div>
                {/* begin::End */}
              </div>
            </div>
            {/* end::Item */}

            {/* begin::Item */}
              { JSON.stringify(permissions).search("view:content")>0 ?
            <div className="list-item hoverable p-2 p-lg-3 mb-2">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-40 symbol-light mr-4">
                  <span className="symbol-label bg-hover-white">
                    <span className="svg-icon h-50 align-self-center">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Folder.svg")} />
                    </span>
                  </span>
                </div>

                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <span className="text-dark-75 font-size-h6 mb-0">
            <FormattedMessage id="MENU.TOTAL" />
                  </span>
                  <NavLink className="menu-link" to="/contentAll"><FormattedMessage id="MENU.STORAGE" /></NavLink>
                </div>

              </div>
            </div>
            :<></>}





      {/* end::List */}
    </div>
  }
  </>
  );
}
