/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Flats } from "../Metrics/Flats";

export function FlatsMetrics() {
  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body p-5">
        <Flats />
      </div>
    </div>
  );
}
