import React from "react";
import { reformForMunic3, getColor } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { BarChart, Bar, XAxis, YAxis, Legend, LabelList } from "recharts";

export const MunicipalityBarChart3 = ({
  width = 800,
  height = 350,
  showLegend = true,
}) => {
  const { safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);
  const parsed = safelyGetData("neighb_stock_restype");
  const data = reformForMunic3(parsed);

  return (
    // changing the width of the ΒarChart will provide more ticks (year of construction points)
    <div className={`${window.innerWidth < 1200 ? "no-left-margin2" : ""}`}>
      <BarChart width={width} height={height} data={data}>
        <XAxis
          dataKey="name"
          interval={data.length > 3 ? 0 : ""}
          angle={data.length > 3 ? -70 : 0}
          tickSize={data.length > 3 ? 10 : 3}
          tickMargin={data.length > 3 ? 40 : 5}
          tickLine={true}
          height={data.length > 3 ? 120 : 40}
        />

        <YAxis
          padding={{ top: 20 }}
          height={320}
          tick={false}
          label={{
            value: getLabels().for_sale,
            angle: -90,
          }}
        />

        {showLegend && <Legend verticalAlign="bottom" align="right" />}
        <Bar dataKey="flats" fill={getColor(3)} name={getLabels().flats}>
          <LabelList dataKey="fPercent" position="top" fill="#2db2ff" />
        </Bar>
        <Bar dataKey="houses" fill={getColor(1)} name={getLabels().houses}>
          <LabelList dataKey="hPercent" position="top" fill="#2db2ff" />
        </Bar>
      </BarChart>
    </div>
  );
};
