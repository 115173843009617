/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { Dropdown,Toast } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu1 } from "../../../_metronic/_partials/dropdowns";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useAuth0 } from '@auth0/auth0-react';
import Loader from "react-loader-spinner";

export function ContentInventoryAll({ className }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();
   useEffect(()=>{
      getContent()
    }, []) // <-- empty dependency array
  async function getContent() {

    setLoading(true);
     setData({});

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=getContentAll";
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
      console.log(resp);
      setData(resp.results);
     setLoading(false);
  }
  async function downloadContent(contentid) {

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=downloadContent&contentid="+contentid;
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
      var url=resp.results;
      window.open(url);
      //setData(resp);
  //    getContent();
  //setLoading(false);
  }
  async function deleteItem(contentid) {

    setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=deleteContentOverride&contentid="+contentid;
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();

      //setData(resp);
      getContent();
  //setLoading(false);
  }
  const [show,setShow ] = useState(false);
  function handleShow(url) {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setShow(true);
  }

    const handleClose = () => setShow(false);

  return (
    <>
    <Toast onClose={handleClose} show={show} delay={2000} autohide>
<Toast.Header>
  <img
    src="holder.js/20x20?text=%20"
    className="rounded mr-2"
    alt=""
  />
  <strong className="mr-auto">Link</strong>
  <small></small>
</Toast.Header>
<Toast.Body>

Copied to clipboard / Αντιγράφηκε στο clipboard
</Toast.Body>
</Toast>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
           Inventory Items
          </h3>
          <div className="card-toolbar">

          </div>
        </div>
        {/* end::Header */}


        <div className="card-body pt-2">




            {loading ?    <Loader
        type="Bars"
        color="#00BFFF"
        height={20}
        width={50}
        />:(
          <>
         {data.map!=undefined?data.map(d => (
           <>
           <div className="d-flex flex-wrap align-items-center mb-10">
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
              <a
                href="#"
                className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
              >
                {d.Title}
              </a>
              <span className="text-muted font-weight-bold font-size-sm my-1">
            {d.objectName}
              </span>
              <span className="text-muted font-weight-bold font-size-sm">
                Created by:
                <span className="text-primary font-weight-bold">{d.user}</span>
              </span>
            </div>

            <div className="d-flex align-items-center py-lg-0 py-2">
              <div className="d-flex flex-column text-right">
                <span className="text-dark-75 font-weight-bolder font-size-h4">

                </span>
                <span className="text-muted font-size-sm font-weight-bolder">

                <button onClick={()=>{ downloadContent(d._id) } }
                  className="btn btn-light-info btn-bold">
                 Download </button>


                 &nbsp;     &nbsp;     &nbsp;
                <button onClick={()=>{
                  deleteItem(d._id)}
                 }  className="btn btn-light-danger btn-bold">
                 Delete </button>
                </span>
              </div>
            </div>

            </div>
            </>
          )) : ( <></> )}
        </>
        )
        }











        </div>
        {/* end::Body */}
      </div>

      {/* end::List Widget 14 */}
      <br />



    </>
  );

}
