import React, {useState,useEffect} from 'react';
import Loader from "react-loader-spinner";
import {useSubheader} from "../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@material-ui/lab/Alert';
import { Form,Button } from 'react-bootstrap';
import { ContentInventory } from '../modules/Hometrix/ContentInventory'
var ReactS3Uploader = require('react-s3-uploader');
export const Content = () => {
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();
  const [selectedFile, setSelectedFile] = useState();
  const [childKey, setChildKey] = useState(0);
  const [dataTitle, setDataTitle] = useState();
	const [isSelected, setIsSelected] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [isPercent, setIsPercent] = useState(false);
  const [isPercentNumber, setIsPercentNumber] = useState();

  const changeHandler = (e) => {
    if (e.target.id=='file') {
        setIsUploaded(false);

  		setSelectedFile(e.target.files[0]);
  		setIsSelected(true);
    }
      if (e.target.id=='title') {
        setDataTitle(e.target.value);
      }
  	};

  	async function handleSubmission(file,callback) {

      const data = new FormData();
	     setIsUploading(true);
  	//	data.append('File', selectedFile);
      //data.append('dataTitle',dataTitle);
      //data.append('user',user.email);
      data.append('filename',file.name);
      const accessToken = await getAccessTokenSilently({
           audience: process.env.REACT_APP_AUTH0_AUDIENCE
         });
         var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
         apiURL+="?action=uploadContentGetSigned";
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       body:data,
       credentials: 'include',
          headers: {

            'authorization': 'Bearer '+accessToken,
          },
        });

        const resp = await metadataResponse.json();
        var url=resp.results;
        //remove bucket from url

        setSelectedFile(resp.filename);
        callback(({'signedUrl': url}));
        setIsUploading(false);
      /*	setIsSelected(false);
        setIsUploaded(true);
        setIsUploading(false);
        var i=childKey;
        i++;
        setChildKey(i);
  */

  	};
    async function handleSubmission2() {
      const data = new FormData();
       setIsUploading(true);
      data.append('filename', selectedFile);
      data.append('dataTitle',dataTitle);
      data.append('user',user.email);
      const accessToken = await getAccessTokenSilently({
           audience: process.env.REACT_APP_AUTH0_AUDIENCE
         });
         var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
         apiURL+="?action=uploadContent";
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       body:data,
       credentials: 'include',
          headers: {

            'authorization': 'Bearer '+accessToken,
          },
        });

        const resp = await metadataResponse.json();
        console.log(resp);
        setIsSelected(false);
        setIsUploaded(true);
        setIsUploading(false);
        var i=childKey;
        i++;
        setChildKey(i);


    };
  const suhbeader = useSubheader();
  suhbeader.setTitle("Upload Content to Store");
  function onProgress(e) {
    setIsPercent(true);
    setIsPercentNumber(e);
    setIsUploading(true);
    console.log("progress");
    console.log(e);
  }
  function onError(e) {
    console.log("error");
    console.log(e);
  }
  function onFinish(e) {
      setIsPercent(false);
      setIsUploading(false);
    console.log("finish");
    console.log(e);
  }
  return(
  <div>
    <div className="col-xl-6">
      <div className="row card">

        <div className="col-xl-12 card-header">
              <h3>Upload your zip file</h3>
        </div>
        <div className="col-xl-12 card-body">

        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" name="title" id="title" onChange={changeHandler}  placeholder="Enter title" />
          <Form.Text className="text-muted">
            A title for the file
          </Form.Text>
        </Form.Group>
        <ReactS3Uploader

  getSignedUrl={handleSubmission}

  onProgress={onProgress}
  onError={onError}
  onFinish={onFinish}
  uploadRequestHeaders={{
    'x-amz-acl': 'authenticated-read'
  }}
  contentDisposition="auto"
/> <br/><br/>
          {isPercent ? (
           <div>Uploading : {isPercentNumber} % - Please wait - Press Save after complete </div>
          ) :(
          <></>)
          }

                        {isUploaded ? (
                       <div>
                       <br /><br />
                         <Alert onClose={() => {  setIsUploaded(false); }}>Success</Alert>
                         <br />

                       </div>
                     ) : (
                         isUploading ? (
                           <Loader
                      type="Bars"
                      color="#00BFFF"
                      height={10}
                      width={50}
                      />
                         ) :(
                       <div>

                         <button onClick={handleSubmission2}  className="btn btn-light-primary btn-bold">
                          Save  </button>
                       </div>
                      )

                     )}

         </div>
        </div>
    </div>



<br/>

    <ContentInventory  key={childKey}></ContentInventory>

  </div>

	)
};
