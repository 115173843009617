import React from "react";
import { PieChart, Pie, Legend, Cell } from "recharts";
import { renderCustomizedLabel, culcPercentage } from "./utils";

export const PieChartWithLabel = ({
  data,
  width = 430,
  height = 350,
  showLegend = true,
  radius = 40,
  palette = ["#f7e5c5", "#ffb380", "#57b5bd", "#78c6cd", "#347E84"],
}) => {
  return (
    <div>
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          cx={"25%"}
          innerRadius={culcPercentage(radius, 30)}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={radius}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={palette[index % palette.length]}
            />
          ))}
        </Pie>
        {showLegend && (
          <Legend
            layout="verical"
            align="right"
            verticalAlign="top"
            height={200}
            width={200}
          />
        )}
      </PieChart>
    </div>
  );
};
