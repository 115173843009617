/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { VerticalBarChart3 } from "../VerticalBarChart3";
import { getWidth, reformForVericalBarChart3 } from "../utils";
import { DataContext } from "../context/DataContext";
import { LanguageContext } from "../context/LanguageContext";
import { MunicipalityBarChart4 } from "../MunicipalityBarChart4";
import { AsteriscSubnote } from "../Shared/AsteriscSubnote";
import { NoData } from "../Shared/NoData";

export function NeighbBarChart3({ name }) {
  const { json, safelyGetData } = React.useContext(DataContext);
  const { language } = React.useContext(LanguageContext);
  const data = reformForVericalBarChart3(
    safelyGetData("retail_market_median_yoc")
  );

  return (
    <>
      <div className={`card card-custom  gutter-b`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">{name}</span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-4 table-responsive">
          {!!json.retail_market_median_yoc ? (
            <div className="row" style={{ paddingLeft: "10%" }}>
              {json.Attica_market ? (
                <VerticalBarChart3
                  data={data}
                  width={getWidth(900, 900)}
                  margin={{ right: -100, left: -100 }}
                  height={435}
                  axis="right"
                  showLegend={false}
                  label={language === "EN" ? "YoC" : "Έτος κατασκευής"}
                  orientation={0}
                />
              ) : (
                <MunicipalityBarChart4 data={data} height={400} width={500} />
              )}
              <AsteriscSubnote target="retail_market_median_yoc" />
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </>
  );
}
