/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { VerticalBarChart3 } from "./VerticalBarChart3";
import { getWidth, getBarSize } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { AsteriscSubnote } from "./Shared/AsteriscSubnote";
import { NoData } from "./Shared/NoData";

export function MunicipalityBarChart2() {
  const { json, safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);

  const data = safelyGetData("office_markets_stock").map((d) => ({
    name: d[0],
    year: d[1],
    count: d[1],
  }));

  const culcLeftMargin = () => {
    return window.innerWidth < 500 ? "1%" : "10%";
  };

  return (
    <>
      <div
        className="card-body table-responsive"
        style={{ marginLeft: culcLeftMargin() }}
      >
        {!!json.office_markets_stock ? (
          <>
            <VerticalBarChart3
              data={data}
              width={getWidth(550, 800)}
              height={520}
              barSize={getBarSize(data)}
              margin={{ right: -100 }}
              axis="right"
              showLegend={false}
              label={getLabels().for_sale}
              showTicks={false}
              domain={["dataMin-10", "dataMax + 10"]}
            />
            <AsteriscSubnote target="office_markets_stock" />
          </>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}
