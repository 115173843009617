import React,{useState} from "react";
import { useHistory } from "react-router-dom";

import {useSubheader} from "../../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export function Hometrix_MVA_Generic()  {
    const history = useHistory();
  const sub=useSubheader();
 sub.setTitle("Under Construction");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [workbook, setWorkbook] = useState(null);

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,getIdTokenClaims,
   } = useAuth0();
  async function testScopes() {

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });



    return;
  }

  return (
    <>
      Test
    </>

  );


}
export default Hometrix_MVA_Generic;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
