import React from "react";
import { getColor } from "./utils";
import { LanguageContext } from "./context/LanguageContext";
import { BarChart, Bar, XAxis, YAxis, Label } from "recharts";

export const YearBarChart = ({
  data,
  width = 500,
  height = 300,
  showLegend = true,
}) => {
  const { getLabels } = React.useContext(LanguageContext);

  return (
    // changing the width of the ΒarChart will provide more ticks (year of construction points)
    <BarChart width={width} height={height} data={data} margin={{ left: 0 }}>
      <XAxis
        dataKey="name"
        interval={0}
        angle={-60}
        tickSize={30}
        tickLine={false}
        height={90}
      >
        <Label
          value={showLegend ? getLabels().yoc : ""}
          position="insideBottom"
        />
      </XAxis>

      <YAxis
        domain={["dataMin", "dataMax + 1"]}
        tick={false}
        label={{
          value: showLegend && getLabels().for_sale,
          angle: -90,
          offset: 40,
          position: "insideTopCenter",
        }}
      />

      <Bar dataKey="houses" fill={getColor(3)} name="Houses" />
    </BarChart>
  );
};
