import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import { Form,Button } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export function Hometrix_MVA_Client()  {
    const history = useHistory();
  const sub=useSubheader();
 sub.setTitle("Clients");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [workbook, setWorkbook] = useState(null);

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,getIdTokenClaims,
   } = useAuth0();
  async function testScopes() {

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });



    return;
  }

  return (
    <>
    <div>
      <div className="col-xl-12">
        <div className="row card">

          <div className="col-xl-12 card-header">
                <h3>Στοιχεία Πελάτη</h3>
          </div>
          <div className="col-xl-12 card-body">

          <Form.Group>
            <Form.Label>Επωνυμία πελάτη</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">

            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>ΑΦΜ πελάτη</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">

            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Διεύθυνση πελάτη</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">

            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Στοιχεία Υπευθύνου</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">

            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Τηλέφωνο επικοινωνιας</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">

            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Website</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">

            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email επικοινωνίας</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">

            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email επίκοινωνίας μισθωμάτων</Form.Label>
            <Form.Control type="text" name="title" id="title"  placeholder="" />
            <Form.Text className="text-muted">
            Αυτοματοποιημένη ενημέρωση
            </Form.Text>
          </Form.Group>
          <br/><br/>



           </div>
          </div>
      </div>
    </div>
<br/><br/>
          <NavLink className="btn btn-light-warning btn-bold" to="/mva/clients">
          Επιστροφή
          </NavLink>
          &nbsp;  &nbsp;  &nbsp;
          <NavLink className="btn btn-light-primary btn-bold" to="/mva/clients">
        Αποθήκευση
          </NavLink>
          &nbsp;&nbsp;


  <br/>
    </>

  );


}
export default Hometrix_MVA_Client;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
