import React from "react";

export const reformForPieChart = (arr) => {
  return arr.map((subArray) => ({
    name: subArray[0],
    value: Math.round(subArray[2]),
  }));
};

const RADIAN = Math.PI / 180;

export const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      textAnchor={"start"}
      dominantBaseline="central"
      x={x}
      y={y}
      fill="white"
      style={{ fontSize: percent > 10 ? "12px" : "10px" }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
