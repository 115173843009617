import React from "react";
import { OfficeReport } from "./OfficesDashboard";
import { DataProvider } from "./context/DataContext";
import { LanguageProvider } from "./context/LanguageContext";

export function OfficesPage(props) {
  return (
    <DataProvider data={props}>
      <LanguageProvider>
        <OfficeReport />
      </LanguageProvider>
    </DataProvider>
  );
}
