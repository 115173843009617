export const culcIconWidth = () => {
  return window.innerWidth > 1200 ? "40%" : "30%";
};

export const culcMarginTop = () => {
  const w = window.innerWidth;
  if (w > 1200) return "-25%";
  return "-18%";
};

export const unTag = (str = "") => {
  return str.replace("<sup>", "").replace("</sup>", "");
};
