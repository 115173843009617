/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { PieChartWithLabel } from "../PieChartWithLabel";
import { DataContext } from "../context/DataContext";
import { getColor, darkGrey, findPercentages } from "../utils";
import { NoData } from "../Shared/NoData";

export function PieChart4() {
  const { json, safelyGetData } = React.useContext(DataContext);
  const parsed = safelyGetData("retail_markets_stock");

  const data = findPercentages(parsed)
    .map((o) => ({
      name: o[0],
      value: o[1],
    }))
    .reverse();

  return (
    <div className="card-body centered">
      {!!json.retail_markets_stock ? (
        <PieChartWithLabel
          palette={[getColor(3), darkGrey]}
          height={320}
          showLegend={true}
          radius={110}
          data={data.reverse()}
        />
      ) : (
        <NoData />
      )}
    </div>
  );
}
