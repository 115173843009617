import React, { useContext } from "react";
import { DataContext } from "../context/DataContext";
import flats from "../assets/icons/flats.png";
import { getColor, culcIconWidth, culcMarginTop, unTag } from "../utils";
import { LanguageContext } from "../context/LanguageContext";
import { InsufficientData } from "../Shared/InsufficientData";

export const Flats = () => {
  const { json } = useContext(DataContext);
  const { getContent, getLabels } = useContext(LanguageContext);

  const CONTENT = getContent();

  return (
    <div>
      <div className="col metric">
        <img src={flats} alt="flats icon" width={culcIconWidth()}></img>
      </div>

      {json.stock >= 30 ? (
        <div className="col">
          <ul
            className="metric-list"
            style={{ marginTop: culcMarginTop(), marginRight: "7%" }}
          >
            <h3 style={{ color: getColor(3) }} className="metric-title">
              {getLabels().flats}
            </h3>

            <li>
              <h5>
                {CONTENT.metrics.med_yoc}{" "}
                <span style={{ color: getColor(3) }}>
                  {json.flats_median_yoc}
                </span>
              </h5>
            </li>

            <li>
              <h5>
                {CONTENT.metrics.med_price}{" "}
                <span style={{ color: getColor(3) }}>
                  {json.flats_median_pr}€
                </span>
              </h5>
            </li>

            <li>
              <h5>
                {CONTENT.metrics.after_2010}{" "}
                <span style={{ color: getColor(3) }}>
                  {json.flats_newly_built}%
                </span>
              </h5>
            </li>
            <li>
              <h5>
                {CONTENT.metrics.med_surf}{" "}
                <span style={{ color: getColor(3) }}>
                  {json.flats_median_area} m²
                </span>
              </h5>
            </li>

            <li>
              <h5>
                {CONTENT.metrics.floor}{" "}
                <span style={{ color: getColor(3) }}>
                  {unTag(json.flats_typ_floor)}
                </span>
              </h5>
            </li>
          </ul>
        </div>
      ) : (
        <InsufficientData
          text={CONTENT.metrics.insufficient_data}
          text2={CONTENT.metrics.few_flats}
        />
      )}
    </div>
  );
};
