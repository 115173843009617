/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../_helpers";
// import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./BreadCrumbs";

export function SubHeader(props) {
  return (
    <div id="kt_subheader" className={`subheader py-3 py-lg-8`}>
      <div
        className={`container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* begin::Heading */}
          <div className="d-flex align-items-baseline mr-5">
            {/* begin::Title */}
            <h2 className="subheader-title text-dark font-weight-bold my-2 mr-3">
              {props.TITLES.main}
            </h2>
            {/* end::Title */}

            <BreadCrumbs items={props} />
          </div>
          {/* end::Heading */}
        </div>
        {/* Info */}

        {/* Toolbar */}
        <div className="d-flex align-items-center flex-wrap">


          {/* <QuickActions /> */}

          {/* Button */}
    
        </div>
      </div>
    </div>
  );
}
