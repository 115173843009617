import React,{useState,useEffect,useRef} from "react";
import { useHistory } from "react-router-dom";
import { NavLink,Link } from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { confirm } from "react-confirm-box";

import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete,Marker } from '@react-google-maps/api';
import { TextField,makeStyles,Button } from '@material-ui/core';
import { Modal } from "react-bootstrap";

import jwt from 'jwt-decode'
export function BOG_Assets()  {
  const center = {
    lat: 38.0572437,
    lng: 23.8481676
  };
  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  const libraries = ["places","geometry"];
  const useStyles = makeStyles({
    margin_r: {
      margin: '0px 5px 0px 0px',
    },

  });
  const [permissions, setPermissions] = useState("");
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;

   const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [data, setData] = useState({});
  const [data2, setData2] = useState({});
  const [geoData, setGeoData] = useState(null);
    const [map, setMap] = useState(null);
  function onloadMap(ref){
    setMap(ref);

  }
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();
   async function getScopes() {

     const accessToken = await getAccessTokenSilently({
          audience:process.env.REACT_APP_AUTH0_AUDIENCE
        });
      const data = jwt(accessToken)

      setPermissions(data.permissions);
     return;
   }

   useEffect(()=>{
       getScopes()
      getContent()
    }, []) // <-- empty dependency array
    async function deleteBOG(id) {
       const result = await confirm("Are you sure?");
        if (!result) return;
  //    console.log("delete: "+id);return;
        setLoading(true);


      const accessToken = await getAccessTokenSilently({
           audience: process.env.REACT_APP_AUTH0_AUDIENCE
         });
         var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
         apiURL+="?action=deleteBOG&id="+id;
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       body:"",
          headers: {

            'authorization': 'Bearer '+accessToken,
          },
        });


       getContent();
       //  afterLoad();
    }
  async function getContent() {

    setLoading(true);
     setData({});

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=getBOGS";
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
      console.log(resp);
      setData(resp.results);
     setLoading(false);
     getBuildings(resp.results);

  }
  async function getBuildings(d) {
    var b=[];
    var buildings=[];
     d.forEach(function(row) {
       if (buildings[row.data.propertyID]===undefined) {
         buildings[row.data.propertyID]=[];
       }
       buildings[row.data.propertyID].push(row);

     });

         for (var building in buildings) {
            var row=[];
            row['id']=building;
            row['buildings']=buildings[building];
            b.push(row);
         }
      setData2(b);
      setLoading2(false);
  }
  async function deleteAll() {

    setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=";
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
      console.log(resp);
      setData(resp);
     setLoading(false);
  }
  function routeChange(path) {
    console.log(path);
    history.push(path);
  }
 function onMarkerClick (d)  {  //<- event object, not a marker object!
   var path=`/bog/asset/${d._id}`;
    history.push(path);
}

async function getMarketInsights(lt,lg) {
  handleShow();
    setGeoData(null);

     var apiURL = process.env.REACT_APP_SERVER_URL+"/mongoTest?";
     apiURL+="lng="+lg+"&lat="+lt;
     console.log(apiURL);
    const metadataResponse = await fetch(apiURL, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
   mode: 'cors', // no-cors, *cors, same-origin
   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

   headers: {
     "Content-Type":"application/json",
   },
    });

    var resp = await metadataResponse.json();
    resp=resp['MarketInsights'];
    console.log(resp);
     const response = [];
       if (resp['Residential_Pdf']!==null&&resp['Residential_Pdf']!==null) {
       response.push( <div className="d-flex align-items-center py-lg-0 py-2 pr-2 mt-5">
         <div className="d-flex flex-column text-right">

           <span className="text-muted font-size-sm font-weight-bolder"><Link className="btn btn-light-primary btn-bold" to={{ pathname:resp['Residential_Pdf']}} target="_blank">
         Residential Market Report
           </Link></span></div><br/><br/></div>)
           if (resp['Residential_elstat_kpis']!==null&&resp['Residential_elstat_kpis']!==null) {
           response.push(<div className="d-flex align-items-center py-lg-0 py-2 pr-2 mt-5">
             <div className="d-flex flex-column text-right">

               <span className="text-muted font-size-sm font-weight-bolder"><Link className="btn btn-light-primary btn-bold" to={{pathname:`/residential_charts`,extra:resp}}>
            Residential Charts Market Report
               </Link></span></div></div>)
             }
     }
     if (resp['Retail_Pdf']!==null&&resp['Retail_Pdf']!==null) {
     response.push( <div className="d-flex align-items-center py-lg-0 py-2 pr-2 mt-5">
       <div className="d-flex flex-column text-right">

         <span className="text-muted font-size-sm font-weight-bolder"><Link className="btn btn-light-primary btn-bold" to={{ pathname:resp['Retail_Pdf']}} target="_blank">
       Retail Market Report
         </Link></span></div><br/><br/></div>)
         if (resp['Retail_elstat_kpis']!==null&&resp['Retail_elstat_kpis']!==null) {
         response.push(<div className="d-flex align-items-center py-lg-0 py-2 pr-2 mt-5">
           <div className="d-flex flex-column text-right">

             <span className="text-muted font-size-sm font-weight-bolder"><Link className="btn btn-light-primary btn-bold" to={{pathname:`/retail_charts`,extra:resp}}>
           Retail Charts Market Report
             </Link></span></div></div>)
           }
   }
   if (resp['Office_Pdf']!==null&&resp['Office_Pdf']!==null) {
   response.push( <div className="d-flex align-items-center py-lg-0 py-2 pr-2 mt-5">
     <div className="d-flex flex-column text-right">

       <span className="text-muted font-size-sm font-weight-bolder"><Link className="btn btn-light-primary btn-bold" to={{ pathname:resp['Office_Pdf']}} target="_blank">
     Office Market Report
       </Link></span></div><br/><br/></div>)
       if (resp['Office_elstat_kpis']!==null&&resp['Office_elstat_kpis']!==null) {
       response.push(<div className="d-flex align-items-center py-lg-0 py-2 pr-2 mt-5">
         <div className="d-flex flex-column text-right">

           <span className="text-muted font-size-sm font-weight-bolder"><Link className="btn btn-light-primary btn-bold" to={{pathname:`/office_charts`,extra:resp}}>
         Office Charts Market Report
           </Link></span></div></div>)
         }
 }
      console.log(response);
    setGeoData(response);



}


async function getGeoValues(lt,lg) {
  handleShow();
    setGeoData(null);

     var apiURL = "https://hometrix.eu/console/geo/json2.php?";
     apiURL+="Lng="+lg+"&Lat="+lt;
     console.log(apiURL);
    const metadataResponse = await fetch(apiURL, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
   mode: 'cors', // no-cors, *cors, same-origin
   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

   headers: {
     "Content-Type":"application/json",
   },
    });

    var translations=[];
    translations['el']=[];
    translations['en']=[];
    translations['en']['pe_pol_enot_pu_name']="G.U.P. Sector ID";
    translations['en']['ot_number']="Building Block ID";
    translations['en']['artiothta_emb_m2']="Par Surface (Sq.m.)";
    translations['en']['artiothta_bathos_m']="Minimum Depth (m.)";
    translations['en']['artiothta_pros_m']="Minimum Face/Facade (m.)";
    translations['en']['artiothta_emb_par_m2']="Parity (by Deviation)";
    translations['en']['artiothta_pros_par']="Minimum Face/Facade (m.) (by Deviation)";
    translations['en']['sd_domisis_sd_domisis']="Building Coefficient";
    translations['en']['sk_synt_kalyps_sk_parekl']="Coverage Coefficient";
    translations['en']['gps_sxooap_gps_name']="General Urban Plan";
    translations['en']['artiothta_fek']="O.G.G. Approval or Modification";
    translations['en']['artiothta_fek_thema']="O.G.G. Title";
    translations['en']['sd_domisis_fek']="Building Coefficient O.G.G";
    translations['en']['sd_domisis_fek_thema']="Building Coefficient Title";
    translations['en']['gps_sxooap_fek']="O.G.G General Urban Plan / City Spatial & Residential Plans";
    translations['en']['gps_sxooap_fek_relat']="Relative O.G.G General Urban Plan / City Spatial & Residential Plans";

    translations['el']['pe_pol_enot_pu_name']="Πολεοδομική Ενότητα";
    translations['el']['ot_number']="Οικοδομικό Τετράγωνο (Ο.Τ.)";
    translations['el']['artiothta_emb_m2']="Εμβαδό αρτιότητας (τ.μ.)";
    translations['el']['artiothta_bathos_m']="Ελάχιστο Βάθος (μ.) ";
    translations['el']['artiothta_pros_m']="Ελάχιστο Πρόσωπο (μ.) ";
    translations['el']['artiothta_emb_par_m2']="Αρτιότητα (Παρέκκλιση)";
    translations['el']['artiothta_pros_par']="Ελάχιστο πρόσωπο (Παρέκκλιση)";
    translations['el']['sd_domisis_sd_domisis']="Συντελεστής Δόμησης";
    translations['el']['sk_synt_kalyps_sk_parekl']="Συντελεστής Κάλυψης";
    translations['el']['gps_sxooap_gps_name']="Γεν. Πολεοδ. Σχέδιο";
    translations['el']['artiothta_fek']="ΦΕΚ Έγκρ. ή Τροπ/σης ";
    translations['el']['artiothta_fek_thema']="Τίτλος ΦΕΚ";
    translations['el']['sd_domisis_fek']="ΦΕΚ Συντελεστή Δόμησης";
    translations['el']['sd_domisis_fek_thema']="Τίτλος ΦΕΚ Συντελεστή Δόμησης ";
    translations['el']['gps_sxooap_fek']="ΦΕΚ ΓΠΣ/ΣΧΟΟΑΠ";
    translations['el']['gps_sxooap_fek_relat']="Σχετικά ΦΕΚ ΓΠΣ/ΣΧΟΟΑΠ";



    const resp = await metadataResponse.json();
    console.log(resp);
     const response = [];
      /*for(var key in resp) {
        if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {
          response.push(<p>{key} : {resp[key].toString()}</p>);
        }
      }*/
      response.push(<br/>);
      response.push(<h4>Γενικά Πολεοδομικά Στοιχεία</h4>);
      response.push(<br/>);
      var key;
      key="pe_pol_enot_pu_name"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="ot_number"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      response.push(<br/>);
      response.push(<h4>Όροι Δόμησης</h4>);
      response.push(<br/>);
      key="artiothta_emb_m2"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="artiothta_bathos_m"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="artiothta_pros_m"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="artiothta_emb_par_m2"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="artiothta_pros_par"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="sd_domisis_sd_domisis"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="sk_synt_kalyps_sk_parekl"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }

      response.push(<br/>);
      response.push(<h4>Λοιπά Πολεοδομικά Στοιχεία </h4>);
      response.push(<br/>);
      key="gps_sxooap_gps_name"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="artiothta_fek"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="artiothta_fek_thema"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="sd_domisis_fek"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="sd_domisis_fek_thema"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="gps_sxooap_fek"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }
      key="gps_sxooap_fek_relat"; if (resp[key]!==null&&resp[key].toString().length>0&&typeof resp[key]!== 'object')  {   response.push(<p>{translations['el'][key]} : {resp[key].toString()}</p>); }

        console.log(response);
    setGeoData(response);



}
 function handleClose() {
   setShow(false);

 }

  function handleShow() {
  setShow(true);
 }

 function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  if (months==1) {
    return (<><span style={{ color: 'red' }}>ΠΡΟΣ ΕΤΗΣΙΑ ΑΝΑΠΡΟΣΑΡΜΟΓΗ</span></>);
  }
  return "";
}
  return (
    <>
      {/* begin::List Widget 14 */}
     { JSON.stringify(permissions).search("bog:all")>0 ?
      <NavLink className="btn btn-light-primary btn-bold" to="/bog/asset">
      Νέα εγγραφή
      </NavLink>
    :<></>}
      <br/>      <br/>
      {data.map!=undefined?
        <>
      <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
          libraries={libraries}
        >
          <GoogleMap
            onLoad={onloadMap}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={8}
          >
          {data.map!=undefined?data.map(d => (
            <Marker
              key={`${d._id}`}
              onClick={onMarkerClick.bind(this,d)}
              position={{lat:parseFloat(d.data.lat),lng:parseFloat(d.data.lng)}}
            />))
            : ( <></> )}




          </GoogleMap>
        </LoadScript>
        </>
      :<></>}
      <div className={`card card-custom `}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
           Εγγραφές
          </h3>
          <div className="card-toolbar">

          </div>
        </div>
        {/* end::Header */}


        <div className="card-body pt-2">




            {loading ?    <Loader
        type="Bars"
        color="#00BFFF"
        height={20}
        width={50}
        />:(
          <>
         {data.map!=undefined?data.map(d => (

           <div key={d.collateralId} className="d-flex flex-wrap align-items-center mb-10">
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
              <a
                href="#"
                className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
              >
              {d.collateralId}  | {d.data.leaser_name}  {monthDiff(new Date(),new Date(d.data.lease_readjustment))}
              </a>
              <span className="text-muted font-weight-bold font-size-sm my-1">
              {d.data.uniqueId}
              </span>
              <span className="text-muted font-weight-bold font-size-sm">

                <span className="text-secondary font-weight-bold">{d._id}</span>
              </span>
              <span className="text-muted font-weight-bold font-size-sm">

                <span className="text-primary font-weight-bold">{d.data.lat},{d.data.lng}</span>
              </span>
            </div>
            { JSON.stringify(permissions).search("bog:all")>0 ?

             <div className="d-flex align-items-center py-lg-0 py-2 pr-2">
               <div className="d-flex flex-column text-right">
                 <span className="text-dark-75 font-weight-bolder font-size-h4">
                 <button  onClick={ ()=> deleteBOG(d._id)} className="btn btn-light-warning btn-bold">
                  Διαγραφή</button>
                 </span>

               </div>
             </div>
             :<></>}
            <div className="d-flex align-items-center py-lg-0 py-2 pr-2">
              <div className="d-flex flex-column text-right">
                <span className="text-dark-75 font-weight-bolder font-size-h4">

                </span>

              </div>
            </div>
            <div className="d-flex align-items-center py-lg-0 py-2 pr-2">
              <div className="d-flex flex-column text-right">

                <span className="text-muted font-size-sm font-weight-bolder">
                { JSON.stringify(permissions).search("bog:all")>0 ?
                <NavLink className="btn btn-light-primary btn-bold" to={`/bog/asset/${d._id}`}>
                Επεξεργασία Ακινήτου
                </NavLink>
                :
                <NavLink className="btn btn-light-primary btn-bold" to={`/bog/asset/${d._id}`}>
                Εμφάνιση Ακινήτου
                </NavLink>
              }
                </span>
              </div>
            </div>

            </div>

          )) : ( <></> )}
        </>
        )
        }











        </div>
        {/* end::Body */}
      </div>

      {/* end::List Widget 14 */}
      <br />

      <div className={`card card-custom `}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
           Ακίνητα
          </h3>
          <div className="card-toolbar">

          </div>
        </div>
        {/* end::Header */}


        <div className="card-body pt-2">




            {loading2 ?    <Loader
        type="Bars"
        color="#00BFFF"
        height={20}
        width={50}
        />:(
          <>
         {data2.map!=undefined?data2.map(d => (

           <div key={d.id} className="d-flex flex-wrap align-items-center mb-10">
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
              <a
                href="#"
                className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
              >
              {d.id}
              </a>
              <span className="text-muted font-weight-bold font-size-sm my-1">
              Πλήθος Εγγραφών : {d.buildings.length}
              </span>
              <span className="text-muted font-weight-bold font-size-sm">

                <span className="text-primary font-weight-bold">{d.buildings[0].data.lat},{d.buildings[0].data.lng}</span>
              </span>
            </div>
            <div className="d-flex align-items-center py-lg-0 py-2 pr-2">
              <div className="d-flex flex-column text-right">
                <span className="text-dark-75 font-weight-bolder font-size-h4">
                <button  onClick={ ()=> getMarketInsights(d.buildings[0].data.lat,d.buildings[0].data.lng)} className="btn btn-light-info btn-bold">
                Πληροφορίες Market Insights</button>
                </span>


              </div>
            </div>
            <div className="d-flex align-items-center py-lg-0 py-2 pr-2">
              <div className="d-flex flex-column text-right">

                <span className="text-muted font-size-sm font-weight-bolder">
                <button  onClick={ ()=> getGeoValues(d.buildings[0].data.lat,d.buildings[0].data.lng)} className="btn btn-light-info btn-bold">
                Πληροφορίες GIS</button>


                </span>
              </div>
            </div>



            </div>

          )) : ( <></> )}
        </>
        )
        }


                      <Modal show={show} onHide={()=>handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Πληροφορίες</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{geoData!=null?
                          <>

                          <div>{geoData}</div>
                          </>
                         :<><Loader
                     type="Bars"
                     color="#00BFFF"
                     height={20}
                     width={50}
                     /></>}</Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={()=>handleClose()}>

                            ΚΛΕΙΣΙΜΟ
                          </Button>

                        </Modal.Footer>
                      </Modal>









        </div>
        {/* end::Body */}
      </div>

    </>
  );

}
export default BOG_Assets;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
