/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { HeatMap1 } from "../HeatMap1";
import { DataContext } from "../context/DataContext";
import { NoData } from "../Shared/NoData";

export function HeatMap1Tab({ name }) {
  const { json } = React.useContext(DataContext);

  return (
    <>
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">{name}</h3>
        </div>
        {/* Body */}
        <div className="card-body pt-0">
          {!!json["overview_heatmap_area_yoc"] ? <HeatMap1 /> : <NoData />}
          {/* end: Item */}
        </div>
      </div>
    </>
  );
}
