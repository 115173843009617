/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { MunicipalityBarChart2 } from "../MunicipalityBarChart2";
import { PieChart4 } from "./PieChart4";
import { DataContext } from "../context/DataContext";

export function NeighbBarChart2({ name }) {
  const { json } = React.useContext(DataContext);

  return (
    <>
      {/* begin::Stats Widget 10 */}
      <div className={`card card-custom gutter-b`}>
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">{name}</h3>
          <div className="card-toolbar"></div>
        </div>
        {/* begin::Body */}

        {/* tiitle here */}
        {json.Attica_market ? (
          <MunicipalityBarChart2
            width={window.innerWidth > 1200 ? 430 : 370}
            height={350}
            showLegend={false}
          />
        ) : (
          <PieChart4 />
        )}
      </div>
      {/* end::Body */}

      {/* end::Stats Widget 10 */}
    </>
  );
}
