import React from "react";
import HeatMap from "react-heatmap-grid";
import { DataContext } from "./context/DataContext";
import { CustomLabel } from "./Shared/CustomLabel";
import { LanguageContext } from "./context/LanguageContext";
import {
  makeMatrix,
  matrixSize,
  sortSurfaceStrings,
  sortYearStrings,
  heatMapColor,
} from "./utils";

export const HeatMap2 = () => {
  const { safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);
  const parsed = safelyGetData("overview_heatmap_price_area_yoc");
  const yLabels = sortSurfaceStrings([...new Set(parsed.map((d) => d[0]))]);
  const xLabels = sortYearStrings([...new Set(parsed.map((d) => d[1]))]);

  return (
    <div className="heatmap2">
      <div className={window.innerWidth > 500 ? "heatmap2-chart" : ""}>
        <CustomLabel value="m²" position="left" />
        <HeatMap
          xLabels={xLabels}
          yLabels={yLabels}
          xLabelsLocation={"bottom"}
          xLabelWidth={40}
          yLabelWidth={80}
          data={makeMatrix(xLabels, yLabels, parsed, true)}
          squares
          height={matrixSize(xLabels.length)} // overall size of the grid is set from here
          cellStyle={(background, value, min, max, data, x, y) => ({
            background: heatMapColor(value, min, max),
            fontSize: "11.5px",
          })}
        />
      </div>
      <CustomLabel value={getLabels().yoc} />
    </div>
  );
};
