/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useState,useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu1 } from "../../../_metronic/_partials/dropdowns";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useAuth0 } from '@auth0/auth0-react';
import Loader from "react-loader-spinner";

export function ContentAVM(props) {

  const id=props.id;
  const category=props.category;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();
   useEffect(()=>{
      getContent()
    }, []) // <-- empty dependency array
  async function getContent() {

    setLoading(true);
     setData({});

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=getContentAVM&id="+id+"&category="+category;
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();


      setData(resp.results);
     setLoading(false);
  }
  async function deleteAVM(contentid) {

    setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=deleteContentAVM&contentid="+contentid;
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();

      //setData(resp);
      getContent();
  //setLoading(false);
  }

  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">

          </h3>
          <div className="card-toolbar">

          </div>
        </div>
        {/* end::Header */}


        <div className="card-body pt-2">




            {loading ?    <Loader
        type="Bars"
        color="#00BFFF"
        height={20}
        width={50}
        />:(
          <>
         {data!==undefined&&data.map!==undefined?data.map(d => (
           <>
           <div  key={d._id} className="d-flex flex-wrap align-items-center mb-10">
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
              <a
                href="#"
                className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
              >
                {d.Title}
              </a>
              <span className="text-muted font-weight-bold font-size-sm my-1">
             {d.objectURL.endsWith("jpg")||d.objectURL.endsWith("png")?<>
              <a href={d.objectURL} target="_blank">
                <img src={d.objectURL} width="100"></img>
              </a>
              </>:<> <a href={d.objectURL} target="_blank">
                  Κατέβασε το αρχείο
                </a></>}
              </span>

            </div>

            <div className="d-flex align-items-center py-lg-0 py-2">
              <div className="d-flex flex-column text-right">
                <span className="text-dark-75 font-weight-bolder font-size-h4">

                  <br />

                  <button onClick={()=>{
                    deleteAVM(d._id)}
                   }  className="btn btn-light-danger btn-bold">
                   Διαγραφή </button>
                </span>

              </div>
            </div>

            </div>
            </>
          )) : ( <></> )}
        </>
        )
        }











        </div>
        {/* end::Body */}
      </div>

      {/* end::List Widget 14 */}



    </>
  );

}
