import React,{useState,useEffect,PureCompoment} from "react";
import { useHistory } from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete,Marker } from '@react-google-maps/api';
import { Form,Button,Col,Table } from 'react-bootstrap';
import { TextField,makeStyles } from '@material-ui/core';
import {InputAdornment} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import { ContentInventoryInspection } from '../../modules/Hometrix/ContentInventoryInspection'
import {AreaChart,Area, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



var QRCode = require('qrcode.react');
const containerStyle = {
  width: '100%',
  height: '400px'
};
const libraries = ["places","geometry"];
const useStyles = makeStyles(theme => ({
  margin_r: {
    margin: '0px 5px 0px 0px',
  },
  cerved_green: {
    backgroundColor:'#23cba7',
  },
  cerved_blue: {
    backgroundColor:'#649dde',
  },
  cerved_mauve: {
    backgroundColor:'#f4ebfff',
  },
  cerved_mauve_1: {
    backgroundColor:'#e9d6ff',
  },
  dcf: {
     margin: theme.spacing(1),
        fontSize:100,//Insert your required size


  }
}));

export function Hometrix_MVA_AssetDCF(props)  {

  const id=props.match.params.id;
  const [globalLoad, setGlobalLoad] = useState(true);
  const classes = useStyles();
  const [qrData, setQrData] = useState(0);
  const [formData, updateFormData] = useState("");
  const [globalData, setGlobalData] = useState(null);
  const [imageData, setImageData] = useState({});
  const [geoValues, setGeoValues] = useState(false);
  const [calculationData,setCalculationData]=useState(null);
  const [globalResults, setGlobalResults] = useState(null);
  const [dcf, setDcf] = useState(null);
  const [chartData1,setChartData1]=useState(null);
  const [chartData2,setChartData2]=useState(null);
  const [chartData3,setChartData3]=useState(null);
  const [chartData4,setChartData4]=useState(null);
  const [chartDataCalc,setChartDataCalc]=useState(null);
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();
   async function getContentImages() {

   }

   async function getGeoValues(lt,lg) {



   }


   async function getQR(data) {
     var a=qrData;
     a++;
     if (a>2) a=0;
     setQrData(a);


     return;
   }
   async function getContent() {

     setGlobalLoad(true);
      setData(null);

     const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
        apiURL+="?action=getMVA&id="+id;
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      body:data,
         headers: {

           'authorization': 'Bearer '+accessToken,
         },
       });

       const resp = await metadataResponse.json();
       var d=resp.results[0].data;


    //   //console.log("---DATA---");
      // //console.log(globalData)

        setLng(d.lng);
        setLat(d.lat);
        setDistrict(d.district);
        setPrefecture(d.prefecture);
        setPostalCode(d.postalCode);
        setMunicipality(d.municipality);
        setAddress(d.address);
        setGlobalData(d);
       setGlobalLoad(false);

   }
   React.useEffect(() => {
   if (globalData!==null) {
     afterLoad();
   }
 }, [globalData]);
   useEffect(()=>{
      getContent()
      getContentImages();



    }, []) // <-- empty dependency array
    const [selectedFile, setSelectedFile] = useState();
    const [childKey, setChildKey] = useState(0);
    const [dataTitle, setDataTitle] = useState();
  	const [isSelected, setIsSelected] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const [isDanger, setIsDanger] = useState(false);
    const [isFront, setIsFront] = useState(false);
    const [isMap, setIsMap] = useState(false);

      const [isResolve, setIsResolve] = useState(false);

    async function handleSubmission() {


    };
  async function onSubmit(event) {

  }
  function getDt(dt) {
    var d = new Date(dt);
    var new_dt= d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

    return(new_dt);
  }
  function getNextDt(dt) {
    var d = new Date(dt);
    d.setDate(d.getDate()+1);
    var new_dt= d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

    return(new_dt);
  }
   function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
}
  async function exportCalc() {


    // GAPCosts
    var results={};
    results['GAPcosts']=globalData.generic_expenses*globalData.leaseTotalSQM*globalData.lease_empty;

/*    results['AgentCost']=globalData.agency_expenses*(globalData.lease_price*12);
    results['OpexCost']=globalData.operating_expenses*(globalData.lease_price*12*globalData.ary_yearly_adjust);
    //needs fix
    results['CapexCost']=globalData.renovation_expenses*(globalData.lease_price*12*globalData.ary_yearly_adjust);
    //needs fix
    */


    var dateObject_lease_start=new Date();
    if (globalData.lease_start!="") {
      dateObject_lease_start = new Date(globalData.lease_start);
    }

    var dateObject_lease_end=new Date();
    if (globalData.lease_end!="") {
      dateObject_lease_end = new Date(globalData.lease_end);
    }
    var dateObject_lease_revaluate=new Date();
    if (globalData.lease_revaluate!="") {

      dateObject_lease_revaluate = new Date(globalData.lease_revaluate);
    }
    var dateObject_lease_readjustment=new Date();
    if (globalData.lease_readjustment!="") {

      dateObject_lease_readjustment = new Date(globalData.lease_readjustment);
    }
    var dateObject_lease_start_dcf=new Date(dateObject_lease_revaluate.toISOString());
    dateObject_lease_start_dcf.setDate(dateObject_lease_start_dcf.getDate()+1);

    var dateObject_lease_end_dcf=new Date(dateObject_lease_start_dcf.toISOString());
    dateObject_lease_end_dcf.setFullYear(dateObject_lease_start_dcf.getFullYear()+10);
    dateObject_lease_end_dcf.setDate(dateObject_lease_end_dcf.getDate()-1);

    var dateObject_lease_end_dcf_for_exit=new Date(dateObject_lease_start_dcf.toISOString());
    dateObject_lease_end_dcf_for_exit.setFullYear(dateObject_lease_start_dcf.getFullYear()+11);
    dateObject_lease_end_dcf_for_exit.setDate(dateObject_lease_end_dcf_for_exit.getDate()-1);

    var dateObject_lease_total_months=monthDiff(dateObject_lease_start_dcf,dateObject_lease_end_dcf_for_exit)+1;
    //begin CALCUATIONS
    var dateObject_calc=new Date(dateObject_lease_start_dcf.toISOString());
    var price=globalData.lease_price;
    var market_price=globalData.lease_market_price*globalData.leaseTotalSQM;
    var readj=-1;
    results['calc']=[];
    var lastYear=dateObject_lease_start_dcf.getFullYear();
    var totalValue=0;
    var totalValueByYear=0;
    var totalValueByYears=[];

    var operating_costs=[];
    var capex_costs=[];
    var void_costs=[];
    var agent_costs=[];
    var renovation_costs=[];
    var discount_factor=[];
    var discount_factor_ary=parseFloat(globalData.ary)-parseFloat(globalData.ary_yearly_deprecation)+parseFloat(globalData.ary_ypervalon_percentage)+parseFloat(globalData.ary_risk_premium)+parseFloat(globalData.ary_fer_percentage);

    for(var i=1;i<=dateObject_lease_total_months;i++) {

      var row={};
      if (readj>0) readj++;
      if (readj==-1) {
        if (monthDiff(dateObject_lease_readjustment,dateObject_calc)==0) {
           // needs re Adjustmen
           price=price*(1+(globalData.ary_yearly_adjust/100)+(globalData.ary_yearly_adjust_cpi/100)); readj=1;

        }
      }
      if (readj==13) {
          price=price*(1+(globalData.ary_yearly_adjust/100)+(globalData.ary_yearly_adjust_cpi/100)); readj=1;

      }
      if (lastYear!=dateObject_calc.getFullYear()) {
        totalValueByYears[lastYear]=totalValueByYear;
        operating_costs[lastYear]=totalValueByYear*(globalData.operating_expenses/100);
        capex_costs[lastYear]=totalValueByYear*(globalData.capex/100);
        var cur=lastYear-dateObject_lease_start_dcf.getFullYear();
        cur++;

        var c;
        c=Math.pow((1+(discount_factor_ary/100)),cur-0.5);

        discount_factor[lastYear]=1/c;

        totalValueByYear=0;

        var lastYear=dateObject_calc.getFullYear();
        market_price=market_price*(1+(globalData.ary_yearly_adjust/100)+(globalData.ary_yearly_adjust_cpi/100));


      }
      row['Dt']=dateObject_calc.toISOString();
      row['LeaseValue']=price;
      row['MarketValue']=market_price;
      var m=monthDiff(dateObject_calc,dateObject_lease_end)
      if (m>=0) {
        row['Value']=price;
      } else {
          if (Math.abs(m)>globalData.lease_empty) {
          row['Value']=market_price;

        } else {
          row['Value']=0;
          if (void_costs[lastYear]===undefined||void_costs[lastYear]==0) {
            void_costs[lastYear]=0;
          }
          void_costs[lastYear]+=globalData.generic_expenses*globalData.leaseTotalSQM;

          if (agent_costs[lastYear]===undefined||agent_costs[lastYear]==0) {
            agent_costs[lastYear]=0;
          }
          var prevLastYear=lastYear-1;

          if (agent_costs[prevLastYear]===undefined||agent_costs[prevLastYear]==0) {
            agent_costs[prevLastYear]=0;
          }
          if (agent_costs[prevLastYear]==0&&agent_costs[lastYear]==0) {
            agent_costs[lastYear]=market_price*12*(globalData.agency_expenses/100);
          }


          if (renovation_costs[lastYear]===undefined||renovation_costs[lastYear]==0) {
            renovation_costs[lastYear]=0;
          }
          var prevLastYear=lastYear-1;

          if (renovation_costs[prevLastYear]===undefined||renovation_costs[prevLastYear]==0) {
            renovation_costs[prevLastYear]=0;
          }
          if (renovation_costs[prevLastYear]==0&&renovation_costs[lastYear]==0) {
            renovation_costs[lastYear]=globalData.renovation_expenses*globalData.leaseTotalSQM;
          }

        }
      }
      if (globalData.lease_status==0) {
        row['Value']=market_price;
        row['LeaseValue']=0;
      }
      totalValue+=parseInt(row['Value']);
      totalValueByYear+=parseInt(row['Value']);
      results['calc'].push(row);
        dateObject_calc.setMonth(dateObject_calc.getMonth()+1);
    }

    operating_costs[lastYear]=totalValueByYear*(globalData.operating_expenses/100);
    capex_costs[lastYear]=totalValueByYear*(globalData.capex/100);

    var exit_revenue=totalValueByYear/(globalData.ary_exit_yield/100);
    var exit_costs=totalValueByYear*(globalData.ary_exit_costs/100);

    results['exit_costs']=exit_costs;
    results['exit_revenue']=exit_revenue;
    results['discount_factor']=discount_factor;
    results['agent_costs']=agent_costs;
    results['renovation_costs']=renovation_costs;
    results['void_costs']=void_costs;
    results['operating_costs']=operating_costs;
    results['capex_costs']=capex_costs;
    results['lease_start']=dateObject_lease_start;
    results['lease_end']=dateObject_lease_end;
    results['lease_revaluate']=dateObject_lease_revaluate;
    results['lease_readjustment']=dateObject_lease_readjustment;
    results['start_dcf']=dateObject_lease_start_dcf;
    results['end_dcf']=dateObject_lease_end_dcf;
    results['end_dcf_exit']=dateObject_lease_end_dcf_for_exit;
    results['total_months']=dateObject_lease_total_months;
    results['total_value']=totalValue-totalValueByYear;
    results['total_value_years']=totalValueByYears;
    results['total_value_for_exit']=totalValueByYear;
    var tocalCashFlowPerYear=[];
    var netPresentValue=0;
    var cc=0;
    for (var year in totalValueByYears) {
        cc++;
        var value=parseInt(totalValueByYears[year]);
        if (operating_costs[year]!==undefined)
          value-=operating_costs[year];
        if (renovation_costs[year]!==undefined)
          value-=renovation_costs[year];
        if (agent_costs[year]!==undefined)
          value-=agent_costs[year];
        if (void_costs[year]!==undefined)
            value-=void_costs[year];
        if (capex_costs[year]!==undefined)
           value-=capex_costs[year];
      if (cc==10) {
        value+=exit_revenue;
        value-=exit_costs;
      }
      tocalCashFlowPerYear[year]=value;
      netPresentValue+=value;

    }
    var NPVtocalCashFlowPerYear=[];
    var NPVnetPresentValue=0;
    var cc=0;
    for (var year in totalValueByYears) {
        cc++;
        var value=parseInt(totalValueByYears[year]);
        if (operating_costs[year]!==undefined)
          value-=operating_costs[year];
        if (renovation_costs[year]!==undefined)
          value-=renovation_costs[year];
        if (agent_costs[year]!==undefined)
          value-=agent_costs[year];
        if (void_costs[year]!==undefined)
            value-=void_costs[year];
        if (capex_costs[year]!==undefined)
           value-=capex_costs[year];
      if (cc==10) {
        value+=exit_revenue;
        value-=exit_costs;
      }
      value=value*discount_factor[year];
      NPVtocalCashFlowPerYear[year]=value;
      NPVnetPresentValue+=value;

    }

    // sums

    results['tocalCashFlowPerYear']=tocalCashFlowPerYear;
    results['netPresentValue']=netPresentValue;
    results['NPVtocalCashFlowPerYear']=NPVtocalCashFlowPerYear;
    results['NPVnetPresentValue']=NPVnetPresentValue;



    results['ARY']=globalData.ary*globalData.lease_price;
    /*var getMonths=monthDiff(globalData.lease_start,globalData.lease_end);
    results['Months']=getMonths;
*/
    //setCalculationData(JSON.stringify(results));
    console.log(results);
    setGlobalResults(results);
    //setDcf(results);
    var final;
    final=Math.ceil(NPVnetPresentValue/1000)*1000;
    setCalculationData(final.toLocaleString("el-GR"));
    var cd1=[];

    for (var year in totalValueByYears) {
      var d={};
      var v=totalValueByYears[year];
      d['name']=year;
      d['value']=parseInt(v);
      cd1.push(d);
    }

    var cd2=[];
    for (var year in NPVtocalCashFlowPerYear) {
      var d={};
      var v=NPVtocalCashFlowPerYear[year];
      d['name']=year;
      d['value']=parseInt(v);
      cd2.push(d);
    }
    cd2.pop();

    var cd3=[];
    for (var year in capex_costs) {
      var d={};
      var v=capex_costs[year];
      d['name']=year;
      d['value']=v;
      cd3.push(d);
    }

    var cd4=[];
    for (var year in operating_costs) {
      var d={};
      var v=operating_costs[year];
      d['name']=year;
      d['value']=v;
      cd4.push(d);
    }
    setChartData1(cd1);
    setChartData2(cd2);
    setChartData3(cd3);
    setChartData4(cd4);
    var cd5=[];
    for (var row in results['calc']) {
      var d={};
      var v=results['calc'][row];
      var dateObject_calc=new Date(v['Dt']);

      d['name']=(dateObject_calc.getMonth()+1)+"/"+dateObject_calc.getFullYear();
      d['Value']=parseInt(v['Value']);
      d['MarketValue']=parseInt(v['MarketValue']);
      d['LeaseValue']=parseInt(v['LeaseValue']);
      cd5.push(d);
    }
    setChartDataCalc(cd5);

    return;

  }
  async function exportExcel() {

     let bl = await fetch('/templates/mva_template_v1.xlsx').then(r => r.blob());

     var dl=await bl.arrayBuffer();
     var data = new Uint8Array(dl);

     const wb = new Excel.Workbook();
     await wb.xlsx.load(data);



     var worksheet = wb.getWorksheet('Tenancy_Details');
     worksheet.getCell('B8').value = globalData.leaser_name;

     worksheet.getCell('D8').value = getDt(globalData.lease_start);
   worksheet.getCell('H8').value = getDt(globalData.lease_revaluate);
     worksheet.getCell('E8').value =getDt(globalData.lease_end);
     worksheet.getCell('J8').value =getNextDt(globalData.lease_end);
if (isNaN(parseInt(globalData.lease_empty))) {
worksheet.getCell('G8').value =0;
} else {
  worksheet.getCell('G8').value =parseInt(globalData.lease_empty);
}


worksheet.getCell('T8').value = globalData.lease_price;
if (globalData.lease_status=="1") {
  worksheet.getCell('C8').value = "-3";
} else if (globalData.lease_status=="0") {
  worksheet.getCell('C8').value = "-2";
} else {
  worksheet.getCell('C8').value = globalData.lease_status;
}
worksheet.getCell('C8').value = globalData.lease_status;
worksheet.getCell('Q8').value = globalData.lease_type;
worksheet.getCell('R8').value = globalData.leaseSQM;
worksheet.getCell('S8').value = globalData.leaseTotalSQM;
worksheet.getCell('W6').value = globalData.leaseYearly+"%";

worksheet.getCell('AC6').value = globalData.generic_expenses;
worksheet.getCell('AE6').value = globalData.agency_expenses;
worksheet.getCell('AG6').value = globalData.renovation_expenses;

    // Images
    var counter=0;
    var counterD=0;
    var counterR=0;
    var where="";




    const buffer = await wb.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], {type: fileType});

    saveAs(blob, 'MVA_'+globalData['collateralId'] + fileExtension);


    return;

  }


  const onLoad = () => {};
  const [map, setMap] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [district,setDistrict]=useState("");
  const [address,setAddress]=useState("");
  const [prefecture,setPrefecture]=useState("");
  const [googleAddress, setGoogleAddress] = useState("");
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  var places;


  var place=null;

  var center = {
    lat: 38.0572437,
    lng: 23.8481676
  };
  function afterLoad() {
    if (globalData.DCF!==null) {
      exportCalc();
    }
  }
  function onloadMap(ref){
    setMap(ref);

  }
  function onLoadMarker(ref) {
    setMarker(ref);
    ref.setPosition(new window.google.maps.LatLng( lat, lng ) );

  }
  function onSearchBoxMounted(ref) {
      setAutocomplete(ref);
  }
  function onDragEnd(e) {
  //  //console.log("onPositionChanged");

    //map.panTo(new window.google.maps.LatLng( marker.getPosition().lat(), marker.getPosition().lng() ));
    //center.lat=marker.getPosition().lat();
  //  center.lng=marker.getPosition().lng();
      setLat(marker.getPosition().lat());
      setLng(marker.getPosition().lng());


    geocodePosition(marker.getPosition());
//    setLat(place.geometry.location.lat());
//    setLng(place.geometry.location.lng());
  }
  function geocodePosition(pos)  {



  var  geocoder = new window.google.maps.Geocoder();
   geocoder.geocode
    ({
        latLng: pos
    },
        function(results, status)
        {
            if (status ==window.google.maps.GeocoderStatus.OK)
            {


              updateAddress(results);
            }
            else
            {
            	//  setAddress(marker.getPosition().lat()+","+marker.getPosition().lng());


            }
        }
    );

	}
  function updateAddress(results) {
    setAddress(results[0].formatted_address);
      Object.keys(results[0].address_components).forEach(function(key) {
        var result=results[0].address_components[key];
        if (result.types.indexOf("locality") > -1) {
          setMunicipality(result.long_name);
        }
        if (result.types.indexOf("postal_code") > -1) {
          setPostalCode(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_32") > -1) {
          setDistrict(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_3") > -1) {
          setPrefecture(result.long_name);
        }

      });
  }
  function onPlacesChanged() {
  //  //console.log('autocomplete: ', autocomplete)
//    //console.log(searchBox.getPlaces());
      if (autocomplete !== null) {
    //   //console.log(autocomplete.getPlace())
      place=autocomplete.getPlace();


      //setGoogleAddress(place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      center.lat=place.geometry.location.lat();
      center.lng=place.geometry.location.lng();

      marker.setPosition(new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );

      map.panTo( new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );
      geocodePosition(marker.getPosition());

      } else {
       //console.log('Autocomplete is not loaded yet!')
      }
  }
 const [value, setValue] = useState('Hello World');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const changeHandler = (e) => {
    if (e.target.id=='file') {
        setIsUploaded(false);

  		setSelectedFile(e.target.files[0]);
  		setIsSelected(true);
    }
      if (e.target.id=='title') {
        setDataTitle(e.target.value);
      }
      if (e.target.id=="select_image_category") {

        setIsMap(false);
        setIsDanger(false);
        setIsFront(false);
        setIsResolve(false);
        if (e.target.value=="isDanger") setIsDanger(true);
        if (e.target.value=="isMap") setIsMap(true);
        if (e.target.value=="isFront") setIsFront(true);
        if (e.target.value=="isResolve") setIsResolve(true);

      }


  	};
  function handleChange (e) {

    if (e.target.id=='lat') {
      setLat(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    if (e.target.id=='lng') {
      setLng(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    //var x=globalData;
    //x[e.target.id]=e.target.value;
    //setGlobalData(x);


 }
 const Table_pnl = () => {
   var ret=[];
    ret.push();
    var str=[];

   for(var year in globalResults.total_value_years) {
      str.push(<td>{year}</td>);


   }
   str.push(<td>{parseInt(year)+1}</td>);
   ret.push(<tr  className={classes.cerved_blue}><td>Year</td>{str}</tr>)

   var str=[];

  for(var year in globalResults.total_value_years) {
     str.push(<td>{globalResults.total_value_years[year].toFixed(2)}</td>);
     //str.push(<td>{globalResults.total_value_years[year]}</td>);


  }
  str.push(<td>{globalResults.total_value_for_exit.toFixed(2)}</td>);
  ret.push(<tr className={classes.cerved_green}><td>Income</td>{str}</tr>)


  var str=[];

 for(var year in globalResults.capex_costs) {
    str.push(<td>{globalResults.capex_costs[year].toFixed(2)}</td>);


 }

 ret.push(<tr className={classes.cerved_mauve}><td>Capex</td>{str}</tr>)

 var str=[];

for(var year in globalResults.operating_costs) {
   str.push(<td>{globalResults.operating_costs[year].toFixed(2)}</td>);


}

ret.push(<tr className={classes.cerved_mauve_1}><td>Opex</td>{str}</tr>)


 var str=[];

for(var year in globalResults.operating_costs) {
  if (globalResults.renovation_costs[year]===undefined||parseInt(globalResults.renovation_costs[year])==0) {
  str.push(<td></td>);
  }  else {
     str.push(<td>{globalResults.renovation_costs[year].toFixed(2)}</td>);
  }

}

ret.push(<tr className={classes.cerved_mauve}><td>Renovation Costs</td>{str}</tr>)

var str=[];

for(var year in globalResults.operating_costs) {
 if (globalResults.agent_costs[year]===undefined||parseInt(globalResults.agent_costs[year])==0) {
 str.push(<td></td>);
 }  else {
    str.push(<td>{globalResults.agent_costs[year].toFixed(2)}</td>);
 }

}

ret.push(<tr className={classes.cerved_mauve_1}><td>Agent Costs</td>{str}</tr>)


var str=[];

for(var year in globalResults.operating_costs) {
 if (globalResults.void_costs[year]===undefined) {
 str.push(<td></td>);
 }  else {
    str.push(<td>{globalResults.void_costs[year].toFixed(2)}</td>);
 }

}

ret.push(<tr className={classes.cerved_mauve}><td>Void Costs</td>{str}</tr>)
var str=[];

for(var year in globalResults.discount_factor) {
  str.push(<td>{globalResults.discount_factor[year].toFixed(3)}</td>);


}
str.push(<td>-</td>);
ret.push(<tr  className={classes.cerved_blue}><td>Discount Factor</td>{str}</tr>)


var str=[];

for(var year in globalResults.NPVtocalCashFlowPerYear) {
  str.push(<td>{globalResults.NPVtocalCashFlowPerYear[year].toFixed(2)}</td>);


}
str.push(<td>{globalResults.NPVnetPresentValue.toFixed(2)}</td>);
ret.push(<tr  className={classes.cerved_green}><td>NPV</td>{str}</tr>)


   //console.log(ret);
   return(ret);
 };
 const Table_void_costs = () => {
   var ret=[];
    ret.push()
   for(var year in globalResults.void_costs) {
      ret.push(<tr><td>{year}</td><td>{Math.ceil(globalResults.void_costs[year]/1000)*1000}</td></tr>)

   }
   //console.log(ret);
   return(ret);
 };
 const Table_renovation_costs = () => {
   var ret=[];
    ret.push()
   for(var year in globalResults.renovation_costs) {
      ret.push(<tr><td>{year}</td><td>{Math.ceil(globalResults.renovation_costs[year]/1000)*1000}</td></tr>)

   }
   //console.log(ret);
   return(ret);
 };
 const Table_agent_costs= () => {
   var ret=[];
    ret.push()
   for(var year in globalResults.agent_costs) {
      ret.push(<tr><td>{year}</td><td>{Math.ceil(globalResults.agent_costs[year]/1000)*1000}</td></tr>)

   }
   //console.log(ret);
   return(ret);
 };
 const Table_capex_costs = () => {
   var ret=[];
    ret.push()
   for(var year in globalResults.capex_costs) {
      ret.push(<tr><td>{year}</td><td>{Math.ceil(globalResults.capex_costs[year]/1000)*1000}</td></tr>)

   }
   //console.log(ret);
   return(ret);
 };

 const Table_operating_costs = () => {
   var ret=[];
    ret.push()
   for(var year in globalResults.operating_costs  ) {
      ret.push(<tr><td>{year}</td><td>{Math.ceil(globalResults.operating_costs[year]/1000)*1000}</td></tr>)

   }
   //console.log(ret);
   return(ret);
 };
const Table_total_value_years = () => {
  var ret=[];
   ret.push()
  for(var year in globalResults.total_value_years) {
     ret.push(<tr><td>{year}</td><td>{Math.ceil(globalResults.total_value_years[year]/1000)*1000}</td></tr>)

  }
  //console.log(ret);
  return(ret);
};
 const Table_NPVtocalCashFlowPerYear = () => {
   var ret=[];
    ret.push()
   for(var year in globalResults.NPVtocalCashFlowPerYear) {
      ret.push(<tr><td>{year}</td><td>{Math.ceil(globalResults.NPVtocalCashFlowPerYear[year]/1000)*1000}</td></tr>)

   }
   //console.log(ret);
   return(ret);
 };
  return (

    <>
    {globalLoad!=true?
      <>

        <div className="row ">
          <div className="col-md-6">
            <div className={`card card-custom p-5 `}>
              <div className="col-md-12 card-header">
                <h4> CollateralId: {globalData.collateralId}</h4>

              </div>
              <div className="col-md-12  card-body">
              {calculationData!==null?(
                <>
                <span>


                <TextField
                      id="outlined-adornment-amount"
                      className={classes.dcf}
                      variant="outlined"
                      label="DCF Final Result"
                      value={calculationData}

                       InputLabelProps={{ style: { fontSize: 20 } }}
                      InputProps={{style: { fontSize: 30 },
                        startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                      }}
                    />
                </span>
                </>
              ):(<></>)}
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className={`card card-custom p-5 `}>
              <div className="col-md-12 card-header">
                <h4> Address: {globalData.address}</h4>

              </div>
              <div className="col-md-12  card-body">
              {globalResults!==null?(
                <>
                <span>


                <TextField
                      id="outlined-adornment-amount"
                      className={classes.dcf}
                      variant="outlined"
                      label="DCF Exit Revenue"
                      value={(Math.ceil(globalResults.exit_revenue/1000)*1000).toLocaleString("el-GR")}

                       InputLabelProps={{ style: { fontSize: 20 } }}
                      InputProps={{style: { fontSize: 30 },
                        startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                      }}
                    />
                </span>
                </>
              ):(<></>)}
              </div>
            </div>

          </div>
        </div>
        {globalResults!==null?
          <>

          <div className="row gutter-t">
            <div className="col-md-12">
              <div className={`card card-custom p-5 `}>
                <div className="col-md-12 card-header">
               <h4> Rental VS Market Value</h4>

                </div>
                <div className="col-md-12  card-body">
          <ResponsiveContainer width="100%" height={300}>
                 <LineChart
                   width={500}
                   height={300}
                   data={chartDataCalc}
                   syncId="chartDataCalc"
                   margin={{
                     top: 10,
                     right: 30,
                     left: 0,
                     bottom: 0,
                   }}
                 >
                   <CartesianGrid strokeDasharray="3 3" />
                   <XAxis dataKey="name" />
                    <YAxis type="number" domain={[dataMin => (Math.floor(dataMin/500)*500), dataMax =>  (Math.ceil(dataMax/500)*500)]} />
                   <Tooltip />

                   <Line type="linear" dataKey="LeaseValue" stroke="#93cddd" fill="#93cddd" />
                   <Line type="linear" dataKey="MarketValue" stroke="#f59793" fill="#f59793" />
<Line type="linear" dataKey="Value" stroke="#31859c" fill="#31859c" />
                 </LineChart>
               </ResponsiveContainer>
               </div>
             </div>

           </div>

           </div>
          <div className="row mt-5">
          <div className="col-md-12">
            <div className={`card card-custom p-5 `}>
              <div className="col-md-12 card-header">
                    <h4> PNL</h4>
              </div>
              <div className="col-md-12  card-body">
                  <Table responsive className="mt-10">
                    <thead>
                      <tr>

                        <th>Description</th>
                        <th>Year 1</th>
                        <th>Year 2</th>
                        <th>Year 3</th>
                        <th>Year 4</th>
                        <th>Year 5</th>
                        <th>Year 6</th>
                        <th>Year 7</th>
                        <th>Year 8</th>
                        <th>Year 9</th>
                        <th>Year 10</th>
                        <th>Year 11</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Table_pnl/>
                    </tbody>
                  </Table>
                  </div>
                </div>

           </div>


          </div>
          <div className="row mt-5">
          <div className="col-md-4">
            <div className={`card card-custom p-5 `}>
              <div className="col-md-12 card-header">
                    <h4> Agent Costs</h4>
              </div>
              <div className="col-md-12  card-body">
                  <Table responsive className="mt-10">
                    <thead>
                      <tr>

                        <th>Year</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Table_agent_costs/>
                    </tbody>
                  </Table>
                  </div>
                </div>

           </div>
           <div className="col-md-4">
             <div className={`card card-custom p-5 `}>
               <div className="col-md-12 card-header">
                     <h4>Renovation Costs</h4>
               </div>
               <div className="col-md-12  card-body">
                   <Table responsive className="mt-10">
                     <thead>
                       <tr>

                         <th>Year</th>
                         <th>Value</th>
                       </tr>
                     </thead>
                     <tbody>
                       <Table_renovation_costs/>
                     </tbody>
                   </Table>
                   </div>
                 </div>

            </div>
            <div className="col-md-4">
              <div className={`card card-custom p-5  `}>
                <div className="col-md-12 card-header">
                      <h4> Void costs</h4>
                </div>
                <div className="col-md-12  card-body">
                    <Table responsive className="mt-10">
                      <thead>
                        <tr>

                          <th>Year</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <Table_void_costs/>
                      </tbody>
                    </Table>
                    </div>
                  </div>

             </div>
          </div>
      </>
      :<></>}
        {globalResults!==null?
          <div className="row mt-5  gutter-b">
          <div className="col-md-6">
            <div className={`card card-custom p-5 `}>
              <div className="col-md-12 card-header">
                    <h4> Total Value Per Year</h4>
              </div>
              <div className="col-md-12  card-body">
                  <Table responsive className="mt-10">
                    <thead>
                      <tr>

                        <th>Year</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <Table_total_value_years/>
                    </tbody>
                  </Table>
                  </div>
                </div>

           </div>
            <div className="col-md-6">
              <div className={`card card-custom p-5 `}>
                <div className="col-md-12 card-header">
                      <h4> NPV total Cash Flow Per Year</h4>
                </div>
                <div className="col-md-12  card-body">
                    <Table responsive className="mt-10">
                      <thead>
                        <tr>

                          <th>Year</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <Table_NPVtocalCashFlowPerYear/>
                      </tbody>
                    </Table>
                    </div>
                  </div>

             </div>
          </div>
      :<></>}
  {chartData1!==null?(
    <>
    <br/>
    <div className="row">
      <div className="col-md-6 ">
        <div className={`card card-custom p-5`}>
          <div className="col-md-12 card-header">
          <h4>   Total Value By Year</h4>

          </div>
          <div className="col-md-12  card-body">
    <ResponsiveContainer width="100%" height={300}>
           <LineChart
             width={500}
             height={300}
             data={chartData1}
             syncId="chartData1"
             margin={{
               top: 10,
               right: 30,
               left: 0,
               bottom: 0,
             }}
           >
             <CartesianGrid strokeDasharray="3 3" />
             <XAxis dataKey="name" />
             <YAxis />
             <Tooltip />
             <Line type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
           </LineChart>
         </ResponsiveContainer>
         </div>
       </div>

     </div>
     <div className="col-md-6">
       <div className={`card card-custom p-5 `}>
         <div className="col-md-12 card-header">
           <h4> NPV total Cash Flow Per Year  </h4>

         </div>
         <div className="col-md-12  card-body">
   <ResponsiveContainer width="100%" height={300}>
          <LineChart

            width={500}
            height={300}
            data={chartData2}
            syncId="chartData2"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
        </div>
      </div>

    </div>
     </div>
     {globalResults!==null?
       <div className="row mt-5">
       <div className="col-md-6">
         <div className={`card card-custom p-5 `}>
           <div className="col-md-12 card-header">
                 <h4> Capex per year</h4>
           </div>
           <div className="col-md-12  card-body">
               <Table responsive className="mt-10">
                 <thead>
                   <tr>

                     <th>Year</th>
                     <th>Value</th>
                   </tr>
                 </thead>
                 <tbody>
                   <Table_capex_costs/>
                 </tbody>
               </Table>
               </div>
             </div>

        </div>
         <div className="col-md-6">
           <div className={`card card-custom p-5 `}>
             <div className="col-md-12 card-header">
                   <h4> Opex per year</h4>
             </div>
             <div className="col-md-12  card-body">
                 <Table responsive className="mt-10">
                   <thead>
                     <tr>

                       <th>Year</th>
                       <th>Value</th>
                     </tr>
                   </thead>
                   <tbody>
                     <Table_operating_costs/>
                   </tbody>
                 </Table>
                 </div>
               </div>

          </div>
       </div>
   :<></>}
     <div className="row gutter-t">
       <div className="col-md-6">
         <div className={`card card-custom p-5 `}>
           <div className="col-md-12 card-header">
          <h4> Capex</h4>

           </div>
           <div className="col-md-12  card-body">
     <ResponsiveContainer width="100%" height={300}>
            <LineChart
              width={500}
              height={300}
              data={chartData3}
              syncId="chartData3"
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
          </div>
        </div>

      </div>
      <div className="col-md-6">
        <div className={`card card-custom p-5 `}>
          <div className="col-md-12 card-header">
        <h4>   Opex Costs</h4>

          </div>
          <div className="col-md-12  card-body">
    <ResponsiveContainer width="100%" height={300}>
           <LineChart
             width={500}
             height={300}
             data={chartData4}
             syncId="chartData4"
             margin={{
               top: 10,
               right: 30,
               left: 0,
               bottom: 0,
             }}
           >
             <CartesianGrid strokeDasharray="3 3" />
             <XAxis dataKey="name" />
             <YAxis />
             <Tooltip />
             <Line type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
           </LineChart>
         </ResponsiveContainer>
         </div>
       </div>

     </div>
      </div>


    </>
  ):(<></>)}


   </>
  :<Loader
type="Bars"
color="#00BFFF"
height={20}
width={40}
/>}

    </>

  );


}
export default Hometrix_MVA_AssetDCF;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
