import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { useAuth0 } from '@auth0/auth0-react';
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,loginWithPopup,
     logout,getAccessTokenSilently,
   } = useAuth0();

   if (isLoading) {
     return <div>Loading...</div>;
   }
   if (error) {
     return <div>Oops... {error.message}</div>;
   }



   if (isAuthenticated) {
      /* (async () => {
        try {
          const token = await getAccessTokenSilently({
               audience: process.env.REACT_APP_AUTH0_AUDIENCE
             });
          console.log(token);
          props.login(token);
        } catch (e) {
          console.error(e);
        }*/


      if(user.email_verified==false) {
           return (
             <div>
               <br/>
               <p className="text-muted font-weight-bold">
                 <FormattedMessage id="AUTH.LOGIN.VERIFY" />
               </p>
               <button className="btn btn-primary font-weight-bold px-9 py-4 my-3" onClick={() => {window.location.reload(false);} }>
               <FormattedMessage id="AUTH.LOGIN.VERIFY_BUTTON" />
               </button>
             </div>
           );
    } else {
      (async () => {
        try {
          const token = await getAccessTokenSilently({
               audience: process.env.REACT_APP_AUTH0_AUDIENCE
             });
          console.log(token);
          props.login(token);
        } catch (e) {
          console.error(e);
        }
        })();
    }


   } else {
     return (
       <div className="login-form login-signin" id="kt_login_signin_form">
         {/* begin::Head */}
         <div className="text-center mb-10 mb-lg-20">
           <h3 className="font-size-h1">
             <FormattedMessage id="AUTH.LOGIN.TITLE" />
           </h3>
           <p className="text-muted font-weight-bold">
             <FormattedMessage id="AUTH.LOGIN.MESSAGE" />
           </p>
         </div>
         {/* end::Head */}
         <div className="form-group d-flex flex-wrap justify-content-center align-items-center">

             <button
               id="kt_login_signin_submit"
               type="button"

               onClick={loginWithRedirect}
               className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
             > Sign in / Sign up
           </button>  {isLoading && <span className="ml-3 spinner spinner-white"></span>}
        </div>
    </div>
     );
   }
}

export default injectIntl(connect(null, auth.actions)(Login));
