import React from "react";
import { AreaChart, Area } from "recharts";

export const CustomAreaChart = ({ data, width, height }) => {
  return (
    <div>
      <AreaChart width={width} height={height} data={data.mockData}>
        <Area
          type="monotone"
          dataKey="price"
          stroke="#8884d8"
          fill="#4ba880"
          fillOpacity={0.3}
        />
      </AreaChart>
    </div>
  );
};
