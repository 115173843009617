import React,{useState} from "react";
import { useHistory } from "react-router-dom";

import {useSubheader} from "../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import {Chart1} from "./Chart1"
import {Chart2} from "./Chart2"
import {Chart3} from "./Chart3"
import {Chart4} from "./Chart4"
import {Chart5} from "./Chart5"
import {Chart6} from "./Chart6"
import {Chart7} from "./Chart7"
import {Chart8} from "./Chart8"
export function MyPage()  {
    const history = useHistory();
  //const [title, setTitle] = useSubheader();
  //setTitle("My Custom title");
  const sub=useSubheader();
  sub.setTitle("No access");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [workbook, setWorkbook] = useState(null);

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,getIdTokenClaims,
   } = useAuth0();
  async function testScopes() {

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });



    return;
  }
   function handleFile(e) {
    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function(e) {
      console.log("mpika");
      var data = new Uint8Array(e.target.result);
      console.log(data);
      const xl = new Excel.Workbook();
      xl.xlsx.load(data);
      setWorkbook(xl);
      console.log(xl);
      //setWorkbook(XLSX.read(data, {type: 'array',cellStyles:true}));
      console.log("readed");

      /* DO SOMETHING WITH workbook HERE */
    };
    reader.readAsArrayBuffer(f);

}

  async function testExcel() {
     let bl = await fetch('/templates/hometrix_inspection_form.xlsx').then(r => r.blob());

     var dl=await bl.arrayBuffer();
     var data = new Uint8Array(dl);

     const wb = new Excel.Workbook();
     await wb.xlsx.load(data);



     var worksheet = wb.getWorksheet('Description');
    worksheet.getCell('F4').value = "24/01/2021";
    const buffer = await wb.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], {type: fileType});

    saveAs(blob, 'InspectionForm' + fileExtension);
    return;

  }
  async function test() {
     setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       const apiURL = process.env.REACT_APP_SERVER_URL;

      const metadataResponse = await fetch(apiURL, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
credentials: 'include',
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
      console.log(resp);
      setData(resp);
     setLoading(false);
  }


  const chart_data_2 = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  return (
    <>
    <div className="row">
      <div className="col-xl-6">
        <div className="row card gutter-b">
        <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
        </div>
          <div className="col-xl-12  card-body">
        <Chart1></Chart1>
          </div>
       </div>
      </div>
      <div className="col-xl-6">
        <div className="row card gutter-b">
              <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
              </div>
              <div className="col-xl-12  card-body">
              <Chart2></Chart2>

          </div>
       </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-6">
        <div className="row card gutter-b">
        <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
        </div>
          <div className="col-xl-12  card-body">
        <Chart3></Chart3>
          </div>
       </div>
      </div>
      <div className="col-xl-6">
        <div className="row card gutter-b">
              <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
              </div>
              <div className="col-xl-12  card-body">
              <Chart4></Chart4>

          </div>
       </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-6">
        <div className="row card gutter-b">
        <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
        </div>
          <div className="col-xl-12  card-body">
        <Chart5></Chart5>
          </div>
       </div>
      </div>
      <div className="col-xl-6">
        <div className="row card gutter-b">
              <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
              </div>
              <div className="col-xl-12  card-body">
              <Chart6></Chart6>

          </div>
       </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-6">
        <div className="row card gutter-b">
        <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
        </div>
          <div className="col-xl-12  card-body">
        <Chart7></Chart7>
          </div>
       </div>
      </div>
      <div className="col-xl-6">
        <div className="row card gutter-b">
              <div className="col-xl-12 card-header">
              <h3>Generic Chart</h3>
              </div>
              <div className="col-xl-12  card-body">
              <Chart8></Chart8>

          </div>
       </div>
      </div>
    </div>
    <br/>
<br/><br/>

    </>

  );


}
export default MyPage;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
