import { culcPercentage } from "../utils";
// takes two diminsions (x, y labels) and the data and fills in accordingly
// enchance mode adds 10 to each cell to make it appear more intensively in the map (defauls to false)
export const makeMatrix = (x, y, data, enhance = false) => {
  let matrix = initMatrix(y, x);

  data.forEach((d) => {
    matrix[d[0]][d[1]] = d[2] + (enhance ? +10 : 0);
  });

  return convertObj2Matrix(matrix);
};

// initialises matrix to xLabels and yLabels lengths with zeros
const initMatrix = (x, y) => {
  const matrix = {};
  x.forEach((x) => {
    matrix[x] = {};
    y.forEach((y) => {
      matrix[x][y] = 0;
    });
  });
  return matrix;
};

// converts the object matrix to an array matrix
const convertObj2Matrix = (obj) => {
  let matrix = [];
  Object.values(obj).forEach((v) => {
    let newArr = [];
    Object.values(v).forEach((k) => {
      newArr.push(k);
    });
    matrix.push(newArr);
  });
  return matrix;
};

// Display only even labels
export const xLabelsVisibility = new Array(24)
  .fill(0)
  .map((_, i) => (i % 2 === 0 ? true : false));

export const matrixSize = (xLength) => {
  const w = window.innerWidth;
  const x = xLength;

  if (w > 1200) return Math.round(culcPercentage(w / x, 38));
  if (w > 800) return Math.round(culcPercentage(w / x, 48));
  if (w > 500) return Math.round(culcPercentage(w / x, 58));
  return Math.round(culcPercentage(w / x, 60));
};
