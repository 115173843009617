import React, { useContext } from "react";
import { DataContext } from "../context/DataContext";
import { LanguageContext } from "../context/LanguageContext";
import houses from "../assets/icons/houses.png";
import { getColor, culcIconWidth, culcMarginTop } from "../utils";
import { InsufficientData } from "../Shared/InsufficientData";

export const Houses = () => {
  const { json } = useContext(DataContext);
  const { getContent, getLabels } = useContext(LanguageContext);

  const CONTENT = getContent();

  return (
    <div>
      <div className="col metric">
        <img src={houses} alt="houses icon" width={culcIconWidth()}></img>
      </div>
      <div>
        {json.stock >= 30 ? (
          <div className="col">
            <ul className="metric-list" style={{ marginTop: culcMarginTop() }}>
              <li>
                <h3 style={{ color: getColor(3) }} className="metric-title">
                  {getLabels().houses}
                </h3>
              </li>
              <li>
                <h5>
                  {CONTENT.metrics.med_yoc}{" "}
                  <span style={{ color: getColor(3) }}>
                    {json.houses_median_yoc}
                  </span>
                </h5>
              </li>

              <li>
                <h5>
                  {CONTENT.metrics.med_price}{" "}
                  <span style={{ color: getColor(3) }}>
                    {json.houses_median_pr}€
                  </span>
                </h5>
              </li>

              <li>
                <h5>
                  {CONTENT.metrics.after_2010}{" "}
                  <span style={{ color: getColor(3) }}>
                    {json.houses_newly_built}%
                  </span>
                </h5>
              </li>
              <li>
                <h5>
                  {CONTENT.metrics.med_surf}{" "}
                  <span style={{ color: getColor(3) }}>
                    {json.houses_median_area} m²
                  </span>
                </h5>
              </li>
              <li style={{ marginBottom: "10%" }}> </li>
            </ul>
          </div>
        ) : (
          <InsufficientData
            text={CONTENT.metrics.insufficient_data}
            text2={CONTENT.metrics.few_houses}
          />
        )}
      </div>
    </div>
  );
};
