export const ILanguage = {
  english: "EN",
  greek: "GR",
};

export const officeTitles = {
  [ILanguage.english]: {
    main: "CPS Market Insights for Offices",
    mun_of: "Municipality of",
    overview: "Overview",
    abbreviations: "Abbreviations",
    heatmap_1: "OfS based on Surface & YoC",
    surface_bar_chart: "Properties Surfaces Breakdown",
    heatmap_2: "OfS per Neighbourhood",
    year_bar_chart: "YoC of OfS",
    census: "Census Data",
    mun_of_2: "for municipality of",
    radar_chart_1: "Frequency Distribution of ΟfS - Properties Surface",
    radar_chart_2: "Frequency Distribution of ΟfS - YoC",
    comp: "Comparison Analytics",
    within: "within",
    offices_fs_mun: "OfS at Municipality level",
    median_price_mun: "Median Psqm for OfS at Municipality level",
    combined_chart: "Distribution of Psqm at Municipality level",
    median_yoc_mun: "Median YoC at Municipality level",
    comp_analysis: "Comparison Analytics",
    per_neighb: "per Neighbourhood",
    median_price_neighb: "Median Price (€)",
    stock_percentage: "Stock Percentage (%)",
    stock_mun: "OfS at Neighbourhood level",
    median_yoc_neighb: "Median YoC at Neighbourhood level",
  },

  [ILanguage.greek]: {
    main: "Στοιχεία αγοράς CPS για Γραφεία",
    mun_of: "Περιοχή:",
    overview: "Σύνοψη",
    abbreviations: "Συντoμογραφίες",
    heatmap_1: "Γραφεία προς Πώληση ανά Επιφάνεια και Έτος Κατασκευής",
    surface_bar_chart: "Κατανομή Ακινήτων ανά Επιφάνεια",
    heatmap_2: "Γραφεία προς Πώληση ανα Γειτονιά",
    year_bar_chart: "Έτος Κατασκευής Γραφείων προς Πώληση",
    census: "Στοιχεία Απογραφής",
    mun_of_2: "",
    radar_chart_1:
      "Κατανομή Συχνότητας Γραφείων προς Πώληση με βάση την Επιφάνεια Ακινήτου",
    radar_chart_2:
      "Κατανομή Συχνότητας Γραφείων προς Πώληση με βάση το Έτος Κατασκευής",
    comp: "Συγκριτική Ανάλυση",
    within: "της περιοχής:",
    offices_fs_mun: "Γραφεία προς Πώληση σε επίπεδο δήμου",
    median_price_mun: "Διάμεση Τιμή/τ.μ. Γραφείων προς Πώληση σε επίπεδο δήμου",
    combined_chart: "Κατανομή Τιμής/τ.μ. σε επίπεδο δήμου",
    median_yoc_mun: "Διάμεσο Έτος Κατασκευής σε επίπεδο δήμου",
    comp_analysis: "Συγκριτική Ανάλυση",
    per_neighb: "σε επίπεδο γειτονιάς",
    median_price_neighb: "Διάμεση Τιμή/τ.μ. (€)",
    stock_percentage: "Ποσοστό Αποθέματος (%)",
    stock_mun: "Γραφεία προς Πώληση σε επίπεδο γειτονιάς",
    median_yoc_neighb: "Διάμεσο Έτος Κατασκευής σε επίπεδο γειτονιάς",
  },
};

export const officeLabels = {
  [ILanguage.english]: {
    for_sale: "OfS",
    median_price: "Median Psqm (€)",
    median_yoc: "Median YoC",
    elstat: "HSA 2011",
    market: "Market Insight",
    yoc: "YoC",
    asterisc: "* Light color for low number of OfS",
  },
  [ILanguage.greek]: {
    for_sale: "Γραφεία προς Πώληση",
    median_price: "Διάμεση Τιμή/τ.μ. (€)",
    median_yoc: "Διάμεσο Έτος Κατασκευής",
    elstat: "ΕΛΣΤΑΤ (2011)",
    market: "Στοιχεία αγοράς (2021)",
    yoc: "Έτος Κατασκευής",
    asterisc: "* Ανοικτό χρώμα για μικρό αριθμό γραφείων προς πώληση",
  },
};

export const officeContent = {
  [ILanguage.english]: {
    metrics: {
      med_price: "Median Psqm:",
      med_yoc: "Median YoC",
      after_2010: "Built after 2010:",
      med_surf: "Median Surface:",
      freq_surf: "Freq. Met Surface:",
      floor: "Typical Floor:",
      mun_pop: "Municipality Population:",
      pop_dens: "Population Density:",
      build_dens: "Building Density:",
      typ_surf: "Typical Surface:",
      typ_yoc: "Typical YoC:",
      dens_unit: "bldgs./km2",
      pop_dens_unit: "ppl./km2",
      insufficient_data: "Not enough Data",
      few_offices: "Very few Offices for Sale",

      abbreviations: {
        Psqm: ["Price per Square Meter:", "Psqm"],
        RfS: ["Offices for Sale:", "OfS"],
        YoC: ["Year of Construction:", "YoC"],
        HSA: ["Hellenic Statistical Authority:", "HSA"],
      },
    },
  },

  [ILanguage.greek]: {
    metrics: {
      med_price: "Διάμεση τιμή/τ.μ.:",
      med_yoc: "Διάμεσο έτος κατ.:",
      after_2010: "Κατ. μετά το 2010:",
      med_surf: "Διάμεση επιφάνεια:",
      freq_surf: "Τυπική Επιφάνεια:",
      floor: "Τυπικός Όροφος:",
      mun_pop: "Μόνιμος Πληθυσμός:",
      pop_dens: "Πληθ. πυκνότ.:",
      build_dens: "Κτιριακή πυκνότ.:",
      typ_surf: "Τυπική Επιφάνεια:",
      typ_yoc: "Τυπικό Έτος Κατ.:",
      dens_unit: "κτίρια/τετ.χμ",
      pop_dens_unit: "άτομα/τετ.χμ",
      insufficient_data: "Δεν υπάρχουν αρκετά δεδομένα",
      few_offices: "Λίγα γραφεία προς πώληση",

      abbreviations: {
        Psqm: ["Τιμή ανά τετραγωνικό μέτρο:", "Τιμή/τ.μ."],
        RfS: ["Έτος Κατασκευής:", "Έτος Κατ."],
        HSA: ["Ελληνική Στατιστική Υπηρεσία:", "ΕΛΣΤΑΤ"],
      },
    },
  },
};
