export const COLORS = ["#fac090", "#b0dee2", "#57b5bd", "#347e84"];
export const darkGrey = "#737373";
export const lightGrey = "#cccccc";
export const lightGreen = "#7fc6cc";

export const RGB_COLORS = [
  "rgb(255, 179, 128)",
  "rgb(120, 198, 205)",
  "rgb(87, 181, 189)",
  "rgb(52, 126, 132)",
];

export const getColor = (index) => {
  if (index < 0) return lightGrey;
  else return COLORS[index % COLORS.length];
};

export const getRGBColor = (index) => {
  if (index > RGB_COLORS.length || index < 0) return "#ffb385";
  else return RGB_COLORS[index];
};

export const greyOut = (entry, json) => {
  if (entry.name === json.munic || entry.name === json.munic_greek) {
    return getColor(3);
  } else {
    return darkGrey;
  }
};

export const greyOutV2 = (entry, json) => {
  if (
    entry.count < 30 &&
    (entry.name === json.munic || entry.name === json.munic_greek)
  ) {
    return lightGreen;
  } else if (entry.name === json.munic || entry.name === json.munic_greek) {
    return getColor(3);
  } else if (entry.count < 30 || entry.sales < 30 || entry.year < 30) {
    return lightGrey;
  } else return darkGrey;
};

export const multiColor = (i) => {
  const pallete = [
    "#93d0bf",
    "#71bfc4",
    "#52aec9",
    "#4095b5",
    "#3a748a",
    "#36535e",
  ].reverse();
  return pallete[i % pallete.length];
};

export const pickPallete = (data) => {
  return data.length > 3
    ? ["#ffb380", "#78c6cd", "#57b5bd", "#347E84"]
    : ["#347E84", "#57b5bd", "#ffb380"];
};

export const heatMapColor = (value, min, max) => {
  return `rgb(6, 133, 40, ${1 - (max - value) / (max - min)})`;
};
