import React from "react";
import { CustomAreaChart } from "../Shared/CustomAreaChart";
import { CustomXAxis } from "../Shared/CustomXAxis";
import { BorderLine } from "./BorderLine";
import { culcPercentage, formAreaChart } from "../utils";

export const MultipleAreaChart = ({ width, height, data }) => {
  let isShort = height < 300 || data.length < 3;
  height = isShort ? 260 : height;

  const getCurveHeight = () => {
    if (data.length < 4) return height / data.length - 30;
    return isShort ? 36 : 29.7;
  };

  const culcMarginTop = () => {
    // if (data.length < 4) return -Math.abs(culcPercentage(height, 49));
    return -Math.abs(height);
  };

  return (
    // some math that weirdly work for making the graph partially scalable
    <div style={{ marginTop: culcMarginTop(), marginRight: width / 3 }}>
      {formAreaChart(data).map((p, i) => (
        <div key={i}>
          <CustomAreaChart
            data={p}
            width={p.mockData.length * culcPercentage(width, 4)}
            height={getCurveHeight()}
          />
          {/* hiding the last borderline */}
          {i <= data.length - 2 && <BorderLine />}
        </div>
      ))}
      <CustomXAxis width={width} height={isShort ? 400 : height} data={data} />
    </div>
  );
};
