import React from "react";
import { LanguageContext } from "../context/LanguageContext";
import { DataContext } from "../context/DataContext";

export const AsteriscSubnote = ({ target, data }) => {
  const { getLabels } = React.useContext(LanguageContext);
  const { json } = React.useContext(DataContext);

  const findCountColumn = () => {
    return JSON.parse(json[target]).columns.indexOf("Count");
  };

  let shouldRender = false;

  if (data === undefined) {
    const countColumn = findCountColumn();
    shouldRender = !!JSON.parse(json[target]).data.filter(
      (d) => d[countColumn] < 30
    ).length;
  } else {
    shouldRender = !!data.filter((d) => d.count < 30).length;
  }

  return (
    <div>
      {shouldRender && <h1 className="asterisc">{getLabels().asterisc}</h1>}
    </div>
  );
};
