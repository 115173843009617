/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getWidth } from "../utils";
import { MunicipalityBarChart1 } from "../MunicipalityBarChart1";
import { DataContext } from "../context/DataContext";
import { NoData } from "../Shared/NoData";

export function NeighbBarChart1({ name }) {
  const { json } = React.useContext(DataContext);

  return (
    <>
      {/* begin::Base Table Widget 2 */}
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {name}
            </span>
          </h3>
          <div className="card-toolbar"></div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive no-left-margin">
            {!!json.neighb_combinatorial ? (
              <MunicipalityBarChart1
                width={getWidth(370, 430)}
                height={340}
                showLegend={false}
              />
            ) : (
              <NoData />
            )}
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 2 */}
    </>
  );
}
