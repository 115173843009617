/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MunicipalityBarChart3 } from "../MunicipalityBarChart3";
import { DataContext } from "../context/DataContext";
import { getWidth } from "../utils/getWidth";
import { NoData } from "../Shared/NoData";

export function NeighbBarChart3({ name }) {
  const { json } = React.useContext(DataContext);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom  gutter-b card-stretch`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {name}
            </span>
          </h3>
          <div className="table-responsive no-left-margin">
            {!!json.neighb_stock_restype ? (
              <MunicipalityBarChart3
                width={getWidth(450, 1000)}
                height={380}
                showLegend={true}
              />
            ) : (
              <NoData />
            )}
          </div>
          {/* end::Stats */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}
