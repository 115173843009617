import React from "react";
import HeatMap from "react-heatmap-grid";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { CustomLabel } from "./Shared/CustomLabel";
import { makeMatrix, sortYearStrings, heatMapColor } from "./utils";

export const HeatMap1 = () => {
  const { safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);
  const parsed = safelyGetData("overview_heatmap_area_yoc");
  const xLabels = sortYearStrings([...new Set(parsed.map((d) => d[1]))]);
  const yLabels = [...new Set(parsed.map((d) => d[0]))];

  const culcSize = () => {
    return window.innerWidth > 1300 ? 45 : 40;
  };

  const culcLeftMargin = () => {
    const w = window.innerWidth;
    if (w < 500) return "-3%";
    if (xLabels.length < 5) return "20%";
    return "10%";
  };

  return (
    <div className="table-responsive">
      <div style={{ pointerEvents: "none" }}>
        <div
          style={{
            marginLeft: culcLeftMargin(),
          }}
        >
          <CustomLabel value="m²" position="left" />

          <HeatMap
            xLabels={xLabels}
            yLabels={yLabels}
            xLabelsLocation={"bottom"}
            // xLabelsVisibility={xLabelsVisibility} // shows only the half of the labels
            xLabelWidth={170}
            yLabelWidth={80}
            data={makeMatrix(xLabels, yLabels, parsed)}
            squares
            height={culcSize()} // overall size of the grid is set from here
            cellStyle={(background, value, min, max, data, x, y) => ({
              background: heatMapColor(value, min, max),
            })}
          />
        </div>
      </div>
      <CustomLabel value={getLabels().yoc} />
    </div>
  );
};
