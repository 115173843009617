import { mergeObjects } from "../../utils";

const retail_GR_1_ls = require("./Retail/GR/listings_kpis_el_1.json");
const retail_GR_1_els = require("./Retail/GR/elstat_kpis_el_1.json");
const retail_GR_2_ls = require("./Retail/GR/listings_kpis_el_2.json");
const retail_GR_2_els = require("./Retail/GR/elstat_kpis_el_2.json");
const retail_ENG_1_ls = require("./Retail/ENG/listings_kpis_1.json");
const retail_ENG_1_els = require("./Retail/ENG/elstat_kpis_1.json");
const retail_ENG_2_ls = require("./Retail/ENG/listings_kpis_2.json");
const retail_ENG_2_els = require("./Retail/ENG/elstat_kpis_2.json");

export const retail_JSONS = {
  json_1_gr: mergeObjects(retail_GR_1_ls, retail_GR_1_els),
  json_2_gr: mergeObjects(retail_GR_2_ls, retail_GR_2_els),
  json_1_eng: mergeObjects(retail_ENG_1_ls, retail_ENG_1_els),
  json_2_eng: mergeObjects(retail_ENG_2_ls, retail_ENG_2_els),
};
