import React, { createContext, useContext, useEffect, useState } from "react";
import { DataContext } from "./DataContext";
import {
  officeTitles,
  officeContent,
  officeLabels,
  ILanguage,
} from "../assets/data/LANGUAGES";

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const { json } = useContext(DataContext);
  const [language, setLanguage] = useState(ILanguage.greek);

  useEffect(() => {
    if (
      JSON.parse(json.overview_build_status).data[0].includes(
        "Χρίζει Ανακαίνισης"
      )
    ) {
      setLanguage(ILanguage.greek);
    } else {
      setLanguage(ILanguage.greek);
    }
  }, [json]);

  const getTitles = () => {
    return officeTitles[language];
  };

  const getContent = () => {
    return officeContent[language];
  };

  const getLabels = () => {
    return officeLabels[language];
  };

  const getMunicipality = () => {
    return language === ILanguage.english ? json.munic_greek : json.munic_greek;
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        getTitles,
        getLabels,
        getContent,
        getMunicipality,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
