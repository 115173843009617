import React, { useContext } from "react";
import { DataContext } from "../context/DataContext";
import global from "../assets/icons/global.png";
import { getColor, culcIconWidth, culcMarginTop } from "../utils";
import { LanguageContext } from "../context/LanguageContext";
import { InsufficientData } from "../Shared/InsufficientData";

export const MedianPrice = () => {
  const { json } = useContext(DataContext);
  const { getContent } = useContext(LanguageContext);

  const CONTENT = getContent();
  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body p-5">
        <div>
          <div className="col metric">
            <img src={global} alt="global icon" width={culcIconWidth()}></img>
          </div>

          {json.stock >= 30 ? (
            <div className="col">
              <ul
                className="metric-list"
                style={{ marginTop: culcMarginTop(), marginBottom: "40px" }}
              >
                <li>
                  <h4>
                    {CONTENT.metrics.med_price}{" "}
                    <span style={{ color: getColor(3) }}>
                      {json.median_pr}€
                    </span>
                  </h4>
                </li>
                <li>
                  <h4>
                    {CONTENT.metrics.med_yoc}{" "}
                    <span style={{ color: getColor(3) }}>
                      {json.median_yoc}
                    </span>
                  </h4>
                </li>
                <li>
                  <h4>
                    {CONTENT.metrics.after_2010}{" "}
                    <span style={{ color: getColor(3) }}>
                      {json.newly_built}%
                    </span>
                  </h4>
                </li>
                <li>
                  <h4>
                    {CONTENT.metrics.med_surf}{" "}
                    <span style={{ color: getColor(3) }}>
                      {json.median_area} m²
                    </span>
                  </h4>
                </li>
              </ul>
            </div>
          ) : (
            <InsufficientData
              text={CONTENT.metrics.insufficient_data}
              text2={CONTENT.metrics.few_retail}
            />
          )}
        </div>
      </div>
    </div>
  );
};
