import React from "react";
import { DataContext } from "../context/DataContext";

export const DropdownButton = () => {
  const { setJson, list } = React.useContext(DataContext);

  return (
    <div className="dropdown btn-group">
      <button
        className="btn btn-secondary btn-sm dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Switch JSON
      </button>
      <div
        className="dropdown-content"
        style={{
          display: "none",
          position: "absolute",
          backgroundColor: "#f9f9f9",
          minWidth: "160px",
          zIndex: 1,
        }}
      >
        <ul>
          {Object.keys(list).map((j, i) => (
            <li
              key={i}
              onClick={() => setJson(list[j])}
              className="dropdown-item"
            >
              {j}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
