import React from "react";
import { getColor } from "../utils";

export const BarrierLine = ({ title = "", subtitle = "" }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="border-line">
          <div className="line" style={{ backgroundColor: getColor(3) }}></div>

          <div className="col-6" style={{ marginLeft: "-12.5px" }}>
            <h3 className="box-info" style={{ backgroundColor: getColor(3) }}>
              {title.toString()}
            </h3>
          </div>
          <div className="col-6">
            <h4
              style={{
                color: getColor(3),
              }}
            >
              {subtitle}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
