import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import {useSubheader} from "../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete,Marker } from '@react-google-maps/api';
import { Form,Button,Col } from 'react-bootstrap';
import { TextField,makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import { ContentInventoryInspection } from '../modules/Hometrix/ContentInventoryInspection'
var QRCode = require('qrcode.react');
const containerStyle = {
  width: '100%',
  height: '400px'
};
const libraries = ["places","geometry"];
const useStyles = makeStyles({
  margin_r: {
    margin: '0px 5px 0px 0px',
  },

});

export function InspectionReportView(props)  {
  const id=props.match.params.id;
  const [globalLoad, setGlobalLoad] = useState(true);
  const classes = useStyles();
  const [qrData, setQrData] = useState(0);
  const [formData, updateFormData] = useState("");
  const [globalData, setGlobalData] = useState(null);
  const [imageData, setImageData] = useState({});
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();
   async function getContentImages() {


      setImageData({});

     const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
        apiURL+="?action=getContentInspection&id="+id;
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      body:data,
         headers: {

           'authorization': 'Bearer '+accessToken,
         },
       });

       const resp = await metadataResponse.json();
       console.log(resp);
       setImageData(resp.results);

   }
   async function connectRevolut() {

     var token='Token '+process.env.REACT_APP_SERVER_PSD2_TOKEN;

         var apiURL = process.env.REACT_APP_SERVER_PSD2;
         apiURL+="agreements/enduser/";
         var data={"enduser_id": id,  "aspsp_id": "REVOLUT_REVOGB21"};
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.

       withCredentials: true,
credentials: 'include',
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       body:data,
          headers: {
            'authorization': token,
          },
        });

    const resp = await metadataResponse.json();
    console.log(resp);

     return;
   }
   async function getQR(data) {
     var a=qrData;
     a++;
     if (a>2) a=0;
     setQrData(a);


     return;
   }
   async function getContent() {

     setGlobalLoad(true);
      setData(null);

     const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
        apiURL+="?action=getInspection&id="+id;
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      body:data,
         headers: {

           'authorization': 'Bearer '+accessToken,
         },
       });

       const resp = await metadataResponse.json();
       var d=resp.results[0].data;


    //   console.log("---DATA---");
      // console.log(globalData)

        setLng(d.lng);
        setLat(d.lat);
        setDistrict(d.district);
        setPrefecture(d.prefecture);
        setPostalCode(d.postalCode);
        setMunicipality(d.municipality);
        setAddress(d.address);
        setGlobalData(d);
       setGlobalLoad(false);
   }
   useEffect(()=>{
      getContent()
      getContentImages();
    }, []) // <-- empty dependency array
    const [selectedFile, setSelectedFile] = useState();
    const [childKey, setChildKey] = useState(0);
    const [dataTitle, setDataTitle] = useState();
  	const [isSelected, setIsSelected] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const [isDanger, setIsDanger] = useState(false);
    const [isFront, setIsFront] = useState(false);
    const [isMap, setIsMap] = useState(false);

      const [isResolve, setIsResolve] = useState(false);

    async function handleSubmission() {
      const data = new FormData();
       setIsUploadingFile(true);
      data.append('File', selectedFile);
      data.append('dataTitle',dataTitle);
      data.append('isFront',isFront);
data.append('isDanger',isDanger);
data.append('isMap',isMap);
data.append('isResolve',isResolve);
      const accessToken = await getAccessTokenSilently({
           audience: process.env.REACT_APP_AUTH0_AUDIENCE
         });
         var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
         apiURL+="?action=uploadInspectionImage&id="+id;
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       body:data,
       credentials: 'include',
          headers: {

            'authorization': 'Bearer '+accessToken,
          },
        });

        const resp = await metadataResponse.json();
      //  console.log(resp);
        setIsSelected(false);
        setIsUploaded(true);
        setIsUploadingFile(false);
        var i=childKey;
        i++;
        setChildKey(i);
      getContentImages();

    };
  async function onSubmit(event) {
         event.preventDefault();
       const data = new FormData(event.target);

    //   console.log(data);
//console.log(data.get('collateralId'));
    //   console.log(data.get('lat'));


     setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=updateInspection&id="+id;
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
     credentials: 'include',
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
    //  console.log(resp);
      setData(resp);
     setLoading(false);
  }
  async function exportExcel() {
     let bl = await fetch('/templates/hometrix_inspection_form_v2.xlsx').then(r => r.blob());

     var dl=await bl.arrayBuffer();
     var data = new Uint8Array(dl);

     const wb = new Excel.Workbook();
     await wb.xlsx.load(data);



     var worksheet = wb.getWorksheet('Description');
    worksheet.getCell('F8').value = globalData.collateralId;
    worksheet.getCell('F9').value = globalData.uniqueId;
    worksheet.getCell('F4').value = globalData.inspectionDt;
    worksheet.getCell('C8').value = globalData.owner;
    worksheet.getCell('B12').value = globalData.description;
    worksheet.getCell('B16').value = globalData.horizontal_1_id;
    worksheet.getCell('C16').value = globalData.horizontal_1_description;
    worksheet.getCell('F16').value = globalData.horizontal_1_sqm;
    worksheet.getCell('G16').value = globalData.horizontal_1_comments;
    worksheet.getCell('B17').value = globalData.horizontal_2_id;
    worksheet.getCell('C17').value = globalData.horizontal_2_description;
    worksheet.getCell('F17').value = globalData.horizontal_2_sqm;
    worksheet.getCell('G17').value = globalData.horizontal_2_comments;
    worksheet.getCell('B18').value = globalData.horizontal_3_id;
    worksheet.getCell('C18').value = globalData.horizontal_3_description;
    worksheet.getCell('F18').value = globalData.horizontal_3_sqm;
    worksheet.getCell('G18').value = globalData.horizontal_3_comments;
    worksheet.getCell('B19').value = globalData.horizontal_4_id;
    worksheet.getCell('C19').value = globalData.horizontal_4_description;
    worksheet.getCell('F19').value = globalData.horizontal_4_sqm;
    worksheet.getCell('G19').value = globalData.horizontal_4_comments;
    worksheet.getCell('B20').value = globalData.horizontal_5_id;
    worksheet.getCell('C20').value = globalData.horizontal_5_description;
    worksheet.getCell('F20').value = globalData.horizontal_5_sqm;
    worksheet.getCell('G20').value = globalData.horizontal_5_comments;
    worksheet.getCell('B21').value = globalData.horizontal_6_id;
    worksheet.getCell('C21').value = globalData.horizontal_6_description;
    worksheet.getCell('F21').value = globalData.horizontal_6_sqm;
    worksheet.getCell('G21').value = globalData.horizontal_6_comments;
    worksheet.getCell('C23').value = globalData.horizontal_track;
    worksheet.getCell('E24').value = globalData.horizontal_comments;
    worksheet.getCell('C29').value = globalData.address;
    worksheet.getCell('C31').value = globalData.postalCode;
    worksheet.getCell('C32').value = globalData.municipality;
    worksheet.getCell('F29').value = globalData.district;
    worksheet.getCell('F31').value = globalData.toponym;
    worksheet.getCell('F32').value = globalData.plotNumber;
    worksheet.getCell('F30').value = globalData.prefecture;
    worksheet.getCell('C35').value = globalData.lat;
    worksheet.getCell('C36').value = globalData.lng;
    worksheet.getCell('F35').value = {
      text: "https://maps.google.com/?q="+globalData.lat+","+globalData.lng+"&ll="+globalData.lat+","+globalData.lng+"&z=15",
      hyperlink: "https://maps.google.com/?q="+globalData.lat+","+globalData.lng+"&ll="+globalData.lat+","+globalData.lng+"&z=15",
      tooltip: "https://maps.google.com/?q="+globalData.lat+","+globalData.lng+"&ll="+globalData.lat+","+globalData.lng+"&z=15"
    };

    worksheet.getCell('C44').value = globalData.leased;
    worksheet.getCell('C45').value = globalData.lease_start;
    worksheet.getCell('F44').value = globalData.leaser_name;
    worksheet.getCell('F45').value = globalData.leaser_contact;
    worksheet.getCell('C49').value = globalData.sequestrator;
    worksheet.getCell('C50').value = globalData.sequestrator_id;
    worksheet.getCell('C51').value = globalData.valuable_items;
    worksheet.getCell('C52').value = globalData.needs_guarding;
    worksheet.getCell('F49').value = globalData.sequestrator_address;
    worksheet.getCell('F50').value = globalData.sequestrator_contact;
    worksheet.getCell('F51').value = globalData.guarantee_area;
    worksheet.getCell('F52').value = globalData.bailiff;
    worksheet.getCell('C55').value = globalData.power_number;
    worksheet.getCell('C56').value = globalData.power;
    worksheet.getCell('C57').value = globalData.power_actions;
    worksheet.getCell('F55').value = globalData.power_meter;
    worksheet.getCell('F59').value = globalData.gas_meter;
    worksheet.getCell('F62').value = globalData.water_meter;
    worksheet.getCell('C59').value = globalData.gas_number;
    worksheet.getCell('C60').value = globalData.gas_actions;
    worksheet.getCell('C62').value = globalData.water_number;
    worksheet.getCell('C63').value = globalData.water;
    worksheet.getCell('C64').value = globalData.water_actions;
    worksheet.getCell('C75').value = globalData.action_secure_break;
    worksheet.getCell('C76').value = globalData.action_normal;
    worksheet.getCell('C77').value = globalData.action_secure;
    worksheet.getCell('C78').value = globalData.action_normal_break;
    worksheet.getCell('F75').value = globalData.management_available;
    worksheet.getCell('F76').value = globalData.management_name;
    worksheet.getCell('F77').value = globalData.management_contact;
    worksheet.getCell('F78').value = globalData.general_entrance;
    worksheet.getCell('C84').value = globalData.heating+" "+globalData.heating_other;
    worksheet.getCell('C85').value = globalData.hot_water;
    worksheet.getCell('C90').value = globalData.danger_violation;
    worksheet.getCell('C91').value = globalData.danger_water;
    worksheet.getCell('C92').value = globalData.danger_physical;
    worksheet.getCell('C93').value = globalData.danger_construction;
    worksheet.getCell('C94').value = globalData.danger_fire;
    worksheet.getCell('C95').value = globalData.danger_environment;
    worksheet.getCell('C96').value = globalData.danger_other;
    worksheet.getCell('E90').value = globalData.danger_violation_text;
    worksheet.getCell('E91').value = globalData.danger_water_text;
    worksheet.getCell('E92').value = globalData.danger_physical_text;
    worksheet.getCell('E93').value = globalData.danger_construction_text;
    worksheet.getCell('E94').value = globalData.danger_fire_text;
    worksheet.getCell('E95').value = globalData.danger_environment_text;
    worksheet.getCell('E96').value = globalData.danger_other_text;
    worksheet.getCell('B99').value = globalData.audit_comments;
    worksheet.getCell('B103').value = globalData.surveyor;

    // Images
    var counter=0;
    var counterD=0;
    var counterR=0;
    var where="";
    if (imageData.map!==undefined) {
         var worksheetPhotos = wb.getWorksheet('Photos');
      imageData.forEach(function(d) {
        var show=1;

        if (d.isMap==="true") {
          const imageId1 = wb.addImage({
              base64: "data:image/jpg;base64,"+d.base64,
              extension: 'jpeg',
            });
            worksheet.addImage(imageId1, 'B41:C41');

              show=0;
        }
        if (d.isFront==="true") {
          const imageId2 = wb.addImage({
              base64: "data:image/jpg;base64,"+d.base64,
              extension: 'jpeg',
            });
            worksheet.addImage(imageId2, 'E41:G41');

              show=0;
        }
        if (show) {
          if (d.isDanger==="true") {
            counterD++;
            if (counterD==1) where="B37:B61";
            if (counterD==2) where="D37:D61";
            if (counterD==3) where="F37:F61";
            if (counterD==4) where="H37:H61";
            if (counterD==5) where="J65:J61";
          }else if (d.isResolve==="true") {
                counterR++;
                if (counterR==1) where="B65:B87";
                if (counterR==2) where="D65:D87";
                if (counterR==3) where="F65:F87";
                if (counterR==4) where="H65:H87";
                if (counterR==5) where="J65:J87";
              } else {
                counter++;
                if (counter==1) where="B11:B33";
                if (counter==2) where="D11:D33";
                if (counter==3) where="F11:F33";
                if (counter==4) where="H11:H33";
                  if (counter==5) where="J11:J33";
              }

          const imageId = wb.addImage({
              base64: "data:image/jpg;base64,"+d.base64,
              extension: 'jpeg',
            });
            worksheetPhotos.addImage(imageId, where);
            //  console.log("data:image/jpg;base64,"+d.base64);

        }
      })
    }



    const buffer = await wb.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], {type: fileType});

    saveAs(blob, 'InspectionForm_'+globalData['collateralId'] + fileExtension);
    return;

  }


  const onLoad = () => {};
  const [map, setMap] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [district,setDistrict]=useState("");
  const [address,setAddress]=useState("");
  const [prefecture,setPrefecture]=useState("");
  const [googleAddress, setGoogleAddress] = useState("");
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  var places;


  var place=null;

  var center = {
    lat: 38.0572437,
    lng: 23.8481676
  };
  function onloadMap(ref){
    setMap(ref);
  }
  function onLoadMarker(ref) {
    setMarker(ref);
  }
  function onSearchBoxMounted(ref) {
      setAutocomplete(ref);
  }
  function onDragEnd(e) {
  //  console.log("onPositionChanged");

    //map.panTo(new window.google.maps.LatLng( marker.getPosition().lat(), marker.getPosition().lng() ));
    //center.lat=marker.getPosition().lat();
  //  center.lng=marker.getPosition().lng();
      setLat(marker.getPosition().lat());
      setLng(marker.getPosition().lng());


    geocodePosition(marker.getPosition());
//    setLat(place.geometry.location.lat());
//    setLng(place.geometry.location.lng());
  }
  function geocodePosition(pos)  {



  var  geocoder = new window.google.maps.Geocoder();
   geocoder.geocode
    ({
        latLng: pos
    },
        function(results, status)
        {
            if (status ==window.google.maps.GeocoderStatus.OK)
            {


              updateAddress(results);
            }
            else
            {
            	//  setAddress(marker.getPosition().lat()+","+marker.getPosition().lng());


            }
        }
    );

	}
  function updateAddress(results) {
    setAddress(results[0].formatted_address);
      Object.keys(results[0].address_components).forEach(function(key) {
        var result=results[0].address_components[key];
        if (result.types.indexOf("locality") > -1) {
          setMunicipality(result.long_name);
        }
        if (result.types.indexOf("postal_code") > -1) {
          setPostalCode(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_32") > -1) {
          setDistrict(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_3") > -1) {
          setPrefecture(result.long_name);
        }

      });
  }
  function onPlacesChanged() {
  //  console.log('autocomplete: ', autocomplete)
//    console.log(searchBox.getPlaces());
      if (autocomplete !== null) {
    //   console.log(autocomplete.getPlace())
      place=autocomplete.getPlace();


      //setGoogleAddress(place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      center.lat=place.geometry.location.lat();
      center.lng=place.geometry.location.lng();

      marker.setPosition(new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );

      map.panTo( new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );
      geocodePosition(marker.getPosition());

      } else {
       console.log('Autocomplete is not loaded yet!')
      }
  }
 const [value, setValue] = useState('Hello World');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const changeHandler = (e) => {
    if (e.target.id=='file') {
        setIsUploaded(false);

  		setSelectedFile(e.target.files[0]);
  		setIsSelected(true);
    }
      if (e.target.id=='title') {
        setDataTitle(e.target.value);
      }
      if (e.target.id==="select_image_category") {

        setIsMap(false);
        setIsDanger(false);
        setIsFront(false);
        setIsResolve(false);
        if (e.target.value==="isDanger") setIsDanger(true);
        if (e.target.value==="isMap") setIsMap(true);
        if (e.target.value==="isFront") setIsFront(true);
        if (e.target.value==="isResolve") setIsResolve(true);

      }


  	};
  function handleChange (e) {

    if (e.target.id=='lat') {
      setLat(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    if (e.target.id=='lng') {
      setLng(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    //var x=globalData;
    //x[e.target.id]=e.target.value;
    //setGlobalData(x);


 }


  return (

    <>
    {globalLoad!=true?
      <>
      <Form onSubmit={onSubmit}>
    <div className="row">
      <div className="col-xl-12">
        <div className={`row card `}>
        <div className="col-xl-12 card-header">
              <h3>ΑΡΧΙΚΗ ΕΠΙΘΕΩΡΗΣΗ ΑΚΙΝΗΤΟΥ </h3>
        </div>
          <div className="col-xl-12  card-body">
              <Form.Group>
                <Form.Label>Μοναδικός κωδικός ακινήτου</Form.Label>
                <Form.Control type="text" name="collateralId" id="collateralId" value={globalData.collateralId} onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Μοναδικός κωδικός ακινήτου (<strong>δεν επιτρέπεται η αλλαγή</strong>)
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>  Α/Α Ακινήτου</Form.Label>
                <Form.Control type="text" name="uniqueId" id="uniqueId" defaultValue={globalData.uniqueId}  onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Α/Α Ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>  ΚΑΕΚ Ακινήτου</Form.Label>
                <Form.Control type="text" name="kaek" id="kaek" defaultValue={globalData.kaek}  onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
              ΚΑΕΚ Ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>

                <Form.Label>Ημερομηνία Αυτοψίας</Form.Label>
                <Form.Control type="date" name="inspectionDt" id="inspectionDt" defaultValue={globalData.inspectionDt}  onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                  Ημερομηνία Αυτοψίας/Αποβολής
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>  Ιδιοκτήτης Ακινήτου</Form.Label>
                <Form.Control type="text" name="owner" id="owner" defaultValue={globalData.owner}  onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                  Ιδιοκτήτης Ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                 <Form.Label>Περιγραφή ακινήτου</Form.Label>
                 <Form.Control  as="textarea"  name="description" id="description" defaultValue={globalData.description}  onChange={handleChange}  rows={10 } />
                 <Form.Text className="text-muted">
                  Περιγραφή ακινήτου
                 </Form.Text>
               </Form.Group>

          </div>

    </div>

  </div>

</div>
<div className="row gutter-t">
  <div className="col-xl-12">
    <div className="row card">
    <div className="col-xl-12 card-header">
          <h3>ΠΙΝΑΚΑΣ ΟΡΙΖΟΝΤΙΩΝ ΙΔΙΟΚΤΗΣΙΩΝ ΑΚΙΝΗΤΟΥ					</h3>
    </div>
      <div className="col-xl-12  card-body">
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label> A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_1_id" id="horizontal_1_id" defaultValue={globalData.horizontal_1_id}  onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_1_description" id="horizontal_1_description" defaultValue={globalData.horizontal_1_description}  onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label>   m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_1_sqm" id="horizontal_1_sqm" defaultValue={globalData.horizontal_1_sqm}  onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_1_comments" id="horizontal_1_comments" defaultValue={globalData.horizontal_1_comments}  onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label>A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_2_id" id="horizontal_2_id"  defaultValue={globalData.horizontal_2_id} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_2_description" id="horizontal_2_description"  defaultValue={globalData.horizontal_2_description} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_2_sqm" id="horizontal_2_sqm"  defaultValue={globalData.horizontal_2_sqm} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label> Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_2_comments" id="horizontal_2_comments"  defaultValue={globalData.horizontal_2_comments} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label>A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_3_id" id="horizontal_3_id"  defaultValue={globalData.horizontal_3_id} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_3_description" id="horizontal_3_description"  defaultValue={globalData.horizontal_3_description} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_3_sqm" id="horizontal_3_sqm"  defaultValue={globalData.horizontal_3_sqm} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_3_comments" id="horizontal_3_comments"  defaultValue={globalData.horizontal_3_comments} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label> A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_4_id" id="horizontal_4_id"  defaultValue={globalData.horizontal_4_id} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_4_description" id="horizontal_4_description"  defaultValue={globalData.horizontal_4_description} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_4_sqm" id="horizontal_4_sqm"  defaultValue={globalData.horizontal_4_sqm} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_4_comments" id="horizontal_4_comments"  defaultValue={globalData.horizontal_4_comments} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label>   A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_5_id" id="horizontal_5_id"  defaultValue={globalData.horizontal_5_id} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_5_description" id="horizontal_5_description"  defaultValue={globalData.horizontal_5_description} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_5_sqm" id="horizontal_5_sqm"  defaultValue={globalData.horizontal_5_sqm} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label> Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_5_comments" id="horizontal_5_comments"  defaultValue={globalData.horizontal_5_comments} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label> A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_6_id" id="horizontal_6_id"  defaultValue={globalData.horizontal_6_id} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_6_description" id="horizontal_6_description" defaultValue={globalData.horizontal_6_description} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label>   m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_6_sqm" id="horizontal_6_sqm"  defaultValue={globalData.horizontal_6_sqm} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label> Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_6_comments" id="horizontal_6_comments"  defaultValue={globalData.horizontal_6_comments} onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={2}>
       <Form.Group  as={Col} >
       <Form.Label>  Ύπαρξη Παρακολουθημάτων</Form.Label>
       <Form.Control as="select"  name="horizontal_track"  defaultValue={globalData.horizontal_track} id="horizontal_track">
         <option value="Όχι">Όχι</option>
         <option value="Ναι">Ναι</option>

       </Form.Control>   <Form.Text className="text-muted">
    Ύπαρξη Παρακολουθημάτων
       </Form.Text>
       </Form.Group>
       </Col>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή παρακολουθήματος</Form.Label>
         <Form.Control  as="textarea"  name="horizontal_comments"  defaultValue={globalData.horizontal_comments}  id="horizontal_comments" onChange={handleChange}  rows={5} />
         <Form.Text className="text-muted">
        Περιγραφή παρακολουθήματος
         </Form.Text>
         </Form.Group>
       </Form.Row>

  </div>

</div>
</div>


    <div className="col-xl-12 gutter-t">
      <div className="row card">
      <div className="col-xl-12 card-header">
            <h3>ΤΟΠΟΘΕΣΙΑ ΑΚΙΝΗΤΟΥ 					 </h3>
      </div>
        <div className="col-xl-12 card-body">

              <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
                  libraries={libraries}
                >
                  <GoogleMap
                    onLoad={onloadMap}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                  >
                    { /* Child components, such as markers, info windows, etc. */ }
                    <Autocomplete
                  onLoad={onSearchBoxMounted}
                  onPlaceChanged={onPlacesChanged}
                  onPlacesChanged={onPlacesChanged}
                  onFail={error => console.error(error)}

                >
                  <input

                    type="text"
                    placeholder=" Εύρεση απο Google"
                    style={{
                      boxSizing: `border-box`,
                      border: `2px solid #E4E6EF`,
                      width: `100%`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: '0.42rem',
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      bottom:"0px",
                      marginLeft: "-50%"

                    }}
                  />
                </Autocomplete>

                  <Marker
                      onLoad={onLoadMarker}
                      draggable={true}
                      onDragEnd={onDragEnd}
                 />



                  </GoogleMap>
                </LoadScript>

      {/* end: MAP */}

                <Form.Group className="gutter-t">
                  <Form.Label>  Γεωγραφικό πλάτος (Latitude)</Form.Label>
                  <Form.Control type="text"  name="lat" value={lat}  onChange={handleChange} id="lat" placeholder="" />
                  <Form.Text className="text-muted">
                  Γεωγραφικό πλάτος (Latitude)
                  </Form.Text>
                </Form.Group>

                  <Form.Group>
                    <Form.Label>  Γεωγραφικό μήκος (Longtitude)</Form.Label>
                    <Form.Control type="text" name="lng" id="lng"  value={lng}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Γεωγραφικό μήκος (Longtitude)
                    </Form.Text>
                  </Form.Group>


                  <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>  Διεύθυνση</Form.Label>
                    <Form.Control type="text" name="address" id="address" value={address} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Διεύθυνση
                    </Form.Text>
                  </Form.Group>
                  <Col xs={2}>
                  <Form.Group>
                    <Form.Label>    ΤΚ</Form.Label>
                    <Form.Control type="text" name="postalCode" id="postalCode" value={postalCode}  placeholder="" />
                    <Form.Text className="text-muted">
                    ΤΚ
                    </Form.Text>
                  </Form.Group>
                  </Col>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label>  Δήμος</Form.Label>
                    <Form.Control type="text" name="municipality" id="municipality"  value={municipality}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δήμος
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>  Δημοτική ενότητα</Form.Label>
                    <Form.Control type="text" name="district" id="district"    value={district}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δημοτική ενότητα
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>  Περιφέρεια</Form.Label>
                    <Form.Control type="text" name="prefecture" id="prefecture"  value={prefecture} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Περιφέρεια
                    </Form.Text>
                  </Form.Group>
                    <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>  Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού</Form.Label>
                    <Form.Control type="text" name="plotNumber" id="plotNumber" defaultValue={globalData.plotNumber}    onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού
                    </Form.Text>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Τοπωνύμιο</Form.Label>
                    <Form.Control type="text" name="toponym" id="toponym"   defaultValue={globalData.toponym}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Τοπωνύμιο
                    </Form.Text>
                  </Form.Group>
                    </Form.Row>
          </div>
          </div>
  </div>
  <div className="col-xl-12 gutter-t">
    <div className={`row card `}>
    <div className="col-xl-12 card-header">
          <h3>ΜΙΣΘΩΣΕΙΣ 					</h3>
    </div>
      <div className="col-xl-12  card-body">
            <Form.Row>
            <Col xs={2}>
            <Form.Group  as={Col} >
            <Form.Label>      Ύπαρξη Υφιστάμενων Μισθώσεων</Form.Label>
            <Form.Control as="select"  name="leased" defaultValue={globalData.leased} id="leased">
              <option value="Όχι">Όχι</option>
              <option value="Ναι">Ναι</option>

            </Form.Control>   <Form.Text className="text-muted">
            Ύπαρξη Υφιστάμενων Μισθώσεων
            </Form.Text>
            </Form.Group>
            </Col>
              <Form.Group  as={Col} >
              <Form.Label>  Επωνυμία Μισθωτή</Form.Label>
              <Form.Control  type="text" name="leaser_name" id="leaser_name" defaultValue={globalData.leaser_name}  onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              Επωνυμία Μισθωτή
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Τηλέφωνο μισθωτή</Form.Label>
              <Form.Control  type="text" name="leaser_contact" id="leaser_contact"  defaultValue={globalData.leaser_contact} onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              Τηλέφωνο μισθωτή
              </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label>  Έναρξη Μίσθωσης</Form.Label>
              <Form.Control  type="date" name="lease_start" id="lease_start"  defaultValue={globalData.lease_start}   onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
            Έναρξη Μίσθωσης
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Λήξη Μίσθωσης</Form.Label>
              <Form.Control  type="date" name="lease_end" id="lease_end" defaultValue={globalData.lease_end}  onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
            Λήξη Μίσθωσης
              </Form.Text>
              </Form.Group>
            </Form.Row>

       </div>

      </div>

   </div>

   <div className="col-xl-12 gutter-t">
     <div className={`row card `}>
     <div className="col-xl-12 card-header">
           <h3>ΜΕΣΕΓΓΥΗΣΗ 					</h3>
     </div>
       <div className="col-xl-12  card-body">
             <Form.Row>
             <Col xs={2}>
             <Form.Group  as={Col} >
             <Form.Label>     Πραγματοποίηση Μεσεγγύησης?</Form.Label>
             <Form.Control as="select"  name="guarantee" id="guarantee" defaultValue={globalData.guarantee} >
               <option value="Όχι">Όχι</option>
               <option value="Ναι">Ναι</option>

             </Form.Control>   <Form.Text className="text-muted">
             Πραγματοποίηση Μεσεγγύησης
             </Form.Text>
             </Form.Group>
             </Col>
               <Form.Group  as={Col} >
               <Form.Label>  Ορισμένος Μεσεγγυητής</Form.Label>
               <Form.Control  type="text" name="sequestrator" id="sequestrator" defaultValue={globalData.sequestrator}  onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
                Ορισμένος Μεσεγγυητής
               </Form.Text>
               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label>  Ορισμένος Μεσεγγυητής</Form.Label>
               <Form.Control  type="text" name="sequestrator_contact" id="sequestrator_contact" defaultValue={globalData.sequestrator_contact} o onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
              Ορισμένος Μεσεγγυητής
               </Form.Text>
               </Form.Group>
             </Form.Row>
             <Form.Row>

               <Form.Group  as={Col} >
               <Form.Label>   Αριθμός Ταυτότητας Μεσεγγυητή</Form.Label>
               <Form.Control  type="text" name="sequestrator_id" id="sequestrator_id"  defaultValue={globalData.sequestrator_id} onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Αριθμός Ταυτότητας Μεσεγγυητή
               </Form.Text>
               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label>   Διεύθυνση Μεσεγγυητή</Form.Label>
               <Form.Control  type="text" name="sequestrator_address" id="sequestrator_address"  defaultValue={globalData.sequestrator_address}  onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Διεύθυνση Μεσεγγυητή
               </Form.Text>
               </Form.Group>
             </Form.Row>
             <Form.Row>

               <Form.Group  as={Col} >
               <Form.Label>  Χώρος Μεσεγγύησης</Form.Label>
               <Form.Control  type="text" name="guarantee_area" id="guarantee_area"  defaultValue={globalData.guarantee_area}  onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
                Χώρος Μεσεγγύησης
               </Form.Text>
               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label> Δικαστικός Επιμελητής</Form.Label>
               <Form.Control  type="text" name="bailiff" id="bailiff"   defaultValue={globalData.bailiff} onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Δικαστικός Επιμελητής
               </Form.Text>
               </Form.Group>
             </Form.Row>
             <Form.Row>

             <Form.Group  as={Col} >
             <Form.Label>    Σημαντικής αξίας αντικείμενα?</Form.Label>
             <Form.Control as="select"  name="valuable_items" id="valuable_items"  defaultValue={globalData.valuable_items}>
               <option value="Όχι">Όχι</option>
               <option value="Ναι">Ναι</option>

             </Form.Control>   <Form.Text className="text-muted">
            Σημαντικής αξίας αντικείμενα
             </Form.Text>
             </Form.Group>
             <Form.Group  as={Col} >
             <Form.Label>Συστήνεται η φύλαξη του ακινήτουs? </Form.Label>
             <Form.Control as="select"  name="needs_guarding" id="needs_guarding" defaultValue={globalData.needs_guarding}>
               <option value="Όχι">Όχι</option>
               <option value="Ναι">Ναι</option>

             </Form.Control>   <Form.Text className="text-muted">
            Συστήνεται η φύλαξη του ακινήτου
             </Form.Text>
             </Form.Group>

             </Form.Row>
        </div>

       </div>

    </div>
    {/* next*/}
    <div className="col-xl-12 gutter-t">
      <div className={`row card `}>
      <div className="col-xl-12 card-header">
            <h3>ΟΡΓΑΝΙΣΜΟΙ ΚΟΙΝΗΣ ΩΦΕΛΕΙΑΣ </h3>
      </div>
        <div className="col-xl-12  card-body">
              <Form.Row>
              <Col xs={2}>
              <Form.Group  as={Col} >
              <Form.Label>  Κλείσιμο Διακόπτη Παροχής Ρεύματος</Form.Label>
              <Form.Control as="select"  name="power" id="power" defaultValue={globalData.power} >
                <option value="Όχι">Όχι</option>
                <option value="Ναι">Ναι</option>

              </Form.Control>   <Form.Text className="text-muted">
              Κλείσιμο Διακόπτη Παροχής Ρεύματος
              </Form.Text>
              </Form.Group>
              </Col>
                <Form.Group  as={Col} >
                <Form.Label>  Αριθμός Μετρητή Παροχής Ρεύματος</Form.Label>
                <Form.Control  type="text" name="power_number" id="power_number"  defaultValue={globalData.power_number} onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Ρεύματος
                </Form.Text>
                </Form.Group>
                <Form.Group  as={Col} >
                <Form.Label>  Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) kWh</Form.Label>
                <Form.Control  type="text" name="power_meter" id="power_meter"  defaultValue={globalData.power_meter}  onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) kWh
                </Form.Text>
                </Form.Group>
              </Form.Row>


              <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label> Επόμενες ενέργειες</Form.Label>
              <Form.Control as="select"  name="power_actions" id="power_actions" defaultValue={globalData.power_actions}  >
              <option value="N/A">N/A</option>
              <option value="Request Termination/Αιτηση διακοπής">Αιτηση διακοπής</option>
              <option value="Μaintain Account/Διατήρηση σύνδεσης">Διατήρηση σύνδεσης</option>
             <option value="Check Account with Provider/Έλεγχος παροχής με υπηρεσία">Έλεγχος παροχής με υπηρεσία </option>
                </Form.Control>   <Form.Text className="text-muted">
             Επόμενες ενέργειες
              </Form.Text>
              </Form.Group>


              </Form.Row>

              <Form.Row>

                <Form.Group  as={Col} >
                <Form.Label>    Αριθμός Μετρητή Παροχής Φυσικού Αερίου</Form.Label>
                <Form.Control  type="text" name="gas_number" id="gas_number"  defaultValue={globalData.gas_number}  onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Φυσικού Αερίου
                </Form.Text>
                </Form.Group>
                <Form.Group  as={Col} >
                <Form.Label>  Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) Nm3</Form.Label>
                <Form.Control  type="text" name="gas_meter" id="gas_meter" defaultValue={globalData.gas_meter}  onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) Nm3

                </Form.Text>
                </Form.Group>
              </Form.Row>


              <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label>   Επόμενες ενέργειες</Form.Label>
              <Form.Control as="select"  name="gas_actions" id="gas_actions"  defaultValue={globalData.gas_actions}>
              <option value="N/A">N/A</option>
              <option value="Request Termination/Αιτηση διακοπής">Αιτηση διακοπής</option>
              <option value="Μaintain Account/Διατήρηση σύνδεσης">Διατήρηση σύνδεσης</option>
             <option value="Check Account with Provider/Έλεγχος παροχής με υπηρεσία">Έλεγχος παροχής με υπηρεσία </option>
              </Form.Control>   <Form.Text className="text-muted">
             Επόμενες ενέργειες
              </Form.Text>
              </Form.Group>


              </Form.Row>

              <Form.Row>
              <Col xs={2}>
              <Form.Group  as={Col} >
              <Form.Label>Κλείσιμο Διακόπτη Παροχής Νερού?</Form.Label>
              <Form.Control as="select"  name="water" id="water"  defaultValue={globalData.water}>
                <option value="Όχι">Όχι</option>
                <option value="Ναι">Ναι</option>

              </Form.Control>   <Form.Text className="text-muted">
              Κλείσιμο Διακόπτη Παροχής Νερού
              </Form.Text>
              </Form.Group>
              </Col>
                <Form.Group  as={Col} >
                <Form.Label>Αριθμός Μετρητή Παροχής Νερού</Form.Label>
                <Form.Control  type="text" name="water_number" id="water_number" defaultValue={globalData.water_number}  onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Νερού
                </Form.Text>
                </Form.Group>
                <Form.Group  as={Col} >
                <Form.Label>Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) m3</Form.Label>
                <Form.Control  type="text" name="water_meter" id="water_meter" onChange={handleChange} defaultValue={globalData.water_meter}  rows={5} />
                <Form.Text className="text-muted">
                Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) m3
                </Form.Text>
                </Form.Group>
              </Form.Row>


              <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label>   Επόμενες ενέργειες</Form.Label>
              <Form.Control as="select"  name="water_actions" id="water_actions" defaultValue={globalData.water_actions} >
              <option value="N/A">N/A</option>
              <option value="Request Termination/Αιτηση διακοπής">Αιτηση διακοπής</option>
              <option value="Μaintain Account/Διατήρηση σύνδεσης">ιατήρηση σύνδεσης</option>
             <option value="Check Account with Provider/Έλεγχος παροχής με υπηρεσία">Έλεγχος παροχής με υπηρεσία </option>
              </Form.Control>   <Form.Text className="text-muted">
             Επόμενες ενέργειες
              </Form.Text>
              </Form.Group>


              </Form.Row>
         </div>

        </div>
        {/*next*/}

           <div className="col-xl-12 gutter-t">
             <div className={`row card `}>
             <div className="col-xl-12 card-header">
                   <h3>ΠΡΑΓΜΑΤΟΠΟΙΗΘΕΙΣΕΣ ΕΝΕΡΓΕΙΕΣ 					</h3>
             </div>
               <div className="col-xl-12  card-body">
                     <Form.Row>

                       <Form.Group  as={Col} >
                       <Form.Label>  Αλλαγή Κλειδαριάς/ών: Ασφαλείας Με Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_secure_break" id="action_secure_break" defaultValue={globalData.action_secure_break}  onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                        Αλλαγή Κλειδαριάς/ών: Ασφαλείας Με Διάρρηξη
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>  Αλλαγή Κλειδαριάς/ών: Ασφαλείας Χωρίς Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_secure" id="action_secure" defaultValue={globalData.action_secure}  onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                    Αλλαγή Κλειδαριάς/ών: Ασφαλείας Χωρίς Διάρρηξη
                       </Form.Text>
                       </Form.Group>


                       <Form.Group  as={Col} >
                       <Form.Label>  Αλλαγή Κλειδαριάς/ών: Απλές με Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_normal_break" id="action_normal_break"  defaultValue={globalData.action_normal_break} onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                          Αλλαγή Κλειδαριάς/ών: Απλές με Διάρρηξη
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>  Αλλαγή Κλειδαριάς/ών: Απλές Χωρίς Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_normal" id="action_normal" defaultValue={globalData.action_normal} onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                    Αλλαγή Κλειδαριάς/ών: Απλές Χωρίς Διάρρηξη
                       </Form.Text>
                       </Form.Group>
                     </Form.Row>

                     <Form.Row>
                     <Col xs={3}>
                     <Form.Group  as={Col} >
                     <Form.Label>  Ανάκτηση Στοιχείων Διαχείρισης</Form.Label>
                     <Form.Control as="select"  name="management_available" id="management_available" defaultValue={globalData.management_available} >
                       <option value="Όχι">Όχι</option>
                       <option value="Ναι">Ναι</option>

                     </Form.Control>   <Form.Text className="text-muted">
                    Ανάκτηση Στοιχείων Διαχείρισης
                     </Form.Text>
                     </Form.Group>
                     </Col>
                       <Form.Group  as={Col} >
                       <Form.Label>Υπεύθυνος Διαχειριστής (Όνομα)</Form.Label>
                       <Form.Control  type="text" name="management_name" id="management_name" defaultValue={globalData.management_name}  onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                      Υπεύθυνος Διαχειριστής (Όνομα)
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>   Τηλέφωνο Επικοινωνίας Διαχειριστή</Form.Label>
                       <Form.Control  type="text" name="management_contact" id="management_contact" defaultValue={globalData.management_contact} onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                       Τηλέφωνο Επικοινωνίας Διαχειριστή
                       </Form.Text>
                       </Form.Group>
                       <Col xs={2}>
                       <Form.Group  as={Col} >
                       <Form.Label>  Ανάκτηση Κλειδιών Εισόδου Κτηρίου</Form.Label>
                       <Form.Control as="select"  name="general_entrance" id="general_entrance" defaultValue={globalData.general_entrance} >
                         <option value="Όχι">Όχι</option>
                         <option value="Ναι">Ναι</option>

                       </Form.Control>   <Form.Text className="text-muted">
                    Ανάκτηση Κλειδιών Εισόδου Κτηρίου
                       </Form.Text>
                       </Form.Group>
                       </Col>
                       </Form.Row>
                </div>

               </div>

            </div>
            {/*next*/}

               <div className="col-xl-12 gutter-t">
                 <div className={`row card `}>
                 <div className="col-xl-12 card-header">
                       <h3>ΘΕΡΜΑΝΣΗ / ΣΥΣΤΗΜΑ Ζ.Ν.Χ					</h3>
                 </div>
                   <div className="col-xl-12  card-body">
                         <Form.Row>

                           <Form.Group  as={Col} >
                           <Form.Label> Θέρμανση</Form.Label>
                           <Form.Control as="select"  name="heating" id="heating"  defaultValue={globalData.heating} >
                           <option value="Not installed/Μη εγκατεστημένη">Μη εγκατεστημένη</option>
                           <option value="Low efficiency/Πετρέλαιο">Πετρέλαιο</option>
                           <option value="Low efficiency measured/Αυτόνομη">Αυτόνομη</option>
                           <option value="High efficiency/Φυσικό αέριο">Φυσικό αέριο</option>

                           </Form.Control>   <Form.Text className="text-muted">
                           Θέρμανση
                           </Form.Text>
                           </Form.Group>
                           <Form.Group  as={Col} >
                           <Form.Label>  Αναλυτικά ή άλλο</Form.Label>
                           <Form.Control  type="text" name="heating_other" id="heating_other"  defaultValue={globalData.heating_other}   onChange={handleChange}  rows={5} />
                           <Form.Text className="text-muted">
                      Αναλυτικά ή άλλο
                           </Form.Text>
                           </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group  as={Col} >
                            <Form.Label>Συστημα Ζεστού Νερού Χρήσης </Form.Label>
                            <Form.Control  type="text" name="hot_water" id="hot_water" defaultValue={globalData.hot_water}  onChange={handleChange}  rows={5} />
                            <Form.Text className="text-muted">
                       Συστημα Ζεστού Νερού Χρήσης Πχ ηλεκτρικός θερμοσίφωνας, ηλιακός θερμοσίφωνας, boiler κτλ.
                            </Form.Text>
                            </Form.Group>
                           </Form.Row>
                    </div>

                   </div>

                </div>
        {/*next*/}
        {/*next*/}

           <div className="col-xl-12 gutter-t">
             <div className={`row card `}>
             <div className="col-xl-12 card-header">
                   <h3> ΑΜΕΣΟΙ ΚΙΝΔΥΝΟΙ ΠΕΡΙΜΕΤΡΟΥ & ΑΝΤΙΜΕΤΩΠΙΣΗ 					</h3>
             </div>
               <div className="col-xl-12  card-body">
                     <Form.Row>

                       <Form.Group  as={Col} >
                       <Form.Label>  Παραβίασης/Καταπάτησης Χώρου</Form.Label>
                       <Form.Control as="select"  name="danger_violation" id="danger_violation"  defaultValue={globalData.danger_violation} >
                       <option value="N/A">N/A</option>
                       <option value="Όχι">Όχι</option>
                       <option value="Ναι">Ναι</option>
                       </Form.Control>
                         <Form.Text className="text-muted">
                      Παραβίασης/Καταπάτησης Χώρου
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>Περιγραφή Κινδύνου</Form.Label>
                       <Form.Control  type="text" name="danger_violation_text" id="danger_violation_text"  defaultValue={globalData.danger_violation_text} onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                  Περιγραφή Κινδύνου
                       </Form.Text>
                       </Form.Group>
                      </Form.Row>
                      <Form.Row>

                        <Form.Group  as={Col} >
                        <Form.Label>   Διαρροής/Εισροής Υδάτων</Form.Label>
                        <Form.Control as="select"  name="danger_water" id="danger_water"  defaultValue={globalData.danger_water} >
                        <option value="N/A">N/A</option>
                        <option value="Όχι">Όχι</option>
                        <option value="Ναι">Ναι</option>

                        </Form.Control>
                          <Form.Text className="text-muted">
                       Διαρροής/Εισροής Υδάτων
                        </Form.Text>
                        </Form.Group>
                        <Form.Group  as={Col} >
                        <Form.Label> Περιγραφή Κινδύνου</Form.Label>
                        <Form.Control  type="text" name="danger_water_text" id="danger_water_text" defaultValue={globalData.danger_water_text} onChange={handleChange}  rows={5} />
                        <Form.Text className="text-muted">
                   Περιγραφή Κινδύνου
                        </Form.Text>
                        </Form.Group>
                       </Form.Row>
                       <Form.Row>

                         <Form.Group  as={Col} >
                         <Form.Label>  Σωματικής Ακεραιότητας</Form.Label>
                         <Form.Control as="select"  name="danger_physical" id="danger_physical"  defaultValue={globalData.danger_physical}>
                         <option value="N/A">N/A</option>
                         <option value="Όχι">Όχι</option>
                         <option value="Ναι">Ναι</option>

                         </Form.Control>
                           <Form.Text className="text-muted">
                    Σωματικής Ακεραιότητας
                         </Form.Text>
                         </Form.Group>
                         <Form.Group  as={Col} >
                         <Form.Label>  Περιγραφή Κινδύνου</Form.Label>
                         <Form.Control  type="text" name="danger_physical_text" id="danger_physical_text" defaultValue={globalData.danger_physical_text} onChange={handleChange}  rows={5} />
                         <Form.Text className="text-muted">
                    Περιγραφή Κινδύνου
                         </Form.Text>
                         </Form.Group>
                        </Form.Row>
                        <Form.Row>

                          <Form.Group  as={Col} >
                          <Form.Label>  Επικινδυνότητας Κατασκευής</Form.Label>
                          <Form.Control as="select"  name="danger_construction" id="danger_construction" defaultValue={globalData.danger_construction}>
                          <option value="N/A">N/A</option>
                          <option value="Όχι">Όχι</option>
                          <option value="Ναι">Ναι</option>
                          </Form.Control>
                            <Form.Text className="text-muted">
                        Επικινδυνότητας Κατασκευής
                          </Form.Text>
                          </Form.Group>
                          <Form.Group  as={Col} >
                          <Form.Label> Περιγραφή Κινδύνου</Form.Label>
                          <Form.Control  type="text" name="danger_construction_text" id="danger_construction_text" defaultValue={globalData.danger_construction_text}  onChange={handleChange}  rows={5} />
                          <Form.Text className="text-muted">
                     Περιγραφή Κινδύνου
                          </Form.Text>
                          </Form.Group>
                         </Form.Row>
                         <Form.Row>

                           <Form.Group  as={Col} >
                           <Form.Label>  Πυρκαγιάς</Form.Label>
                           <Form.Control as="select"  name="danger_fire" id="danger_fire" defaultValue={globalData.danger_fire}  >
                           <option value="N/A">N/A</option>
                           <option value="Όχι">Όχι</option>
                           <option value="Ναι">Ναι</option>

                           </Form.Control>
                             <Form.Text className="text-muted">
                        Πυρκαγιάς
                           </Form.Text>
                           </Form.Group>
                           <Form.Group  as={Col} >
                           <Form.Label>Περιγραφή Κινδύνου</Form.Label>
                           <Form.Control  type="text" name="danger_fire_text" id="danger_fire_text"  defaultValue={globalData.danger_fire_text} onChange={handleChange}  rows={5} />
                           <Form.Text className="text-muted">
                      Περιγραφή Κινδύνου
                           </Form.Text>
                           </Form.Group>
                          </Form.Row>
                          <Form.Row>

                            <Form.Group  as={Col} >
                            <Form.Label>  Περιβαντολλογικοί Κίνδυνοι</Form.Label>
                            <Form.Control as="select"  name="danger_environment" id="danger_environment" defaultValue={globalData.danger_environment} >
                            <option value="N/A">N/A</option>
                            <option value="Όχι">Όχι</option>
                            <option value="Ναι">Ναι</option>

                            </Form.Control>
                              <Form.Text className="text-muted">
                          Περιβαντολλογικοί Κίνδυνοι
                            </Form.Text>
                            </Form.Group>
                            <Form.Group  as={Col} >
                            <Form.Label>   Περιγραφή Κινδύνου</Form.Label>
                            <Form.Control  type="text" name="danger_environment_text" id="danger_environment_text" defaultValue={globalData.danger_environment_text} onChange={handleChange}  rows={5} />
                            <Form.Text className="text-muted">
                       Περιγραφή Κινδύνου
                            </Form.Text>
                            </Form.Group>
                           </Form.Row>
                           <Form.Row>

                             <Form.Group  as={Col} >
                             <Form.Label>  Άλλο</Form.Label>
                             <Form.Control as="select"  name="danger_other" id="danger_other" defaultValue={globalData.danger_other} >
                             <option value="N/A">N/A</option>
                             <option value="Όχι">Όχι</option>
                             <option value="Ναι">Ναι</option>

                             </Form.Control>
                               <Form.Text className="text-muted">
                          Άλλο
                             </Form.Text>
                             </Form.Group>
                             <Form.Group  as={Col} >
                             <Form.Label>  Περιγραφή Κινδύνου</Form.Label>
                             <Form.Control  type="text" name="danger_other_text" id="danger_other_text" defaultValue={globalData.danger_other_text}  onChange={handleChange}  rows={5} />
                             <Form.Text className="text-muted">
                        Περιγραφή Κινδύνου
                             </Form.Text>
                             </Form.Group>
                            </Form.Row>

                </div>

               </div>

            </div>
    {/*next*/}


       <div className="col-xl-12 gutter-t">
         <div className={`row card `}>
         <div className="col-xl-12 card-header">
               <h3>ΕΥΡΗΜΑΤΑ ΑΥΤΟΨΙΑΣ 					</h3>
         </div>
           <div className="col-xl-12  card-body">

                 <Form.Group>
                    <Form.Label>Περιγραφή</Form.Label>
                    <Form.Control  as="textarea"  name="audit_comments" id="audit_comments" defaultValue={globalData.audit_comments}  onChange={handleChange}  rows={10 } />
                    <Form.Text className="text-muted">
                  Ευρήματα αυτοψίας
                    </Form.Text>
                  </Form.Group>


            </div>

           </div>

        </div>
        {/*next*/}


           <div className="col-xl-12 gutter-t">
             <div className={`row card `}>
             <div className="col-xl-12 card-header">
                   <h3>O ΣΥΝΤΑΞΑΣ 					</h3>
             </div>
               <div className="col-xl-12  card-body">

                     <Form.Group>
                        <Form.Label>Περιγραφή</Form.Label>
                        <Form.Control  as="textarea"  name="surveyor" id="surveyor" defaultValue={globalData.surveyor} onChange={handleChange}  rows={10 } />
                        <Form.Text className="text-muted">
                        Ο συντάξας
                        </Form.Text>
                      </Form.Group>


                </div>

               </div>

            </div>
{/*next*/}

     </div>
</div>
<div className="row">
  <div className="col-xl-12">
    <div className="row">

      <div className="col-xl-12">

          {data!==null?
          <div>
          <br/>
          {data.ErrorCode!=0?<>
            <Alert severity="error">Πρόβλημα στην ενημέρωση άλλαξε το Collateral Id </Alert>
          </>:

          <Alert>  Επιτυχής ενημέρωση </Alert>
          }
          </div>
          :<></>}
          <br/><br/>
          {loading ?    <Loader
      type="Bars"
      color="#00BFFF"
      height={10}
      width={50}
      />:<>
              <button className="btn btn-light-primary btn-bold">
            Αποθήκευση αλλαγών</button>
               <br/> <br/>
            </>
              }
          <br/>
          </div>
        </div>
      </div>
      </div>
  </Form>
  <br/>
  <div>
    <div className="col-xl-12">
      <div className="row card">

        <div className="col-xl-12 card-header">
              <h3>Καταχώρηση εικόνας</h3>
        </div>
        <div className="col-xl-12 card-body">
        <Form.Group>
          <Form.Label>Τίτλος</Form.Label>
          <Form.Control type="text" name="title" id="title" value={dataTitle} onChange={changeHandler}  placeholder="Enter title" />
          <Form.Text className="text-muted">
            Περιγραφή
          </Form.Text>

        </Form.Group>

        <Form.Group  >
        <Form.Label>  Κατηγορία</Form.Label>
        <Form.Control as="select"  name="select_image_category" onChange={changeHandler}  id="select_image_category">
          <option value="">Γενική</option>
          <option value="isFront">Κεντρική φωτογραφία</option>
          <option value="isMap">Χάρτης</option>
          <option value="isDanger">Κίνδυνος</option>
          <option value="isResolve">Αντιμετώπιση</option>

        </Form.Control>   <Form.Text className="text-muted">

        </Form.Text>
        </Form.Group>


                        <br/><br/>
               <input type="file" name="file" id="file" onChange={changeHandler}/>
               {isSelected ? (
                         <div>
                           <p>Filename: {selectedFile.name}</p>
                           <p>Filetype: {selectedFile.type}</p>
                           <p>Size in bytes: {selectedFile.size}</p>

                         </div>
               ) : (
                 <p>Select a file to show details</p>
               )}

                        {isUploaded ? (
                       <div>
                       <br /><br />
                         <Alert onClose={() => {  setIsUploaded(false); }}>Success</Alert>
                         <br />

                       </div>
                     ) : (
                         isUploadingFile ? (
                           <Loader
                      type="Bars"
                      color="#00BFFF"
                      height={10}
                      width={50}
                      />
                         ) :(
                       <div>

                         <button onClick={handleSubmission}  className="btn btn-light-primary btn-bold">
                          Αποθήκευση  </button>
                       </div>
                      )

                     )}

         </div>
        </div>
    </div>
  </div>
  <br/>
    <ContentInventoryInspection  id={id} key={childKey}></ContentInventoryInspection>
  <br/>
  <button  onClick={ ()=> exportExcel()} className="btn btn-light-info btn-bold">
   Export to CPS Excel</button>
   <br/>  <br/>  <br/>

   <br/><br/>



   </>
  :<Loader
type="Bars"
color="#00BFFF"
height={20}
width={40}
/>}

    </>

  );


}
export default InspectionReportView;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
