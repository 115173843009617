import React from "react";
import { reformForRadarChart, getColor } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";

export const RadarChart1 = ({ height, size = 150 }) => {
  const { json } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);

  const [data, setData] = React.useState(reformForRadarChart(json, "area"));

  React.useEffect(() => {
    setData(reformForRadarChart(json, "area"));
  }, [json]);

  const cuclWidth = () => {
    const w = window.innerWidth;
    if (w > 1400) return 470;
    if (w < 1500 && w > 900) return 450;
    else return 450;
  };

  return (
    <RadarChart
      cx={window.innerWidth > 1300 ? "55%" : "45%"}
      margin={{ bottom: 32 }}
      outerRadius={size}
      width={cuclWidth()}
      height={height}
      data={data}
    >
      <PolarGrid />
      <PolarAngleAxis dataKey="area" />
      <PolarRadiusAxis angle={90} domain={[0, "dataMax"]} />
      <Radar
        name={getLabels().elstat}
        dataKey="elstat"
        stroke={getColor(3)}
        fill={getColor(3)}
        fillOpacity={0.6}
        dot={true}
      />

      <Radar
        name={getLabels().market}
        dataKey="listing"
        stroke={getColor(0)}
        fill={getColor(0)}
        fillOpacity={0.4}
        dot={true}
      />

      <Legend />
      <Tooltip />
    </RadarChart>
  );
};
