/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../_metronic/layout";
import ReactWeather, { useOpenWeather } from 'react-open-weather';
export function WeatherWidgetAthens({ className, symbolShape, baseColor }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);

  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_11_chart");

    if (!element) {
      return;
    }


  }, [layoutProps]);
  const { data, isLoading, errorMessage } = useOpenWeather({
      key: '550fc1aeccb26183cfc990357652682f',
      lat: '37.986556',
      lon: '23.761117',
      lang: 'el',
      unit: 'metric', // values are (metric, standard, imperial)
    });
  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body p-0">
      <ReactWeather
  isLoading={isLoading}
  errorMessage={errorMessage}
  data={data}
  lang="en"
  locationLabel="Athens"
  unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
  showForecast={false}
  />
      </div>
    </div>
  );
}
