import React from "react";
import { reformForBarChart, getColor } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { BarChart, Bar, XAxis, YAxis, Legend, Label } from "recharts";
import { NoData } from "./Shared/NoData";

export const SurfaceBarChart = ({
  width = 730,
  height = 250,
  showLegend = true,
}) => {
  const { json, safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);

  return !!json.overview_area_stock ? (
    <BarChart
      width={width}
      height={height}
      data={reformForBarChart(safelyGetData("overview_area_stock"))}
    >
      <XAxis
        dataKey="name"
        interval={0}
        angle={-60}
        tickSize={25}
        tickLine={false}
        height={70}
      >
        <Label value="m²" offset={2} position="insideBottom" />
      </XAxis>

      <YAxis height={400} tick={false}>
        <Label
          value={showLegend ? getLabels().for_sale : ""}
          angle={-90}
          offset={4}
          position="insideTopCenter"
        />
      </YAxis>

      {showLegend && <Legend verticalAlign="top" align="right" />}

      <Bar dataKey="flats" fill={getColor(3)} name={getLabels().flats} />
      <Bar dataKey="houses" fill={getColor(1)} name={getLabels().houses} />
    </BarChart>
  ) : (
    <NoData />
  );
};
