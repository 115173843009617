import React from "react";
import { ResidentialDashboard } from "./ResidentialDashboard";
import { DataProvider } from "./context/DataContext";
import { LanguageProvider } from "./context/LanguageContext";

export function ResidentialPage(props) {
  return (
    <DataProvider data={props}>
      <LanguageProvider>
        <ResidentialDashboard />
      </LanguageProvider>
    </DataProvider>
  );
}
