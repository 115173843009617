export const reformForRadarChart = (json, key) => {
  if (!["area", "yoc"].includes(key)) {
    return [];
  } else {
    const elstat = JSON.parse(json[`elstat_${key}`][`${key}_elstat`]).data;
    const listings = JSON.parse(json[`elstat_${key}`][`${key}_listings`]).data;

    let data = [];
    for (let i = 0; i < elstat.length; i++) {
      let key = elstat[i];
      data.push({ area: key[0], elstat: key[1], listing: listings[i][1] });
    }
    return data;
  }
};

export const repositionData = (data, steps) => {
  const newData = {};
  for (let i = 0; i < data.length; i++) {
    newData[i + steps] = data[Math.abs(i - data.length + 1)];
  }
  return Object.values(newData);
};
