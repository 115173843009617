import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export function Hometrix_MVA_Clients()  {
    const history = useHistory();
  const sub=useSubheader();
 sub.setTitle("Clients");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [workbook, setWorkbook] = useState(null);

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;

  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,getIdTokenClaims,
   } = useAuth0();
  async function testScopes() {

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });



    return;
  }

  return (
    <>

      <br/>

      <NavLink className="btn btn-light-primary btn-bold" to="/mva/client">
      Νέος Πελάτης
      </NavLink>
      <br/>      <br/>
      <div className={`card card-custom`}>
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
          Πελατολόγιο
          </h3>
          <div className="card-toolbar">

          </div>
        </div>



        <div className="card-body pt-2">




           <div className="d-flex flex-wrap align-items-center mb-10">
            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
              <a
                href="#"
                className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
              >
              Frigoglass
              </a>
              <span className="text-muted font-weight-bold font-size-sm my-1">
              EL239101291
              </span>
              <span className="text-muted font-weight-bold font-size-sm">
                Created by:
                <span className="text-primary font-weight-bold">dimitris@moutsatsos.gr</span>
              </span>
            </div>

            <div className="d-flex align-items-center py-lg-0 py-2">
              <div className="d-flex flex-column text-right">
                <span className="text-dark-75 font-weight-bolder font-size-h4">

                </span>
                <span className="text-muted font-size-sm font-weight-bolder">

                <button
                  className="btn btn-light-info btn-bold">
                 Προβολή </button>

                 &nbsp;&nbsp;
                 <button
                   className="btn btn-light-warning btn-bold">
                  Ελεγχος ΓΕΜΗ </button>

                </span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center mb-10">
           <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
             <a
               href="#"
               className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
             >
             Toshiba
             </a>
             <span className="text-muted font-weight-bold font-size-sm my-1">
             EL23910125
             </span>
             <span className="text-muted font-weight-bold font-size-sm">
               Created by:
               <span className="text-primary font-weight-bold">dimitris@moutsatsos.gr</span>
             </span>
           </div>

           <div className="d-flex align-items-center py-lg-0 py-2">
             <div className="d-flex flex-column text-right">
               <span className="text-dark-75 font-weight-bolder font-size-h4">

               </span>
               <span className="text-muted font-size-sm font-weight-bolder">

               <button
                 className="btn btn-light-info btn-bold">
                Προβολή </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-light-warning btn-bold">
                 Ελεγχος ΓΕΜΗ </button>

               </span>
             </div>
           </div>
         </div>
        </div>
    </div>

</>

  );


}
export default Hometrix_MVA_Clients;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
