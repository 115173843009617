import React from "react";
import { CombinedAreaChart } from "../CombinedAreaChart";
import { DataContext } from "../context/DataContext";
import { NoData } from "../Shared/NoData";

export function CombinedChartTab({ name }) {
  const { json, safelyGetData } = React.useContext(DataContext);

  return (
    <>
      {/* begin::Advance Table Widget 9 */}
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <h3 className="card-title flex-column mt-5 ml-5 mb-0">
          <span className="card-label font-weight-bolder text-dark">
            {name}
          </span>
        </h3>
        <div className="card-header border-0 py-5 table-responsive">
          {!!json.same_region_price ? (
            <CombinedAreaChart
              width={window.innerWidth > 1500 ? 400 : 750}
              data={safelyGetData("same_region_price")}
            />
          ) : (
            <NoData />
          )}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}
