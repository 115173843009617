import React, { createContext, useState,useEffect } from "react";
import { retail_JSONS } from "../assets/data";
import { mergeObjects } from "../utils";
export const DataContext = createContext();

export function DataProvider({ data,children }) {
  async function getContent(data) {

      var apiURL=data.Retail_elstat_kpis;
      const metadataResponse = await fetch(apiURL, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached


      });

      const resp = await metadataResponse.json();
       apiURL=data.Retail_listings_kpis_el;
      const metadataResponse2 = await fetch(apiURL, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached


      });

      const resp2 = await metadataResponse2.json();
      var r=mergeObjects(resp,resp2);
      console.log("all");
      console.log(r);
      setJson(r);




  }
  useEffect(()=>{
    if (data!==undefined&&data.location!==undefined&&data.location.extra!==undefined&&data.location.extra.Retail_elstat_kpis!==undefined) {

     getContent(data.location.extra);
    }

   }, []) // <-- empty dependency array
  const [json, setJson] = useState(retail_JSONS.json_1_gr);
  const [list] = useState(retail_JSONS);

  const safelyGetData = (key) => {
    return !!json[key] ? JSON.parse(json[key]).data : [];
  };

  return (
    <DataContext.Provider value={{ json, setJson, list, safelyGetData }}>
      {children}
    </DataContext.Provider>
  );
}
