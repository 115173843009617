/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { PieChartWithLabel } from "../PieChartWithLabel";
import { DataContext } from "../context/DataContext";
import { reformForPieChart, pickPallete } from "../utils";
import { NoData } from "../Shared/NoData";

export function PieChart1() {
  const { json, safelyGetData } = React.useContext(DataContext);

  const data = safelyGetData("overview_build_status");

  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body">
        {json.overview_build_status ? (
          <PieChartWithLabel
            palette={
              data.length > 3 ? pickPallete(data).reverse() : pickPallete(data) // ik ik, it just works, stfu
            }
            height={140}
            showLegend={true}
            radius={70}
            data={reformForPieChart(data).filter((o) => o.value > 0)}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
