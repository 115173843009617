import React from "react";
import { multiColor, reformForMunic4 } from "./utils";
import { DataContext } from "./context/DataContext";
import { BarChart, Bar, XAxis, YAxis, Legend, Cell } from "recharts";

export const MunicipalityBarChart2 = ({
  width = 730,
  height = 550,
  showLegend = true,
}) => {
  const { safelyGetData } = React.useContext(DataContext);
  const data = reformForMunic4(safelyGetData("neighb_combinatorial"), 1, 4);

  const genTicks = (data) => {
    const MAX = Math.round(Math.max(...data.map((d) => d.yoc)) + 5);
    let ticks = [];
    for (let i = 0; i <= MAX; ) {
      ticks.push(i);
      i += 5;
    }
    return ticks;
  };

  return (
    <BarChart width={width} height={height} data={data}>
      <XAxis
        dataKey="name"
        interval={data.length > 3 ? 0 : ""}
        angle={data.length > 3 ? -80 : 0}
        tickSize={data.length > 3 ? 10 : 3}
        tickMargin={data.length > 3 ? 40 : 5}
        tickLine={true}
        height={data.length > 3 ? 100 : 30}
      />
      <YAxis
        height={400}
        type="number"
        tickCount={9}
        domain={[0, "dataMax"]}
        ticks={genTicks(data)}
      ></YAxis>
      {showLegend && <Legend />}

      <Bar dataKey="yoc">
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={multiColor(index)} />
        ))}
      </Bar>
    </BarChart>
  );
};
