import { mergeObjects } from "../../utils";

const init_ls = require("./listings_kpis.json");
const init_els = require("./elstat_kpis_el.json");

const gr_1_ls = require("./GR/listings_kpis_el_1.json");
const gr_1_els = require("./GR/elstat_kpis_el_1.json");

const gr_2_ls = require("./GR/listings_kpis_el_2.json");
const gr_2_els = require("./GR/elstat_kpis_el_2.json");

const gr_3_ls = require("./GR/listings_kpis_el_3.json");
const gr_3_els = require("./GR/elstat_kpis_el_3.json");

const en_1_ls = require("./ENG/listings_kpis_1.json");
const en_1_els = require("./ENG/elstat_kpis_1.json");

const en_2_ls = require("./ENG/listings_kpis_2.json");
const en_2_els = require("./ENG/elstat_kpis_2.json");

const en_3_ls = require("./ENG/listings_kpis_3.json");
const en_3_els = require("./ENG/elstat_kpis_3.json");

export const JSONS = {
  json_init: mergeObjects(init_ls, init_els),
  json_1_gr: mergeObjects(gr_1_ls, gr_1_els),
  json_2_gr: mergeObjects(gr_2_ls, gr_2_els),
  json_3_gr: mergeObjects(gr_3_ls, gr_3_els),

  json_1_eng: mergeObjects(en_1_ls, en_1_els),
  json_2_eng: mergeObjects(en_2_ls, en_2_els),
  json_3_eng: mergeObjects(en_3_ls, en_3_els),
};
