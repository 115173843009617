import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Label,
  Cell,
} from "recharts";
import { generateYearTicks, greyOutV2 } from "./utils";
import { DataContext } from "./context/DataContext";

export const VerticalBarChart3 = ({
  data,
  width = 600,
  height = 700,
  margin = { top: 0, bottom: 0, left: 0, right: 0 },
  axis = "left",
  showLegend = true,
  label = "",
  barSize = 10,
  showTicks = true,
  domain = ["dataMin-10", "dataMax + 10"],
}) => {
  const { json } = React.useContext(DataContext);

  return (
    // increasing the height of the ComposedChart will provide more areas
    <ComposedChart
      layout="vertical"
      width={width}
      height={height}
      data={data.reverse()}
      margin={{ ...margin }}
    >
      <XAxis
        type="number"
        dataKey="year"
        ticks={generateYearTicks(data, "year")}
        tick={showTicks}
        tickCount={10}
        domain={domain}
      >
        <Label
          value={!!label.length && label}
          position="insideBottom"
          offset={2}
        />
      </XAxis>

      <YAxis
        dataKey="name"
        type="category"
        interval={0}
        width={400}
        reversed={true}
        orientation={axis}
      />

      {showLegend ? <Legend /> : null}
      <Bar dataKey="year" barSize={barSize}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={greyOutV2(entry, json)} />
        ))}
      </Bar>
    </ComposedChart>
  );
};
