const generateMockDataForMinAndMaxValues = (min, max) => {
  let mockData = [];

  for (let i = 0; i < max; ) {
    mockData.push({ price: i });
    i += 500;
  }

  mockData.push({ price: max });

  for (let i = max - 1000; i > min; ) {
    mockData.push({ price: i });
    i -= 400;
  }
  mockData.push({ price: min });
  mockData.push({ price: 0 });
  return mockData;
};

const reformForAreaChart = (data) => {
  return data.map((o) => ({ name: o[0], prices: { max: o[1], min: o[2] } }));
};

export const formAreaChart = (data) => {
  const parsed = reformForAreaChart(data);
  // injecting mock data for smoother lines
  return parsed.map((o) => ({
    ...o,
    mockData: generateMockDataForMinAndMaxValues(o.prices.min, o.prices.max),
  }));
};
