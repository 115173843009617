/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { LanguageContext } from "../context/LanguageContext";
import { DataContext } from "../context/DataContext";
import { VerticalBarChart2 } from "../Shared/VerticalBarChart2";
import { getWidth } from "../utils";
import { AsteriscSubnote } from "../Shared/AsteriscSubnote";
import { NoData } from "../Shared/NoData";

export function MedianPriceTab({ name }) {
  const { getLabels } = React.useContext(LanguageContext);
  const { json } = React.useContext(DataContext);

  const culcLeftMargin = () => {
    return window.innerWidth < 500 ? "1%" : "10%";
  };

  return (
    <>
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">{name}</h3>
        </div>
        {/* Body */}
        {!!json.same_region_price ? (
          <div
            className="card-body pt-0"
            style={{ marginLeft: culcLeftMargin() }}
          >
            <div className="mb-10 table-responsive">
              <VerticalBarChart2
                margin={{ right: -100 }}
                height={580}
                width={getWidth(650, 900)}
                barSize={20}
                axis="right"
                showLegend={false}
                label={getLabels().median_price}
                xAxisHeight={40}
              />
            </div>

            <AsteriscSubnote target="same_region_price" />
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
}
