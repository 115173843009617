import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete,Marker } from '@react-google-maps/api';
import { Form,Button,Col } from 'react-bootstrap';
import { TextField,makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import { ContentAVM } from './ContentAVM';
var QRCode = require('qrcode.react');
const containerStyle = {
  width: '100%',
  height: '400px'
};
const libraries = ["places","geometry"];
const useStyles = makeStyles({
  margin_r: {
    margin: '0px 5px 0px 0px',
  },

});

export function AVM_AssetView(props)  {

  const id=props.match.params.id;
  const [globalLoad, setGlobalLoad] = useState(true);
  const classes = useStyles();
  const [qrData, setQrData] = useState(0);
  const [formData, updateFormData] = useState("");
  const [globalData, setGlobalData] = useState(null);
  const [imageData, setImageData] = useState({});
  const [geoValues, setGeoValues] = useState(false);
  const [calculationData,setCalculationData]=useState(null);
  const [dcf, setDcf] = useState(null);
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();

  const [openARY, setOpenARY] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openLease, setOpenLease] = useState(false);
  const [openExp, setOpenExp] = useState(false);
  const [openDeko, setOpenDeko] = useState(false);


  const [cat1, setCat1] = useState(false);
  const [cat2, setCat2] = useState(false);
  const [cat3, setCat3] = useState(false);
  const [cat4, setCat4] = useState(false);
  const [cat5, setCat5] = useState(false);
  const [cat6, setCat6] = useState(false);
  const [cat7, setCat7] = useState(false);
  const [cat8, setCat8] = useState(false);
  const [cat9, setCat9] = useState(false);
  const [cat10, setCat10] = useState(false);
  const [cat11, setCat11] = useState(false);
  const [cat12, setCat12] = useState(false);

   async function getContentImages() {
return;

      setImageData({});

     const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
        apiURL+="?action=getContentAVM&id="+id;
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      body:data,
         headers: {

           'authorization': 'Bearer '+accessToken,
         },
       });

       const resp = await metadataResponse.json();
       console.log(resp);
       setImageData(resp.results);

   }

   async function getGeoValues(lt,lg) {





        var apiURL = "https://hometrix.eu/console/geo/json2.php?";
        apiURL+="Lng="+lg+"&Lat="+lt;
        console.log(apiURL);
       const metadataResponse = await fetch(apiURL, {
         method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       });

       const resp = await metadataResponse.json();
       console.log(resp);
       setGeoValues(resp);

   }


   async function getQR(data) {
     var a=qrData;
     a++;
     if (a>2) a=0;
     setQrData(a);


     return;
   }
   async function getContent() {

     setGlobalLoad(true);
      setData(null);

     const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        });
        var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
        apiURL+="?action=getAVM&id="+id;
       const metadataResponse = await fetch(apiURL, {
         method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      body:data,
         headers: {

           'authorization': 'Bearer '+accessToken,
         },
       });

       const resp = await metadataResponse.json();
       var d=resp.results[0].data;


    //   console.log("---DATA---");
      // console.log(globalData)

        setLng(d.lng);
        setLat(d.lat);
        setDistrict(d.district);
        setPrefecture(d.prefecture);
        setPostalCode(d.postalCode);
        setMunicipality(d.municipality);
        setAddress(d.address);
        setGlobalData(d);
        setGlobalLoad(false);
      //  afterLoad();
   }
   React.useEffect(() => {
   if (globalData!==null) {
     afterLoad();
   }
 }, [globalData]);
   useEffect(()=>{
      getContent()
      getContentImages();



    }, []) // <-- empty dependency array
    const [selectedFile, setSelectedFile] = useState();
    const [childKey, setChildKey] = useState(0);
    const [dataTitle, setDataTitle] = useState();
  	const [isSelected, setIsSelected] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const [isDanger, setIsDanger] = useState(false);
    const [isFront, setIsFront] = useState(false);
    const [isMap, setIsMap] = useState(false);

      const [isResolve, setIsResolve] = useState(false);

    async function handleSubmission(category) {
      const data = new FormData();
       setIsUploadingFile(true);
      data.append('File', selectedFile);
      data.append('dataTitle',dataTitle);

      const accessToken = await getAccessTokenSilently({
           audience: process.env.REACT_APP_AUTH0_AUDIENCE
         });
         var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
         apiURL+="?action=uploadAVMContent&id="+id+"&category="+category;
        const metadataResponse = await fetch(apiURL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
       mode: 'cors', // no-cors, *cors, same-origin
       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
       body:data,
       credentials: 'include',
          headers: {

            'authorization': 'Bearer '+accessToken,
          },
        });

        const resp = await metadataResponse.json();
      //  console.log(resp);
        setIsSelected(false);
        setIsUploaded(true);
        setIsUploadingFile(false);
        var i=childKey;
        i++;
        setChildKey(i);
    //  getContentImages();
      setGlobalData(data);

    };
  async function onSubmit(event) {
         event.preventDefault();
       const dat = new FormData(event.target);

    //   console.log(data);
//console.log(data.get('collateralId'));
    //   console.log(data.get('lat'));
    if (dcf!=null) {
      dat.set('DCF', JSON.stringify(dcf));
    }
     setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=updateAVM&id="+id;
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:dat,
     credentials: 'include',
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
    //  console.log(resp);
      setData(resp);
     setLoading(false);
     exportCalc();
  }
  function getDt(dt) {
    var d = new Date(dt);
    var new_dt= d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

    return(new_dt);
  }
  function getNextDt(dt) {
    var d = new Date(dt);
    d.setDate(d.getDate()+1);
    var new_dt= d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

    return(new_dt);
  }
   function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
}
  function timeout(delay: number) {
      return new Promise( res => setTimeout(res, delay) );
  }
  async function exportCalc() {

    if (globalData.DCF===undefined||globalData.DCF===null) return;
    setCalculationData(null);
    await timeout(1000);
    // GAPCosts
    var results={};
    results['GAPcosts']=globalData.generic_expenses*globalData.leaseTotalSQM*globalData.lease_empty;

/*    results['AgentCost']=globalData.agency_expenses*(globalData.lease_price*12);
    results['OpexCost']=globalData.operating_expenses*(globalData.lease_price*12*globalData.ary_yearly_adjust);
    //needs fix
    results['CapexCost']=globalData.renovation_expenses*(globalData.lease_price*12*globalData.ary_yearly_adjust);
    //needs fix
    */


    var dateObject_lease_start=new Date();
    if (globalData.lease_start!="") {
      dateObject_lease_start = new Date(globalData.lease_start);
    }

    var dateObject_lease_end=new Date();
    if (globalData.lease_end!="") {
      dateObject_lease_end = new Date(globalData.lease_end);
    }
    var dateObject_lease_revaluate=new Date();
    if (globalData.lease_revaluate!="") {

      dateObject_lease_revaluate = new Date(globalData.lease_revaluate);
    }
    var dateObject_lease_readjustment=new Date();
    if (globalData.lease_readjustment!="") {

      dateObject_lease_readjustment = new Date(globalData.lease_readjustment);
    }
    var dateObject_lease_start_dcf=new Date(dateObject_lease_revaluate.toISOString());
    dateObject_lease_start_dcf.setDate(dateObject_lease_start_dcf.getDate()+1);

    var dateObject_lease_end_dcf=new Date(dateObject_lease_start_dcf.toISOString());
    dateObject_lease_end_dcf.setFullYear(dateObject_lease_start_dcf.getFullYear()+10);
    dateObject_lease_end_dcf.setDate(dateObject_lease_end_dcf.getDate()-1);

    var dateObject_lease_end_dcf_for_exit=new Date(dateObject_lease_start_dcf.toISOString());
    dateObject_lease_end_dcf_for_exit.setFullYear(dateObject_lease_start_dcf.getFullYear()+11);
    dateObject_lease_end_dcf_for_exit.setDate(dateObject_lease_end_dcf_for_exit.getDate()-1);

    var dateObject_lease_total_months=monthDiff(dateObject_lease_start_dcf,dateObject_lease_end_dcf_for_exit)+1;
    //begin CALCUATIONS
    var dateObject_calc=new Date(dateObject_lease_start_dcf.toISOString());
    var price=globalData.lease_price;
    var market_price=globalData.lease_market_price*globalData.leaseTotalSQM;
    var readj=-1;
    results['calc']=[];
    var lastYear=dateObject_lease_start_dcf.getFullYear();
    var totalValue=0;
    var totalValueByYear=0;
    var totalValueByYears=[];

    var operating_costs=[];
    var capex_costs=[];
    var void_costs=[];
    var agent_costs=[];
    var renovation_costs=[];
    var discount_factor=[];
    var discount_factor_ary=parseFloat(globalData.ary)+parseFloat(globalData.ary_yearly_adjust)+parseFloat(globalData.ary_yearly_deprecation)+parseFloat(globalData.ary_ypervalon_percentage)+parseFloat(globalData.ary_risk_premium)+parseFloat(globalData.ary_fer_percentage);

    for(var i=1;i<=dateObject_lease_total_months;i++) {

      var row={};
      if (readj>0) readj++;
      if (readj==-1) {
        if (monthDiff(dateObject_lease_readjustment,dateObject_calc)==0) {
           // needs re Adjustmen
           price=price*(1+(globalData.ary_yearly_adjust/100)); readj=1;

        }
      }
      if (readj==13) {
          price=price*(1+(globalData.ary_yearly_adjust/100)); readj=1;

      }
      if (lastYear!=dateObject_calc.getFullYear()) {
        totalValueByYears[lastYear]=totalValueByYear;
        operating_costs[lastYear]=totalValueByYear*(globalData.operating_expenses/100);
        capex_costs[lastYear]=totalValueByYear*(globalData.capex/100);
        var cur=lastYear-dateObject_lease_start_dcf.getFullYear();
        cur++;

        var c;
        c=Math.pow((1+(discount_factor_ary/100)),cur-0.5);

        discount_factor[lastYear]=1/c;

        totalValueByYear=0;

        var lastYear=dateObject_calc.getFullYear();
        market_price=market_price*(1+(globalData.ary_yearly_adjust/100));


      }
      row['Dt']=dateObject_calc.toISOString();

      row['LeaseValue']=price;
      row['MarketValue']=market_price;
      var m=monthDiff(dateObject_calc,dateObject_lease_end)
      if (m>=0) {
        row['Value']=price;
      } else {
          if (Math.abs(m)>globalData.lease_empty) {
          row['Value']=market_price;

        } else {
          row['Value']=0;
          if (void_costs[lastYear]===undefined||void_costs[lastYear]==0) {
            void_costs[lastYear]=0;
          }
          void_costs[lastYear]+=globalData.generic_expenses*globalData.leaseTotalSQM;

          if (agent_costs[lastYear]===undefined||agent_costs[lastYear]==0) {
            agent_costs[lastYear]=0;
          }
          var prevLastYear=lastYear-1;

          if (agent_costs[prevLastYear]===undefined||agent_costs[prevLastYear]==0) {
            agent_costs[prevLastYear]=0;
          }
          if (agent_costs[prevLastYear]==0&&agent_costs[lastYear]==0) {
            agent_costs[lastYear]=market_price*12*(globalData.agency_expenses/100);
          }


          if (renovation_costs[lastYear]===undefined||renovation_costs[lastYear]==0) {
            renovation_costs[lastYear]=0;
          }
          var prevLastYear=lastYear-1;

          if (renovation_costs[prevLastYear]===undefined||renovation_costs[prevLastYear]==0) {
            renovation_costs[prevLastYear]=0;
          }
          if (renovation_costs[prevLastYear]==0&&renovation_costs[lastYear]==0) {
            renovation_costs[lastYear]=globalData.renovation_expenses*globalData.leaseTotalSQM;
          }

        }
      }
      if (globalData.lease_status==0) {
        row['Value']=market_price;
        row['LeaseValue']=0;
      }
      totalValue+=row['Value'];
      totalValueByYear+=row['Value'];
      results['calc'].push(row);
        dateObject_calc.setMonth(dateObject_calc.getMonth()+1);
    }

    operating_costs[lastYear]=totalValueByYear*(globalData.operating_expenses/100);
    capex_costs[lastYear]=totalValueByYear*(globalData.capex/100);

    var exit_revenue=totalValueByYear/(globalData.ary_exit_yield/100);
    var exit_costs=totalValueByYear*(globalData.ary_exit_costs/100);

    results['exit_costs']=exit_costs;
    results['exit_revenue']=exit_revenue;
    results['discount_factor']=discount_factor;
    results['agent_costs']=agent_costs;
    results['renovation_costs']=renovation_costs;
    results['void_costs']=void_costs;
    results['operating_costs']=operating_costs;
    results['capex_costs']=capex_costs;
    results['lease_start']=dateObject_lease_start;
    results['lease_end']=dateObject_lease_end;
    results['lease_revaluate']=dateObject_lease_revaluate;
    results['lease_readjustment']=dateObject_lease_readjustment;
    results['start_dcf']=dateObject_lease_start_dcf;
    results['end_dcf']=dateObject_lease_end_dcf;
    results['end_dcf_exit']=dateObject_lease_end_dcf_for_exit;
    results['total_months']=dateObject_lease_total_months;
    results['total_value']=totalValue-totalValueByYear;
    results['total_value_years']=totalValueByYears;
    results['total_value_for_exit']=totalValueByYear;
    var tocalCashFlowPerYear=[];
    var netPresentValue=0;
    var cc=0;
    for (var year in totalValueByYears) {
        cc++;
        var value=totalValueByYears[year];
        if (operating_costs[year]!==undefined)
          value-=operating_costs[year];
        if (renovation_costs[year]!==undefined)
          value-=renovation_costs[year];
        if (agent_costs[year]!==undefined)
          value-=agent_costs[year];
        if (void_costs[year]!==undefined)
            value-=void_costs[year];
        if (capex_costs[year]!==undefined)
           value-=capex_costs[year];
      if (cc==10) {
        value+=exit_revenue;
        value-=exit_costs;
      }
      tocalCashFlowPerYear[year]=value;
      netPresentValue+=value;

    }
    var NPVtocalCashFlowPerYear=[];
    var NPVnetPresentValue=0;
    var cc=0;
    for (var year in totalValueByYears) {
        cc++;
        var value=totalValueByYears[year];
        if (operating_costs[year]!==undefined)
          value-=operating_costs[year];
        if (renovation_costs[year]!==undefined)
          value-=renovation_costs[year];
        if (agent_costs[year]!==undefined)
          value-=agent_costs[year];
        if (void_costs[year]!==undefined)
            value-=void_costs[year];
        if (capex_costs[year]!==undefined)
           value-=capex_costs[year];
      if (cc==10) {
        value+=exit_revenue;
        value-=exit_costs;
      }
      value=value*discount_factor[year];
      NPVtocalCashFlowPerYear[year]=value;
      NPVnetPresentValue+=value;

    }

    // sums

    results['tocalCashFlowPerYear']=tocalCashFlowPerYear;
    results['netPresentValue']=netPresentValue;
    results['NPVtocalCashFlowPerYear']=NPVtocalCashFlowPerYear;
    results['NPVnetPresentValue']=NPVnetPresentValue;



    results['ARY']=globalData.ary*globalData.lease_price;
    /*var getMonths=monthDiff(globalData.lease_start,globalData.lease_end);
    results['Months']=getMonths;
*/
    //setCalculationData(JSON.stringify(results));
    console.log(results);
    setDcf(results);
    var final;
    final=Math.ceil(NPVnetPresentValue/1000)*1000;
    setCalculationData(final.toLocaleString("el-GR"));
    return;

  }
  async function exportExcel() {

     let bl = await fetch('/templates/mva_template_v1.xlsx').then(r => r.blob());

     var dl=await bl.arrayBuffer();
     var data = new Uint8Array(dl);

     const wb = new Excel.Workbook();
     await wb.xlsx.load(data);



     var worksheet = wb.getWorksheet('Tenancy_Details');
     worksheet.getCell('B8').value = globalData.leaser_name;

     worksheet.getCell('D8').value = getDt(globalData.lease_start);
   worksheet.getCell('H8').value = getDt(globalData.lease_revaluate);
     worksheet.getCell('E8').value =getDt(globalData.lease_end);
     worksheet.getCell('J8').value =getNextDt(globalData.lease_end);
if (isNaN(parseInt(globalData.lease_empty))) {
worksheet.getCell('G8').value =0;
} else {
  worksheet.getCell('G8').value =parseInt(globalData.lease_empty);
}


worksheet.getCell('T8').value = globalData.lease_price;
if (globalData.lease_status=="1") {
  worksheet.getCell('C8').value = "-3";
} else if (globalData.lease_status=="0") {
  worksheet.getCell('C8').value = "-2";
} else {
  worksheet.getCell('C8').value = globalData.lease_status;
}
worksheet.getCell('C8').value = globalData.lease_status;
worksheet.getCell('Q8').value = globalData.lease_type;
worksheet.getCell('R8').value = globalData.leaseSQM;
worksheet.getCell('S8').value = globalData.leaseTotalSQM;
worksheet.getCell('W6').value = globalData.leaseYearly+"%";

worksheet.getCell('AC6').value = globalData.generic_expenses;
worksheet.getCell('AE6').value = globalData.agency_expenses;
worksheet.getCell('AG6').value = globalData.renovation_expenses;

    // Images
    var counter=0;
    var counterD=0;
    var counterR=0;
    var where="";




    const buffer = await wb.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], {type: fileType});

    saveAs(blob, 'MVA_'+globalData['collateralId'] + fileExtension);


    return;

  }


  const onLoad = () => {};
  const [map, setMap] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [district,setDistrict]=useState("");
  const [address,setAddress]=useState("");
  const [prefecture,setPrefecture]=useState("");
  const [googleAddress, setGoogleAddress] = useState("");
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  var places;


  var place=null;

  var center = {
    lat: 38.0572437,
    lng: 23.8481676
  };
  function afterLoad() {

      if (globalData.DCF!==null) exportCalc();

  }
  function onloadMap(ref){
    setMap(ref);

  }
  function onLoadMarker(ref) {
    setMarker(ref);
    ref.setPosition(new window.google.maps.LatLng( lat, lng ) );

  }
  function onSearchBoxMounted(ref) {
      setAutocomplete(ref);
  }
  function onDragEnd(e) {
  //  console.log("onPositionChanged");

    //map.panTo(new window.google.maps.LatLng( marker.getPosition().lat(), marker.getPosition().lng() ));
    //center.lat=marker.getPosition().lat();
  //  center.lng=marker.getPosition().lng();
      setLat(marker.getPosition().lat());
      setLng(marker.getPosition().lng());


    geocodePosition(marker.getPosition());
//    setLat(place.geometry.location.lat());
//    setLng(place.geometry.location.lng());
  }
  function geocodePosition(pos)  {



  var  geocoder = new window.google.maps.Geocoder();
   geocoder.geocode
    ({
        latLng: pos
    },
        function(results, status)
        {
            if (status ==window.google.maps.GeocoderStatus.OK)
            {


              updateAddress(results);
            }
            else
            {
            	//  setAddress(marker.getPosition().lat()+","+marker.getPosition().lng());


            }
        }
    );

	}
  function updateAddress(results) {
    setAddress(results[0].formatted_address);
      Object.keys(results[0].address_components).forEach(function(key) {
        var result=results[0].address_components[key];
        if (result.types.indexOf("locality") > -1) {
          setMunicipality(result.long_name);
        }
        if (result.types.indexOf("postal_code") > -1) {
          setPostalCode(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_32") > -1) {
          setDistrict(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_3") > -1) {
          setPrefecture(result.long_name);
        }

      });
  }
  function onPlacesChanged() {
  //  console.log('autocomplete: ', autocomplete)
//    console.log(searchBox.getPlaces());
      if (autocomplete !== null) {
    //   console.log(autocomplete.getPlace())
      place=autocomplete.getPlace();


      //setGoogleAddress(place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      center.lat=place.geometry.location.lat();
      center.lng=place.geometry.location.lng();

      marker.setPosition(new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );

      map.panTo( new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );
      geocodePosition(marker.getPosition());

      } else {
       console.log('Autocomplete is not loaded yet!')
      }
  }
 const [value, setValue] = useState('Hello World');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const changeHandler = (e) => {
    if (e.target.id=='file') {
        setIsUploaded(false);

  		setSelectedFile(e.target.files[0]);
  		setIsSelected(true);
    }
      if (e.target.id=='title') {
        setDataTitle(e.target.value);
      }
      if (e.target.id=="select_image_category") {

        setIsMap(false);
        setIsDanger(false);
        setIsFront(false);
        setIsResolve(false);
        if (e.target.value=="isDanger") setIsDanger(true);
        if (e.target.value=="isMap") setIsMap(true);
        if (e.target.value=="isFront") setIsFront(true);
        if (e.target.value=="isResolve") setIsResolve(true);

      }


  	};
  function handleChange (e) {

    if (e.target.id=='lat') {
      setLat(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    if (e.target.id=='lng') {
      setLng(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    var x=globalData;
    x[e.target.id]=e.target.value;
    setGlobalData(x);


 }


  return (

    <>
    {globalLoad!=true?
      <>
      <Form onSubmit={onSubmit}>
    <div className="row">
      <div className="col-xl-12">
        <div className={`row card `}>
        <div className="col-xl-12 card-header">
              <h3>ΣΤΟΙΧΕΙΑ  ΑΚΙΝΗΤΟΥ </h3>
        </div>
          <div className="col-xl-12  card-body">
              <Form.Group>
                <Form.Label>Μοναδικός κωδικός εγγραφής</Form.Label>
                <Form.Control type="text" name="collateralId" id="collateralId" value={globalData.collateralId} onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Μοναδικός κωδικός εγγραφής (<strong>δεν επιτρέπεται η αλλαγή</strong>)
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Κωδικός ακινήτου</Form.Label>
                <Form.Control type="text" name="propertyID" id="propertyID" defaultValue={globalData.propertyID} onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                 Κωδικός ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>  Α/Α Ακινήτου</Form.Label>
                <Form.Control type="text" name="uniqueId" id="uniqueId" defaultValue={globalData.uniqueId}  onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Α/Α Ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>  ΚΑΕΚ Ακινήτου</Form.Label>
                <Form.Control type="text" name="kaek" id="kaek" defaultValue={globalData.kaek}  onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
              ΚΑΕΚ Ακινήτου
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>  Ιδιοκτήτης Ακινήτου</Form.Label>
                <Form.Control type="text" name="owner" id="owner" defaultValue={globalData.owner}  onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                  Ιδιοκτήτης Ακινήτου  (CID)
                </Form.Text>
              </Form.Group>
              <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>  Ετος Κατασκευής</Form.Label>
                <Form.Control type="text" name="year_of_construction" id="year_of_construction" value={globalData.year_of_construction} onChange={handleChange} placeholder="" />
                <Form.Text className="text-muted">

                </Form.Text>
              </Form.Group>
              <Col xs={2}>
              <Form.Group>
                <Form.Label>  Ετος Ανακαίνισης</Form.Label>
                <Form.Control type="text" name="year_of_renovation" id="year_of_renovation" value={globalData.year_of_renovation}  onChange={handleChange} placeholder="" />
                <Form.Text className="text-muted">

                </Form.Text>
              </Form.Group>
              </Col>
              </Form.Row>
              <Form.Group>
                 <Form.Label>Περιγραφή ακινήτου</Form.Label>
                 <Form.Control  as="textarea"  name="description" id="description" defaultValue={globalData.description}  onChange={handleChange}  rows={10 } />
                 <Form.Text className="text-muted">
                  Περιγραφή ακινήτου
                 </Form.Text>
               </Form.Group>


          </div>

    </div>

  </div>

</div>

<div className="row gutter-t">


    <div className="col-xl-12 gutter-t">
      <div className="row card">
      <div className="col-xl-12 card-header">
            <h3> <button  onClick={(e) => { e.preventDefault(); setOpenLocation(!openLocation)}} class="btn btn-icon btn-sm btn-light-primary mr-1">
              <i class="ki ki-arrow-down icon-nm"></i>
              </button> ΤΟΠΟΘΕΣΙΑ ΑΚΙΝΗΤΟΥ 					 </h3>
      </div>
        <div className="col-xl-12 card-body" style={{ display: openLocation ? "block" : "none" }}>

              <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
                  libraries={libraries}
                >
                  <GoogleMap
                    onLoad={onloadMap}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                  >
                    { /* Child components, such as markers, info windows, etc. */ }
                    <Autocomplete
                  onLoad={onSearchBoxMounted}
                  onPlaceChanged={onPlacesChanged}
                  onPlacesChanged={onPlacesChanged}
                  onFail={error => console.error(error)}

                >
                  <input

                    type="text"
                    placeholder=" Εύρεση απο Google"
                    style={{
                      boxSizing: `border-box`,
                      border: `2px solid #E4E6EF`,
                      width: `100%`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: '0.42rem',
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      bottom:"0px",
                      marginLeft: "-50%"

                    }}
                  />
                </Autocomplete>

                  <Marker
                      onLoad={onLoadMarker}
                      draggable={true}
                      onDragEnd={onDragEnd}
                 />



                  </GoogleMap>
                </LoadScript>

      {/* end: MAP */}

                <Form.Group className="gutter-t">
                  <Form.Label>  Γεωγραφικό πλάτος (Latitude)</Form.Label>
                  <Form.Control type="text"  name="lat" value={lat}  onChange={handleChange} id="lat" placeholder="" />
                  <Form.Text className="text-muted">
                  Γεωγραφικό πλάτος (Latitude)
                  </Form.Text>
                </Form.Group>

                  <Form.Group>
                    <Form.Label>  Γεωγραφικό μήκος (Longtitude)</Form.Label>
                    <Form.Control type="text" name="lng" id="lng"  value={lng}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Γεωγραφικό μήκος (Longtitude)
                    </Form.Text>
                  </Form.Group>


                  <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>  Διεύθυνση</Form.Label>
                    <Form.Control type="text" name="address" id="address" value={address} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Διεύθυνση
                    </Form.Text>
                  </Form.Group>
                  <Col xs={2}>
                  <Form.Group>
                    <Form.Label>    ΤΚ</Form.Label>
                    <Form.Control type="text" name="postalCode" id="postalCode" value={postalCode}   onChange={handleChange}  placeholder="" />
                    <Form.Text className="text-muted">
                    ΤΚ
                    </Form.Text>
                  </Form.Group>
                  </Col>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label>  Δήμος</Form.Label>
                    <Form.Control type="text" name="municipality" id="municipality"  value={municipality}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δήμος
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>  Δημοτική ενότητα</Form.Label>
                    <Form.Control type="text" name="district" id="district"    value={district}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δημοτική ενότητα
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>  Περιφέρεια</Form.Label>
                    <Form.Control type="text" name="prefecture" id="prefecture"  value={prefecture} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Περιφέρεια
                    </Form.Text>
                  </Form.Group>
                    <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>  Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού</Form.Label>
                    <Form.Control type="text" name="plotNumber" id="plotNumber" defaultValue={globalData.plotNumber}    onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού
                    </Form.Text>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Τοπωνύμιο</Form.Label>
                    <Form.Control type="text" name="toponym" id="toponym"   defaultValue={globalData.toponym}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Τοπωνύμιο
                    </Form.Text>
                  </Form.Group>
                    </Form.Row>

          </div>
          </div>
  </div>
  <div className="col-xl-12 gutter-t">
    <div className={`row card `}>
    <div className="col-xl-12 card-header">
          <h3> <button  onClick={(e) => { e.preventDefault(); setOpenLease(!openLease)}} class="btn btn-icon btn-sm btn-light-primary mr-1">
            <i class="ki ki-arrow-down icon-nm"></i>
            </button> ΜΙΣΘΩΣΕΙΣ 					</h3>
    </div>
      <div className="col-xl-12  card-body" style={{ display: openLease ? "block" : "none" }}>
            <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label>  Επωνυμία Μισθωτή</Form.Label>
              <Form.Control  type="text" name="leaser_name" id="leaser_name" defaultValue={globalData.leaser_name}  onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              Επωνυμία Μισθωτή
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  ΑΦΜ Μισθωτή</Form.Label>
              <Form.Control  type="text" name="leaser_vat" id="leaser_vat" defaultValue={globalData.leaser_vat}  onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              ΑΦΜ Μισθωτή
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Τηλέφωνο μισθωτή</Form.Label>
              <Form.Control  type="text" name="leaser_contact" id="leaser_contact"  defaultValue={globalData.leaser_contact} onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              Τηλέφωνο μισθωτή
              </Form.Text>
              </Form.Group>

                      <Form.Group  as={Col} >
                      <Form.Label>Κατάσταση</Form.Label>
                      <Form.Control as="select"  name="lease_status"  defaultValue={globalData.lease_status} onChange={handleChange} id="lease_status">
                        <option value="0">Κενό</option>
                        <option value="1">Μισθωμένο</option>

                      </Form.Control>
                      </Form.Group>
            </Form.Row>
            <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label>  Έναρξη Μίσθωσης</Form.Label>
              <Form.Control  type="date" name="lease_start" id="lease_start"  defaultValue={globalData.lease_start}   onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
            Έναρξη Μίσθωσης
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Λήξη Μίσθωσης</Form.Label>
              <Form.Control  type="date" name="lease_end" id="lease_end" defaultValue={globalData.lease_end}  onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
            Λήξη Μίσθωσης
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Ημερομηνία Εκτίμησης</Form.Label>
              <Form.Control  type="date" name="lease_revaluate" id="lease_revaluate" defaultValue={globalData.lease_revaluate}  onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
          Κρίσιμη Ημερομηνία Εκτίμησης
              </Form.Text>
              </Form.Group>


                <Form.Group  as={Col} >
                <Form.Label>  Ημερομηνία Αναπροσαρμογής</Form.Label>
                <Form.Control  type="date" name="lease_readjustment" id="lease_readjustment" defaultValue={globalData.lease_readjustment}  onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
          Ημερομηνία Επόμενης Αναπροσαρμογής
                </Form.Text>
                </Form.Group>
                  </Form.Row>
              <Form.Row>
              <Form.Group  as={Col} >
              <Form.Label> Κενή Περίοδος</Form.Label>
              <Form.Control  type="text" name="lease_empty" id="lease_empty" defaultValue={globalData.lease_empty}  onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              Κενή Περίοδος μέχρι μίσθωση / Void
    (μήνες)
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Μίσθωμα</Form.Label>
              <Form.Control  type="text" name="lease_price" id="lease_price" defaultValue={globalData.lease_price}   onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">

              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label> Αγοραίο Μίσθωμα</Form.Label>
              <Form.Control  type="text" name="lease_market_price" id="lease_market_price" defaultValue={globalData.lease_market_price}   onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
                Μίσθωμα της αγοράς (ανα m2)
              </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Row>





        <Form.Group  as={Col} >
        <Form.Label>Χρήση</Form.Label>
        <Form.Control as="select"  name="lease_type"  defaultValue={globalData.lease_type}  onChange={handleChange} id="lease_type">
          <option value="Κενό">Κενό</option>
          <option value="Οικόπεδο">Οικόπεδο</option>
          <option value="Γραφείο">Γραφείο</option>
          <option value="Κατάστημα">Κατάστημα</option>
          <option value="Θέσεις στάθμευσης">Θέσεις στάθμευσης</option>
          <option value="Μηχανολογικός χώρος">Μηχανολογικός χώρος</option>
          <option value="Υπόγειες Θέσεις στάθμευσης">Υπόγειες Θέσεις στάθμευσης</option>
          <option value="Κεραίες κινητής τηλεφωνίας">Κεραίες κινητής τηλεφωνίας</option>
        </Form.Control>
        </Form.Group>
        <Form.Group  as={Col} >
        <Form.Label>Επίπεδο</Form.Label>
        <Form.Control as="select"  name="lease_floor"  defaultValue={globalData.lease_floor}  onChange={handleChange} id="lease_floor">
          <option value=""></option>
         <option value="-3">Υπόγειο 3</option>
          <option value="-2">Υπόγειο 2</option>
          <option value="-1">Υπόγειο 1</option>
          <option value="-0.5">Ημιυπόγειο</option>
          <option value="0">Ισόγειο</option>
          <option value="+0.5">Δώμα</option>
          <option value="1">1ος Οροφος</option>
          <option value="2">2ος Οροφος</option>
          <option value="3">3ος Οροφος</option>
          <option value="4">4ος Οροφος</option>
          <option value="5">5ος Οροφος</option>
          <option value="6">6ος Οροφος</option>
          <option value="7">7ος Οροφος</option>
          <option value="8">8ος Οροφος</option>
          <option value="9">9ος Οροφος</option>
          <option value="10">10ος Οροφος</option>
        </Form.Control>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Επιφάνεια</Form.Label>
          <Form.Control type="text" name="leaseSQM" id="leaseSQM" defaultValue={globalData.leaseSQM}   onChange={handleChange}  placeholder="" />
          <Form.Text className="text-muted">
          Επιφάνεια
          </Form.Text>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Συν.Μισθώσιμης Ανηγμένης Επιφάνειας (μ²)</Form.Label>
          <Form.Control type="text" name="leaseTotalSQM" id="leaseTotalSQM" defaultValue={globalData.leaseTotalSQM}  onChange={handleChange}   placeholder="" />
          <Form.Text className="text-muted">
          Συν.Μισθώσιμης Ανηγμένης Επιφάνειας (μ²)
          </Form.Text>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Ετήσια Αναπροσορμογή	</Form.Label>
          <Form.Control type="text" name="leaseYearly" id="leaseYearly" defaultValue={globalData.leaseYearly}  onChange={handleChange}   placeholder="" />
          <Form.Text className="text-muted">
          Ετήσια Αναπροσορμογή
          </Form.Text>
        </Form.Group>
            </Form.Row>
       </div>

      </div>

   </div>
   <br/><br/>




   <div className="col-xl-12 gutter-t">
     <div className={`row card `}>
     <div className="col-xl-12 card-header">
           <h3>  <button  onClick={(e) => { e.preventDefault(); setOpenExp(!openExp)}} class="btn btn-icon btn-sm btn-light-primary mr-1">
             <i class="ki ki-arrow-down icon-nm"></i>
             </button> ΕΞΟΔΑ 					</h3>
     </div>
       <div className="col-xl-12  card-body" style={{ display: openExp ? "block" : "none" }}>
             <Form.Row>

               <Form.Group  as={Col} >
               <Form.Label>Κοινόχρηστα</Form.Label>
               <Form.Control  type="text" name="generic_expenses" id="generic_expenses" defaultValue={globalData.generic_expenses}  onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Κοινόχρηστα / Ρεύμα κτλ (€/μ²/μήνα)
               </Form.Text>
               </Form.Group>

                              <Form.Group  as={Col} >
                              <Form.Label>Έξοδα Διακράτησης</Form.Label>
                              <Form.Control  type="text" name="operating_expenses" id="operating_expenses" defaultValue={globalData.operating_expenses}  onChange={handleChange}  rows={5} />
                              <Form.Text className="text-muted">
                            Έξοδα Διακράτησης (% επί του ετήσιουυ)
                              </Form.Text>
                              </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label>Μεσιτική Αμοιβη
</Form.Label>
               <Form.Control  type="text" name="agency_expenses" id="agency_expenses" defaultValue={globalData.agency_expenses}  onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
                Μεσιτική Αμοιβή (επί ετήσιου μισθώματος)

               </Form.Text>
               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label>Κόστη ανακαίνισης
</Form.Label>
               <Form.Control  type="text" name="renovation_expenses" id="renovation_expenses" defaultValue={globalData.renovation_expenses}  onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
              Κόστη ανακαίνισης

               </Form.Text>

               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label>Capex
</Form.Label>
               <Form.Control  type="text" name="capex" id="capex" defaultValue={globalData.capex}  onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
            capex
               </Form.Text>
               </Form.Group>
           </Form.Row>
        </div>

       </div>

    </div>
    {/* next*/}
    <div className="col-xl-12 gutter-t">
      <div className={`row card `}>
      <div className="col-xl-12 card-header">
            <h3> <button  onClick={(e) => { e.preventDefault(); setOpenDeko(!openDeko)}} class="btn btn-icon btn-sm btn-light-primary mr-1">
              <i class="ki ki-arrow-down icon-nm"></i>
              </button> ΟΡΓΑΝΙΣΜΟΙ ΚΟΙΝΗΣ ΩΦΕΛΕΙΑΣ </h3>
      </div>
        <div className="col-xl-12  card-body" style={{ display: openDeko ? "block" : "none" }}>
              <Form.Row>

                <Form.Group  as={Col} >
                <Form.Label>  Αριθμός Μετρητή Παροχής Ρεύματος</Form.Label>
                <Form.Control  type="text" name="power_number" id="power_number"  defaultValue={globalData.power_number} onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Ρεύματος
                </Form.Text>
                </Form.Group>

              </Form.Row>



              <Form.Row>

                <Form.Group  as={Col} >
                <Form.Label>    Αριθμός Μετρητή Παροχής Φυσικού Αερίου</Form.Label>
                <Form.Control  type="text" name="gas_number" id="gas_number"  defaultValue={globalData.gas_number}  onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Φυσικού Αερίου
                </Form.Text>
                </Form.Group>

              </Form.Row>



              <Form.Row>

                <Form.Group  as={Col} >
                <Form.Label>Αριθμός Μετρητή Παροχής Νερού</Form.Label>
                <Form.Control  type="text" name="water_number" id="water_number" defaultValue={globalData.water_number}  onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Νερού
                </Form.Text>
                </Form.Group>

              </Form.Row>



         </div>

        </div>
        {/*next*/}

            {/*next*/}


        {/*next*/}
        {/*next*/}


    {/*next*/}


        {/*next*/}



{/*next*/}

     </div>
</div>
<div className="row">
  <div className="col-xl-12">
    <div className="row">

      <div className="col-xl-12">

          {data!==null?
          <div>
          <br/>
          {data.ErrorCode!=0?<>
            <Alert severity="error">Πρόβλημα στην ενημέρωση άλλαξε το Collateral Id </Alert>
          </>:

          <Alert>  Επιτυχής ενημέρωση </Alert>
          }
          </div>
          :<></>}
          <br/><br/>
          {loading ?    <Loader
      type="Bars"
      color="#00BFFF"
      height={10}
      width={50}
      />:<>
              <button className="btn btn-light-primary btn-bold mr-3">
            Αποθήκευση αλλαγών</button>

                 <button  onClick={ ()=> exportExcel()} className="btn btn-light-info btn-bold mr-3">
                  Export to CPS Excel</button>
                  <br/>  <br/>  <br/>
            </>
              }
          <br/>
          </div>
        </div>
      </div>
      </div>
  </Form>
  <br/>

    <div className="col-xl-12 gutter-t">
      <div className={`row card `}>
      <div className="col-xl-12 card-header">
            <h3>Φωτογραφίες</h3>
      </div>
        <div className="col-xl-12  card-body">
      <ContentAVM  id={id} category="photos" key="photos"></ContentAVM>
        </div>

     </div>
    </div>
    <div className="col-xl-12">
      <div className="row card">

        <div className="col-xl-12 card-header">
              <h3>Καταχώρηση νέου αρχείου</h3>
        </div>
        <div className="col-xl-12 card-body">
        <Form.Group>
          <Form.Label>Τίτλος</Form.Label>
          <Form.Control type="text" name="title" id="title" value={dataTitle} onChange={changeHandler}  placeholder="Enter title" />
          <Form.Text className="text-muted">
            Περιγραφή
          </Form.Text>

        </Form.Group>
                        <br/><br/>
               <input type="file" name="file" id="file" onChange={changeHandler}/>
               {isSelected ? (
                         <div>
                           <p>Filename: {selectedFile.name}</p>
                           <p>Filetype: {selectedFile.type}</p>
                           <p>Size in bytes: {selectedFile.size}</p>

                         </div>
               ) : (
                 <p>Select a file to show details</p>
               )}

                        {isUploaded ? (
                       <div>
                       <br /><br />
                         <Alert onClose={() => {  setIsUploaded(false); }}>Success</Alert>
                         <br />

                       </div>
                     ) : (
                         isUploadingFile ? (
                           <Loader
                      type="Bars"
                      color="#00BFFF"
                      height={10}
                      width={50}
                      />
                         ) :(
                       <div>

                         <button onClick={()=>handleSubmission('photos')}  className="btn btn-light-primary btn-bold">
                          Αποθήκευση  </button>
                       </div>
                      )

                     )}

         </div>
        </div>
    </div>






   <br/><br/>


   <br/><br/>



   </>
  :<Loader
type="Bars"
color="#00BFFF"
height={20}
width={40}
/>}

    </>

  );


}
export default AVM_AssetView;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
