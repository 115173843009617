import React,{useState} from "react";
import {useSubheader} from "../../_metronic/layout";
import { InspectionInventory } from '../modules/Hometrix/InspectionInventory'

export const InspectionPage = () => {
  //const suhbeader = useSubheader();
  //suhbeader.setTitle("inventory page");

  const [childKey, setChildKey] = useState(0);

  return (
    <>

      <InspectionInventory  key={childKey}></InspectionInventory>
      </>

  );
};
