import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../layout";

import {
  MixedWidget4,
  BaseTablesWidget1,
  BaseTablesWidget2,
  BaseTablesWidget6,
  StatsWidget11,
  StatsWidget10,
  ListsWidget8,
  ListsWidget10,
  ListsWidget14,
  AdvanceTablesWidget9,
  InventoryValue,
  InventoryReports,
  InventoryItems,
  TestWidget,
} from "../widgets";
import {WeatherWidgetAthens} from "../../../app/modules/Hometrix/WeatherWidgetAthens"
import {WeatherWidgetMilan} from "../../../app/modules/Hometrix/WeatherWidgetMilan"
import {GoogleNewsWidget} from "../../../app/modules/Hometrix/GoogleNewsWidget"

export function Dashboard() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            demo: objectPath.get(
                uiService.config,
                "demo"
            )};
    }, [uiService]);
    return <>

    {/* begin::Row */}
    <div className="row">
      <div className="col-xl-4">
        <div className="row">
          <div className="col-xl-12">
            <WeatherWidgetAthens
              className="gutter-b"
              symbolShape="circle"
              baseColor="danger"
            />
          </div>
          <div className="col-xl-12">
            <WeatherWidgetMilan
              className="gutter-b"
              symbolShape="circle"
              baseColor="info"
            />
          </div>
        </div>
      </div>
      <div className="col-xl-8">
      <GoogleNewsWidget/>
      </div>
      <div className="col-xl-12">

      </div>
    </div>
    {/* end::Row */}

    </>;
}
