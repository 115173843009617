import React from "react";
import { culcPercentage } from "../utils";

export const BorderLine = () => {
  const culcSize = () => {
    const w = window.innerWidth;
    if (w > 1500) return culcPercentage(w, 63);
    else if (w > 1000 && w <= 1500) return culcPercentage(w, 73);
    else if (w <= 500) return culcPercentage(w, 200);
    else return culcPercentage(w, 150);
  };
  return (
    <div
      style={{
        width: culcSize(),
        height: "1px",
        backgroundColor: "#0f0f0f",
        opacity: "0.2",
        marginTop: "-5px",
      }}
    ></div>
  );
};
