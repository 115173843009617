import React from "react";
import no_data_gr from "../assets/icons/no_data_gr.png";
import no_data_en from "../assets/icons/no_data_en.png";
import { LanguageContext } from "../context/LanguageContext";

export const NoData = () => {
  const { language } = React.useContext(LanguageContext);

  return (
    <span className="no-data-thumb">
      <img
        className="no-data-img"
        src={language === "EN" ? no_data_en : no_data_gr}
        alt="no-data"
      ></img>
    </span>
  );
};
