import React from "react";
import { LanguageContext } from "../context/LanguageContext";
import { DataContext } from "../context/DataContext";

export const AsteriscSubnote = ({ target, data }) => {
  const { getLabels } = React.useContext(LanguageContext);
  const { json, safelyGetData } = React.useContext(DataContext);

  const findCountColumn = () => {
    return !!json[target]
      ? JSON.parse(json[target]).columns.indexOf("Count")
      : 0;
  };

  let shouldRender = false;

  if (data === undefined) {
    const countColumn = findCountColumn();
    shouldRender = safelyGetData(target).filter((d) => d[countColumn] < 30)
      .length;
  } else {
    shouldRender = !!data.filter((d) => d.count < 30).length;
  }

  return (
    <div>
      {shouldRender ? (
        <h1 className="asterisc">{getLabels().asterisc}</h1>
      ) : null}
    </div>
  );
};
