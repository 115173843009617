/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useState} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList4({ layoutProps }) {
  const location = useLocation();
  const [permissions, setPermissions] = useState("");
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}


        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Εργαλειοθηκη</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/retail_charts", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/retail_charts">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Ανάλυση καταστημάτων</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/office_charts", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/office_charts">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Archive.svg"
                )}
              />
            </span>
            <span className="menu-text">Ανάλυση γραφείων</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/residential_charts", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/residential_charts">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Archive.svg"
                  )}
                />
              </span>
              <span className="menu-text">Ανάλυση οικιστικών</span>

              </NavLink>
            </li>


        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/tφ", false)}`}
          aria-haspopup="true"
        >
          <span className="menu-link" to="/mva/assets">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Search.svg"
                )}
              />
            </span>

            <NavLink className="menu-link" to="/inspection-inventory">Βιβλιοθήκη επιθεωρήσεων</NavLink>
          </span>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/t", false)}`}
          aria-haspopup="true"
        >
          <span className="menu-link" to="/mva/assets">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Edit.svg"
                )}
              />
            </span>

            <NavLink className="menu-link" to="/inspection-report"> Δημιουργία αρχικής επιθεώρησης</NavLink>
          </span>
        </li>
        {/*end::1 Level*/}

        {/* Custom */}


        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
