const getSum = (str) => {
  let n = str.split(",");
  return Number(n[0].replace("(", "")) + Number(n[1].replace("]", ""));
};

export const sortYearStrings = (labels) => {
  const mappedSums = labels.map((l) => ({ key: l, sum: getSum(l) }));
  return mappedSums.sort((a, b) => a.sum - b.sum).map((s) => s.key);
};

export const sortSurfaceStrings = (labels) => {
  // keeping possible first and last items here
  const firstItem = labels.find((s) => s.includes("<")) || "";
  const lastItem = labels.find((s) => s.includes(">")) || "";

  labels = labels
    .filter((d) => !d.includes(">") && !d.includes("<")) // clearing out non sortables
    .map((l) => ({ key: l, sum: getSum(l) })) // summing the strings
    .sort((a, b) => a.sum - b.sum) // sorting
    .map((s) => s.key); // re-composing the label array

  // pushing first and last in case there was any
  !!lastItem.length && labels.push(lastItem);
  !!firstItem.length && labels.unshift(firstItem);

  return labels;
};
