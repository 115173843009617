import React from "react";
import { greyOutV2, generatePriceTicks, getBarSize } from "../utils";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Label,
  Cell,
} from "recharts";
import { DataContext } from "../context/DataContext";

export const VerticalBarChart2 = ({
  data,
  width = 800,
  height = 600,
  margin = { left: 0, top: 0, right: 0, bottom: 0 },
  label = "",
  showLegend = true,
  axis = "left",
  xAxisHeight = 30,
}) => {
  const { json } = React.useContext(DataContext);

  return (
    // increasing the height of the ComposedChart will provide more areas
    <ComposedChart
      layout="vertical"
      width={width}
      height={height}
      data={data}
      margin={{ ...margin }}
    >
      <XAxis
        type="number"
        height={xAxisHeight}
        interval="preserveStartEnd"
        domain={["dataMin - 100", "dataMax + 100"]}
        ticks={generatePriceTicks(data, "sales")}
      >
        <Label
          value={!!label.length && label}
          offset={2}
          position="insideBottom"
        />
      </XAxis>

      <YAxis
        dataKey="name"
        type="category"
        width={500}
        orientation={axis}
        interval={0}
      />
      {showLegend && <Legend />}
      <Bar dataKey="sales" barSize={getBarSize(data)}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={greyOutV2(entry, json)} />
        ))}
      </Bar>
    </ComposedChart>
  );
};
