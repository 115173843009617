import React from "react";
import { culcPercentage } from "./utils";
import { DataContext } from "./context/DataContext";
import { LanguageContext } from "./context/LanguageContext";
import { MultipleAreaChart } from "./Shared/MultipleAreaChart";
import { VerticalBarChart2 } from "./Shared/VerticalBarChart2";
import { AsteriscSubnote } from "./Shared/AsteriscSubnote";
import { NoData } from "./Shared/NoData";

// slightly scalable
export const CombinedAreaChart = ({ width = 1000 }) => {
  const { json, safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);

  const data = safelyGetData("same_region_price");

  const getHeight = () => {
    return data.length * 27.3;
  };

  const getLeftMargin = () => {
    if (width > 300) return width / culcPercentage(width, 0.5);
    else return 50 / culcPercentage(width, 0.5);
  };

  const culcWidth = () => {
    return window.innerWidth > 1500 ? width + 650 : width + 100;
  };

  return (
    <>
      {!!json.same_region_price ? (
        <div>
          <VerticalBarChart2
            width={culcWidth()}
            height={getHeight()}
            margin={{ left: getLeftMargin() }}
            label={getLabels().median_price}
            showLegend={false}
          />
          <MultipleAreaChart data={data} width={width} height={getHeight()} />
          <AsteriscSubnote target="same_region_price" />
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
};
