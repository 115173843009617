import React from "react";
import abbreviations from "../assets/icons/abbreviations.png";
import { getColor, culcIconWidth, culcMarginTop } from "../utils";
import { LanguageContext } from "../context/LanguageContext";

// react/jsx-pascal-case
export const Αbbreviations = () => {
  const { getTitles, getContent } = React.useContext(LanguageContext);
  const CONTENT = getContent();

  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body p-5">
        <div className="col metric">
          <img
            src={abbreviations}
            alt="abbreviations icon"
            width={culcIconWidth()}
          ></img>
        </div>
        <div>
          <div className="col">
            <ul className="metric-list" style={{ marginTop: culcMarginTop() }}>
              <li>
                <h3 style={{ color: getColor(3) }} className="metric-title">
                  {getTitles().abbreviations}
                </h3>
              </li>

              {Object.values(CONTENT.metrics.abbreviations).map((o, i) => (
                <li key={i}>
                  <p>
                    {o[0]} <b>{o[1]}</b>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
