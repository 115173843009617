import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { MyPage } from "./pages/MyPage";
import { InspectionReport } from "./pages/InspectionReport";
import { InspectionReportView } from "./pages/InspectionReportView";

import { InspectionPage} from "./pages/InspectionPage";
import { DashboardPage } from "./pages/DashboardPage";
import { Content } from "./pages/Content";
import { ContentAll } from "./pages/ContentAll";
import { Hometrix_MVA_Generic } from "./modules/Hometrix_MVA/Generic";
import { Hometrix_MVA_Clients } from "./modules/Hometrix_MVA/Clients";
import { Hometrix_MVA_Client } from "./modules/Hometrix_MVA/Client";
import { Hometrix_MVA_Assets } from "./modules/Hometrix_MVA/Assets";
import { Hometrix_MVA_Asset } from "./modules/Hometrix_MVA/Asset";
import { Hometrix_MVA_AssetView } from "./modules/Hometrix_MVA/AssetView";
import { Hometrix_MVA_AssetDCF } from "./modules/Hometrix_MVA/AssetDCF";

import { AVM_Assets } from "./modules/AVM/Assets";
import { AVM_Asset } from "./modules/AVM/Asset";
import { AVM_AssetView } from "./modules/AVM/AssetView";

import { BOG_Assets } from "./modules/BOG/Assets";
import { BOG_Asset } from "./modules/BOG/Asset";
import { BOG_AssetView } from "./modules/BOG/AssetView";


import { CommercialsInventory } from "./pages/CommercialsInventory";
import { ResidentialPage } from "../charts/ResidentialPage";
import { OfficesPage } from "../office-charts/OfficesPage";
import { RetailPage } from "../retail-charts/RetailPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);



export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/inspection-report/:id" component={InspectionReportView} />
        <Route path="/mva/asset/:id" component={Hometrix_MVA_AssetView} />
        <Route path="/avm/asset/:id" component={AVM_AssetView} />
        <Route path="/mva/dcf/:id" component={Hometrix_MVA_AssetDCF} />

        <Route path="/bog/asset/:id" component={BOG_AssetView} />


        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/inspection-inventory" component={InspectionPage} />
        <ContentRoute path="/inspection-report" component={InspectionReport} />
        <ContentRoute path="/test-page" component={MyPage} />
        <ContentRoute path="/residential_charts" component={ResidentialPage} />
        <ContentRoute path="/office_charts" component={OfficesPage} />
        <ContentRoute path="/retail_charts" component={RetailPage} />
        <ContentRoute path="/content" component={Content} />

        <ContentRoute path="/contentAll" component={ContentAll} />

        <ContentRoute path="/mva/generic" component={Hometrix_MVA_Generic} />
        <ContentRoute path="/mva/clients" component={Hometrix_MVA_Clients} />
        <ContentRoute path="/mva/client" component={Hometrix_MVA_Client} />
        <ContentRoute path="/mva/assets" component={Hometrix_MVA_Assets} />
        <ContentRoute path="/mva/asset" component={Hometrix_MVA_Asset} />

        <ContentRoute path="/bog/assets" component={BOG_Assets} />
        <ContentRoute path="/bog/asset" component={BOG_Asset} />

        <ContentRoute path="/avm/assets" component={AVM_Assets} />
        <ContentRoute path="/avm/asset" component={AVM_Asset} />

        <ContentRoute path="/commercials/inventory" component={CommercialsInventory} />

        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
