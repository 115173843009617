/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { getWidth } from "../utils";
import { YearBarChart } from "../YearBarChart";

export function YearBarChartTab({ name }) {
  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom gutter-b`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">{name}</h3>
          <div className="card-toolbar"></div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 table-responsive">
          {/* begin::Item */}
          <YearBarChart
            width={getWidth(450, 1030)}
            height={450}
            showLegend={true}
          />
          {/* end::Item */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
}
