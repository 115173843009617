/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { PieChartWithLabel } from "../PieChartWithLabel";
import { DataContext } from "../context/DataContext";
import { reformForPieChart } from "../utils";
import { NoData } from "../Shared/NoData";

export function PieChart1() {
  const { json, safelyGetData } = React.useContext(DataContext);

  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body">
        {!!json.overview_build_status ? (
          <PieChartWithLabel
            palette={["#347E84", "#57b5bd", "#78c6cd", "#ffb380", "#f7e5c5"]}
            height={150}
            showLegend={true}
            radius={75}
            data={reformForPieChart(safelyGetData("overview_build_status"))}
          />
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}
