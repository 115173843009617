/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Houses } from "../Metrics/Houses";

export function HousesMetrics() {
  return (
    <div className={`card card-custom gutter-b`}>
      <div className="card-body p-5">
        <Houses />
      </div>
    </div>
  );
}
