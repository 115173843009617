/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { SurfaceBarChart } from "../SurfaceBarChart";
import { getWidth } from "../utils";

export function SurfaceBarChartTab({ name }) {
  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom gutter-b`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {name}
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <SurfaceBarChart
              height={450}
              width={getWidth(500, 1030)}
              showLegend={true}
            />
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
