import React from "react";
import {
  greyOutV2,
  reformForVericalBarChart,
  generatePriceTicks,
} from "../utils";
import { DataContext } from "../context/DataContext";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Label,
  Cell,
} from "recharts";

export const VerticalBarChart2 = ({
  width = 800,
  height = 600,
  margin = { left: 0, top: 0, right: 0, bottom: 0 },
  label = "",
  showLegend = true,
  barSize = 10,
  axis = "left",
  xAxisHeight = 30,
}) => {
  const { json, safelyGetData } = React.useContext(DataContext);
  const data = reformForVericalBarChart(safelyGetData("same_region_price"));

  return (
    // increasing the height of the ComposedChart will provide more areas
    <ComposedChart
      layout="vertical"
      width={width}
      height={height}
      data={data}
      margin={{ ...margin }}
    >
      <XAxis
        type="number"
        height={xAxisHeight}
        interval="preserveStartEnd"
        domain={["dataMin - 100", "dataMax + 100"]}
        ticks={generatePriceTicks(data, "sales")}
      >
        <Label
          value={!!label.length && label}
          offset={2}
          position="insideBottom"
        />
      </XAxis>

      <YAxis
        dataKey="name"
        type="category"
        width={500}
        orientation={axis}
        interval={0}
      />
      {showLegend && <Legend />}
      <Bar dataKey="sales" barSize={barSize}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={greyOutV2(entry, json)} />
        ))}
      </Bar>
    </ComposedChart>
  );
};
