/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getWidth } from "../utils";
import { VerticalBarChart2 } from "../Shared/VerticalBarChart2";
import { DataContext } from "../context/DataContext";
import { LanguageContext } from "../context/LanguageContext";
import { MunicipalityBarChart1 } from "../MunicipalityBarChart1";
import { AsteriscSubnote } from "../Shared/AsteriscSubnote";
import { NoData } from "../Shared/NoData";

export function NeighbBarChart1({ name }) {
  const { json, safelyGetData } = React.useContext(DataContext);
  const { getLabels } = React.useContext(LanguageContext);

  const data = safelyGetData("retail_market_price").map((n) => ({
    name: n[0],
    sales: n[1],
    count: n[2],
  }));

  return (
    <>
      {/* begin::Base Table Widget 2 */}
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {name}
            </span>
          </h3>
          <div className="card-toolbar"></div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          {!!json.retail_market_price ? (
            <div className="table-responsive" style={{ paddingLeft: "10%" }}>
              {json.Attica_market ? (
                <VerticalBarChart2
                  axis="right"
                  margin={{ right: -200 }}
                  data={data}
                  width={getWidth(900, 900)}
                  height={420}
                  showLegend={false}
                  label={getLabels().median_price}
                />
              ) : (
                <MunicipalityBarChart1
                  data={data}
                  height={400}
                  showLegend={false}
                  width={500}
                />
              )}
              <AsteriscSubnote target="retail_market_price" />
            </div>
          ) : (
            <NoData />
          )}
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 2 */}
    </>
  );
}
