import { ILanguage } from "../assets/data/LANGUAGES";

export const makeSubtitle = (json, lang) => {
  if (lang === ILanguage.english) {
    return `between ${
      json.Attica_market ? "Attica" : "Thessaloniki"
    } Main Office Markets`;
  } else {
    return `ανάμεσα στις Κύριες Αγορές Γραφείων ${
      json.Attica_market ? "Αττικής" : "Θεσσαλονίκης"
    }`;
  }
};

export const makeCombinedChartTitle = (json, lang) => {
  if (lang === ILanguage.english) {
    return `Distribution of Psqm at Municipality level for ${
      json.Attica_market ? "Attica" : "Thessaloniki"
    } Main Office Markets`;
  } else {
    return `Κατανομή Τιμής/τ.μ. σε επίπεδο δήμου για τις Κύριες Αγορές Γραφείων ${
      json.Attica_market ? "Αττικής" : "Θεσσαλονίκης"
    }`;
  }
};

export const makeMedianYoCTitle = (json, lang) => {
  if (lang === ILanguage.english) {
    return `Median YoC at Municipality level for ${
      json.Attica_market ? "Attica" : "Thessaloniki"
    } Main Office Markets`;
  } else {
    return `Διάμεσο Έτος Κατασκευής σε επίπεδο δήμου για τις Κύριες Αγορές Γραφείων ${
      json.Attica_market ? "Αττικής" : "Θεσσαλονίκης"
    }`;
  }
};
