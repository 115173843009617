import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import {useSubheader} from "../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { FormattedMessage } from "react-intl";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Autocomplete,Marker } from '@react-google-maps/api';
import { Form,Button,Col } from 'react-bootstrap';
import { TextField,makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const libraries = ["places","geometry"];
const useStyles = makeStyles({
  margin_r: {
    margin: '0px 5px 0px 0px',
  },

});

export function InspectionReport()  {
  const classes = useStyles();
  const [formData, updateFormData] = useState("");

  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();

  async function onSubmit(event) {
         event.preventDefault();
       const data = new FormData(event.target);
    //   console.log(data);
    //   console.log(data.get('collateralId'));
    //   console.log(data.get('lat'));
      data.append('user',user.email);

     setLoading(true);
     setData(null);

    const accessToken = await getAccessTokenSilently({
         audience: process.env.REACT_APP_AUTH0_AUDIENCE
       });
       var apiURL = process.env.REACT_APP_SERVER_URL+"/hometrixApp";
       apiURL+="?action=insertInspection";
      const metadataResponse = await fetch(apiURL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
     mode: 'cors', // no-cors, *cors, same-origin
     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
     body:data,
     credentials: 'include',
        headers: {

          'authorization': 'Bearer '+accessToken,
        },
      });

      const resp = await metadataResponse.json();
    //  console.log(resp);
      setData(resp);
     setLoading(false);
  }



  const onLoad = () => {};
  const [map, setMap] = useState(null);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [district,setDistrict]=useState("");
  const [address,setAddress]=useState("");
  const [prefecture,setPrefecture]=useState("");
  const [googleAddress, setGoogleAddress] = useState("");
  const [marker, setMarker] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  var places;


  var place=null;

  var center = {
    lat: 38.0572437,
    lng: 23.8481676
  };
  function onloadMap(ref){
    setMap(ref);
  }
  function onLoadMarker(ref) {
    setMarker(ref);
  }
  function onSearchBoxMounted(ref) {
      setAutocomplete(ref);
  }
  function onDragEnd(e) {
//    console.log("onPositionChanged");

    //map.panTo(new window.google.maps.LatLng( marker.getPosition().lat(), marker.getPosition().lng() ));
    //center.lat=marker.getPosition().lat();
  //  center.lng=marker.getPosition().lng();
      setLat(marker.getPosition().lat());
      setLng(marker.getPosition().lng());


    geocodePosition(marker.getPosition());
//    setLat(place.geometry.location.lat());
//    setLng(place.geometry.location.lng());
  }
  function geocodePosition(pos)  {



  var  geocoder = new window.google.maps.Geocoder();
   geocoder.geocode
    ({
        latLng: pos
    },
        function(results, status)
        {
            if (status ==window.google.maps.GeocoderStatus.OK)
            {


              updateAddress(results);
            }
            else
            {
            	//  setAddress(marker.getPosition().lat()+","+marker.getPosition().lng());


            }
        }
    );

	}
  function updateAddress(results) {
    setAddress(results[0].formatted_address);
      Object.keys(results[0].address_components).forEach(function(key) {
        var result=results[0].address_components[key];
        if (result.types.indexOf("locality") > -1) {
          setMunicipality(result.long_name);
        }
        if (result.types.indexOf("postal_code") > -1) {
          setPostalCode(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_32") > -1) {
          setDistrict(result.long_name);
        }
        if (result.types.indexOf("administrative_area_level_3") > -1) {
          setPrefecture(result.long_name);
        }

      });
  }
  function onPlacesChanged() {
  //  console.log('autocomplete: ', autocomplete)
//    console.log(searchBox.getPlaces());
      if (autocomplete !== null) {
  //     console.log(autocomplete.getPlace())
      place=autocomplete.getPlace();


      //setGoogleAddress(place.formatted_address);
      setLat(place.geometry.location.lat());
      setLng(place.geometry.location.lng());
      center.lat=place.geometry.location.lat();
      center.lng=place.geometry.location.lng();

      marker.setPosition(new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );

      map.panTo( new window.google.maps.LatLng( place.geometry.location.lat(), place.geometry.location.lng() ) );
      geocodePosition(marker.getPosition());

      } else {
  //     console.log('Autocomplete is not loaded yet!')
      }
  }
 const [value, setValue] = useState('Hello World');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);


  function handleChange (e) {

    if (e.target.id=='lat') {
      setLat(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }
    if (e.target.id=='lng') {
      setLng(e.target.value);
      if (marker) {
          marker.setPosition(new window.google.maps.LatLng( lat, lng ) );
          map.panTo( new window.google.maps.LatLng( lat, lng ) );
          map.setZoom(16);
      }
    }



 }


  return (
    <>
      <Form onSubmit={onSubmit}>
    <div className="row">
      <div className="col-xl-12">
        <div className={`row card `}>
        <div className="col-xl-12 card-header">
              <h3>ΑΡΧΙΚΗ ΕΠΙΘΕΩΡΗΣΗ ΑΚΙΝΗΤΟΥ</h3>
        </div>
          <div className="col-xl-12  card-body">
              <Form.Group>
                <Form.Label>Μοναδικός κωδικός ακινήτου</Form.Label>
                <Form.Control type="text" name="collateralId" id="collateralId" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Μοναδικός κωδικός ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>  Α/Α Ακινήτου</Form.Label>
                <Form.Control type="text" name="uniqueId" id="uniqueId" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                Α/Α Ακινήτου
                </Form.Text>
              </Form.Group>

              <Form.Group>
                <Form.Label>  ΚΑΕΚ Ακινήτου</Form.Label>
                <Form.Control type="text" name="kaek" id="kaek" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                ΚΑΕΚ Ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>Ημερομηνία Αυτοψίας</Form.Label>
                <Form.Control type="date" name="inspectionDt" id="inspectionDt" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                  Ημερομηνία Αυτοψίας/Αποβολής
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Label>    Ιδιοκτήτης Ακινήτου</Form.Label>
                <Form.Control type="text" name="owner" id="owner" onChange={handleChange}  placeholder="" />
                <Form.Text className="text-muted">
                  Ιδιοκτήτης Ακινήτου
                </Form.Text>
              </Form.Group>
              <Form.Group>
                 <Form.Label>  Περιγραφή ακινήτου</Form.Label>
                 <Form.Control  as="textarea"  name="description" id="description" onChange={handleChange}  rows={10 } />
                 <Form.Text className="text-muted">
                  Περιγραφή ακινήτου
                 </Form.Text>
               </Form.Group>

          </div>

    </div>

  </div>

</div>
<div className="row gutter-t">
  <div className="col-xl-12">
    <div className="row card">
    <div className="col-xl-12 card-header">
          <h3>ΠΙΝΑΚΑΣ ΟΡΙΖΟΝΤΙΩΝ ΙΔΙΟΚΤΗΣΙΩΝ ΑΚΙΝΗΤΟΥ					</h3>
    </div>
      <div className="col-xl-12  card-body">
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label>   A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_1_id" id="horizontal_1_id" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_1_description" id="horizontal_1_description" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label>m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_1_sqm" id="horizontal_1_sqm" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_1_comments" id="horizontal_1_comments" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label>   A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_2_id" id="horizontal_2_id" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_2_description" id="horizontal_2_description" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_2_sqm" id="horizontal_2_sqm" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_2_comments" id="horizontal_2_comments" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label>   A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_3_id" id="horizontal_3_id" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_3_description" id="horizontal_3_description" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_3_sqm" id="horizontal_3_sqm" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label> Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_3_comments" id="horizontal_3_comments" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label>A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_4_id" id="horizontal_4_id" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_4_description" id="horizontal_4_description" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label>m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_4_sqm" id="horizontal_4_sqm" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_4_comments" id="horizontal_4_comments" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label> A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_5_id" id="horizontal_5_id" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_5_description" id="horizontal_5_description" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_5_sqm" id="horizontal_5_sqm" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_5_comments" id="horizontal_5_comments" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={1}>
         <Form.Group>
         <Form.Label> A/A.</Form.Label>
         <Form.Control type="text" name="horizontal_6_id" id="horizontal_6_id" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         A/A
         </Form.Text>
         </Form.Group>
         </Col>
         <Col xs={7}>
         <Form.Group  as={Col} >
         <Form.Label>Περιγραφή Οριζόντιας Ιδιοκτησίας</Form.Label>
         <Form.Control type="text" name="horizontal_6_description" id="horizontal_6_description" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
        Περιγραφή Οριζόντιας Ιδιοκτησίας
         </Form.Text>
         </Form.Group>
        </Col>
         <Form.Group  as={Col} >
         <Form.Label> m2 βάσει ΠΚΕ.</Form.Label>
         <Form.Control type="text" name="horizontal_6_sqm" id="horizontal_6_sqm" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         m2 βάσει ΠΚΕ
         </Form.Text>
         </Form.Group>
         <Form.Group  as={Col} >
         <Form.Label>Παρατηρήσεις</Form.Label>
         <Form.Control type="text" name="horizontal_6_comments" id="horizontal_6_comments" onChange={handleChange}  placeholder="" />
         <Form.Text className="text-muted">
         Παρατηρήσεις
         </Form.Text>
         </Form.Group>
       </Form.Row>
       <Form.Row>
       <Col xs={2}>
       <Form.Group  as={Col} >
       <Form.Label>  Ύπαρξη Παρακολουθημάτων</Form.Label>
       <Form.Control as="select"  name="horizontal_track" id="horizontal_track">
         <option value="Όχι">Όχι</option>
         <option value="Ναι">Ναι</option>

       </Form.Control>   <Form.Text className="text-muted">
    Ύπαρξη Παρακολουθημάτων
       </Form.Text>
       </Form.Group>
       </Col>
         <Form.Group  as={Col} >
         <Form.Label>  Περιγραφή παρακολουθήματος</Form.Label>
         <Form.Control  as="textarea"  name="horizontal_comments" id="horizontal_comments" onChange={handleChange}  rows={5} />
         <Form.Text className="text-muted">
        Περιγραφή παρακολουθήματος
         </Form.Text>
         </Form.Group>
       </Form.Row>

  </div>

</div>
</div>


    <div className="col-xl-12 gutter-t">
      <div className="row card">
      <div className="col-xl-12 card-header">
            <h3>ΤΟΠΟΘΕΣΙΑ ΑΚΙΝΗΤΟΥ 					 </h3>
      </div>
        <div className="col-xl-12 card-body">

              <LoadScript
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
                  libraries={libraries}
                >
                  <GoogleMap
                    onLoad={onloadMap}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                  >
                    { /* Child components, such as markers, info windows, etc. */ }
                    <Autocomplete
                  onLoad={onSearchBoxMounted}
                  onPlaceChanged={onPlacesChanged}
                  onPlacesChanged={onPlacesChanged}
                  onFail={error => console.error(error)}

                >
                  <input

                    type="text"
                    placeholder="Εύρεση απο Google"
                    style={{
                      boxSizing: `border-box`,
                      border: `2px solid #E4E6EF`,
                      width: `100%`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: '0.42rem',
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      bottom:"0px",
                      marginLeft: "-50%"

                    }}
                  />
                </Autocomplete>

                  <Marker
                      onLoad={onLoadMarker}
                      draggable={true}
                      onDragEnd={onDragEnd}
                 />



                  </GoogleMap>
                </LoadScript>

      {/* end: MAP */}

                <Form.Group className="gutter-t">
                  <Form.Label>Γεωγραφικό πλάτος (Latitude)</Form.Label>
                  <Form.Control type="text"  name="lat" value={lat}  onChange={handleChange} id="lat" placeholder="" />
                  <Form.Text className="text-muted">
                  Γεωγραφικό πλάτος (Latitude)
                  </Form.Text>
                </Form.Group>

                  <Form.Group>
                    <Form.Label>  Γεωγραφικό μήκος (Longtitude)</Form.Label>
                    <Form.Control type="text" name="lng" id="lng"  value={lng}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Γεωγραφικό μήκος (Longtitude)
                    </Form.Text>
                  </Form.Group>


                  <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Διεύθυνση</Form.Label>
                    <Form.Control type="text" name="address" id="address" value={address} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Διεύθυνση
                    </Form.Text>
                  </Form.Group>
                  <Col xs={2}>
                  <Form.Group>
                    <Form.Label>ΤΚ</Form.Label>
                    <Form.Control type="text" name="postalCode" id="postalCode" value={postalCode}  placeholder="" />
                    <Form.Text className="text-muted">
                    ΤΚ
                    </Form.Text>
                  </Form.Group>
                  </Col>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label>Δήμος</Form.Label>
                    <Form.Control type="text" name="municipality" id="municipality"  value={municipality}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δήμος
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Δημοτική ενότητα</Form.Label>
                    <Form.Control type="text" name="district" id="district"    value={district}   onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Δημοτική ενότητα
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Περιφέρεια</Form.Label>
                    <Form.Control type="text" name="prefecture" id="prefecture"  value={prefecture} onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Περιφέρεια
                    </Form.Text>
                  </Form.Group>
                    <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού</Form.Label>
                    <Form.Control type="text" name="plotNumber" id="plotNumber"    onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Αρ. Ο.Τ. / Αρ. Οικοπ. ή Αγρού
                    </Form.Text>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Τοπωνύμιοs</Form.Label>
                    <Form.Control type="text" name="toponym" id="toponym"    onChange={handleChange} placeholder="" />
                    <Form.Text className="text-muted">
                    Τοπωνύμιο
                    </Form.Text>
                  </Form.Group>
                    </Form.Row>
          </div>
          </div>
  </div>
  <div className="col-xl-12 gutter-t">
    <div className={`row card `}>
    <div className="col-xl-12 card-header">
          <h3>ΜΙΣΘΩΣΕΙΣ 					</h3>
    </div>
      <div className="col-xl-12  card-body">
            <Form.Row>
            <Col xs={2}>
            <Form.Group  as={Col} >
            <Form.Label>  Ύπαρξη Υφιστάμενων Μισθώσεων</Form.Label>
            <Form.Control as="select"  name="leased" id="leased">
              <option value="Όχι">Όχι</option>
              <option value="Ναι">Ναι</option>

            </Form.Control>   <Form.Text className="text-muted">
              Ύπαρξη Υφιστάμενων Μισθώσεων
            </Form.Text>
            </Form.Group>
            </Col>
              <Form.Group  as={Col} >
              <Form.Label>    Επωνυμία Μισθωτή</Form.Label>
              <Form.Control  type="text" name="leaser_name" id="leaser_name" onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              Επωνυμία Μισθωτή
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Τηλέφωνο μισθωτή</Form.Label>
              <Form.Control  type="text" name="leaser_contact" id="leaser_contact" onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
              Τηλέφωνο μισθωτή
              </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label>  Έναρξη Μίσθωσης</Form.Label>
              <Form.Control  type="date" name="lease_start" id="lease_start" onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
            Έναρξη Μίσθωσης
              </Form.Text>
              </Form.Group>
              <Form.Group  as={Col} >
              <Form.Label>  Λήξη Μίσθωσης</Form.Label>
              <Form.Control  type="date" name="lease_end" id="lease_end" onChange={handleChange}  rows={5} />
              <Form.Text className="text-muted">
            Λήξη Μίσθωσης
              </Form.Text>
              </Form.Group>
            </Form.Row>

       </div>

      </div>

   </div>

   <div className="col-xl-12 gutter-t">
     <div className={`row card `}>
     <div className="col-xl-12 card-header">
           <h3>ΜΕΣΕΓΓΥΗΣΗ 					</h3>
     </div>
       <div className="col-xl-12  card-body">
             <Form.Row>
             <Col xs={2}>
             <Form.Group  as={Col} >
             <Form.Label>ΜΕΣΕΓΓΥΗΣΗ?</Form.Label>
             <Form.Control as="select"  name="guarantee" id="guarantee">
               <option value="Όχι">Όχι</option>
               <option value="Ναι">Ναι</option>

             </Form.Control>   <Form.Text className="text-muted">
             ΜΕΣΕΓΓΥΗΣΗ
             </Form.Text>
             </Form.Group>
             </Col>
               <Form.Group  as={Col} >
               <Form.Label>  Ορισμένος Μεσεγγυητής</Form.Label>
               <Form.Control  type="text" name="sequestrator" id="sequestrator" onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
                Ορισμένος Μεσεγγυητής
               </Form.Text>
               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label> Τηλέφωνο Μεσεγγυητή</Form.Label>
               <Form.Control  type="text" name="sequestrator_contact" id="sequestrator_contact" onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Τηλέφωνο Μεσεγγυητή
               </Form.Text>
               </Form.Group>
             </Form.Row>
             <Form.Row>

               <Form.Group  as={Col} >
               <Form.Label>Αριθμός Ταυτότητας Μεσεγγυητή</Form.Label>
               <Form.Control  type="text" name="sequestrator_id" id="sequestrator_id" onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Αριθμός Ταυτότητας Μεσεγγυητή
               </Form.Text>
               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label>Διεύθυνση Μεσεγγυητή</Form.Label>
               <Form.Control  type="text" name="sequestrator_address" id="sequestrator_address" onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Διεύθυνση Μεσεγγυητή
               </Form.Text>
               </Form.Group>
             </Form.Row>
             <Form.Row>

               <Form.Group  as={Col} >
               <Form.Label>Χώρος Μεσεγγύησης</Form.Label>
               <Form.Control  type="text" name="guarantee_area" id="guarantee_area" onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
                Χώρος Μεσεγγύησης
               </Form.Text>
               </Form.Group>
               <Form.Group  as={Col} >
               <Form.Label> Δικαστικός Επιμελητής</Form.Label>
               <Form.Control  type="text" name="bailiff" id="bailiff" onChange={handleChange}  rows={5} />
               <Form.Text className="text-muted">
               Δικαστικός Επιμελητής
               </Form.Text>
               </Form.Group>
             </Form.Row>
             <Form.Row>

             <Form.Group  as={Col} >
             <Form.Label>Σημαντικής αξίας αντικείμενα?</Form.Label>
             <Form.Control as="select"  name="valuable_items" id="valuable_items">
               <option value="Όχι">Όχι</option>
               <option value="Ναι">Ναι</option>

             </Form.Control>   <Form.Text className="text-muted">
            Σημαντικής αξίας αντικείμενα
             </Form.Text>
             </Form.Group>
             <Form.Group  as={Col} >
             <Form.Label>Συστήνεται η φύλαξη του ακινήτου? </Form.Label>
             <Form.Control as="select"  name="needs_guarding" id="needs_guarding">
               <option value="Όχι">Όχι</option>
               <option value="Ναι">Ναι</option>

             </Form.Control>   <Form.Text className="text-muted">
            Συστήνεται η φύλαξη του ακινήτου
             </Form.Text>
             </Form.Group>

             </Form.Row>
        </div>

       </div>

    </div>
    {/* next*/}
    <div className="col-xl-12 gutter-t">
      <div className={`row card `}>
      <div className="col-xl-12 card-header">
            <h3>ΟΡΓΑΝΙΣΜΟΙ ΚΟΙΝΗΣ ΩΦΕΛΕΙΑΣ 					</h3>
      </div>
        <div className="col-xl-12  card-body">
              <Form.Row>
              <Col xs={2}>
              <Form.Group  as={Col} >
              <Form.Label>  Κλείσιμο Διακόπτη Παροχής Ρεύματος</Form.Label>
              <Form.Control as="select"  name="power" id="power">
                <option value="Όχι">Όχι</option>
                <option value="Ναι">Ναι</option>

              </Form.Control>   <Form.Text className="text-muted">
              Κλείσιμο Διακόπτη Παροχής Ρεύματος
              </Form.Text>
              </Form.Group>
              </Col>
                <Form.Group  as={Col} >
                <Form.Label>  Αριθμός Μετρητή Παροχής Ρεύματος</Form.Label>
                <Form.Control  type="text" name="power_number" id="power_number" onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Ρεύματος
                </Form.Text>
                </Form.Group>
                <Form.Group  as={Col} >
                <Form.Label>  Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) kWh</Form.Label>
                <Form.Control  type="text" name="power_meter" id="power_meter" onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) kWh
                </Form.Text>
                </Form.Group>
              </Form.Row>


              <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label> Επόμενες ενέργειες</Form.Label>
              <Form.Control as="select"  name="power_actions" id="power_actions">
              <option value="N/A">N/A</option>
              <option value="Request Termination/Αιτηση διακοπής">Αιτηση διακοπής</option>
              <option value="Μaintain Account/Διατήρηση σύνδεσης">Διατήρηση σύνδεσης</option>
             <option value="Check Account with Provider/Έλεγχος παροχής με υπηρεσία">Έλεγχος παροχής με υπηρεσία </option>
              </Form.Control>   <Form.Text className="text-muted">
             Επόμενες ενέργειες
              </Form.Text>
              </Form.Group>


              </Form.Row>

              <Form.Row>

                <Form.Group  as={Col} >
                <Form.Label>  Αριθμός Μετρητή Παροχής Φυσικού Αερίου</Form.Label>
                <Form.Control  type="text" name="gas_number" id="gas_number" onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Αριθμός Μετρητή Παροχής Φυσικού Αερίου
                </Form.Text>
                </Form.Group>
                <Form.Group  as={Col} >
                <Form.Label>  Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) Nm3</Form.Label>
                <Form.Control  type="text" name="gas_meter" id="gas_meter" onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) Nm3

                </Form.Text>
                </Form.Group>
              </Form.Row>


              <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label> Επόμενες ενέργειες</Form.Label>
              <Form.Control as="select"  name="gas_actions" id="gas_actions">
              <option value="N/A">N/A</option>
              <option value="Request Termination/Αιτηση διακοπής">Αιτηση διακοπής</option>
              <option value="Μaintain Account/Διατήρηση σύνδεσης">Διατήρηση σύνδεσης</option>
             <option value="Check Account with Provider/Έλεγχος παροχής με υπηρεσία">Έλεγχος παροχής με υπηρεσία </option>
                  </Form.Control>   <Form.Text className="text-muted">
             Επόμενες ενέργειες
              </Form.Text>
              </Form.Group>


              </Form.Row>

              <Form.Row>
              <Col xs={2}>
              <Form.Group  as={Col} >
              <Form.Label>  Κλείσιμο Διακόπτη Παροχής Νερού?</Form.Label>
              <Form.Control as="select"  name="water" id="water">
                <option value="Όχι">Όχι</option>
                <option value="Ναι">Ναι</option>

              </Form.Control>   <Form.Text className="text-muted">
              Κλείσιμο Διακόπτη Παροχής Νερού
              </Form.Text>
              </Form.Group>
              </Col>
                <Form.Group  as={Col} >
                <Form.Label>  Κλείσιμο Διακόπτη Παροχής Νερού</Form.Label>
                <Form.Control  type="text" name="water_number" id="water_number" onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Κλείσιμο Διακόπτη Παροχής Νερού
                </Form.Text>
                </Form.Group>
                <Form.Group  as={Col} >
                <Form.Label>    Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) m3</Form.Label>
                <Form.Control  type="text" name="water_meter" id="water_meter" onChange={handleChange}  rows={5} />
                <Form.Text className="text-muted">
                Ένδειξη Μετρητή (Ημ/νία Αυτοψίας) m3
                </Form.Text>
                </Form.Group>
              </Form.Row>


              <Form.Row>

              <Form.Group  as={Col} >
              <Form.Label>Επόμενες ενέργειες</Form.Label>
              <Form.Control as="select"  name="water_actions" id="water_actions">
                <option value="N/A">N/A</option>
                <option value="Request Termination/Αιτηση διακοπής">Αιτηση διακοπής</option>
                <option value="Μaintain Account/Διατήρηση σύνδεσης">Διατήρηση σύνδεσης</option>
               <option value="Check Account with Provider/Έλεγχος παροχής με υπηρεσία">Έλεγχος παροχής με υπηρεσία </option>
              </Form.Control>   <Form.Text className="text-muted">
             Επόμενες ενέργειες
              </Form.Text>
              </Form.Group>


              </Form.Row>
         </div>

        </div>
        {/*next*/}

           <div className="col-xl-12 gutter-t">
             <div className={`row card `}>
             <div className="col-xl-12 card-header">
                   <h3>ΠΡΑΓΜΑΤΟΠΟΙΗΘΕΙΣΕΣ ΕΝΕΡΓΕΙΕΣ 					</h3>
             </div>
               <div className="col-xl-12  card-body">
                     <Form.Row>

                       <Form.Group  as={Col} >
                       <Form.Label>  Αλλαγή Κλειδαριάς/ών: Ασφαλείας Με Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_secure_break" id="action_secure_break" onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                        Αλλαγή Κλειδαριάς/ών: Ασφαλείας Με Διάρρηξη
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>Αλλαγή Κλειδαριάς/ών: Ασφαλείας Χωρίς Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_secure" id="action_secure" onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                    Αλλαγή Κλειδαριάς/ών: Ασφαλείας Χωρίς Διάρρηξη
                       </Form.Text>
                       </Form.Group>


                       <Form.Group  as={Col} >
                       <Form.Label>  Αλλαγή Κλειδαριάς/ών: Απλές με Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_normal_break" id="action_normal_break" onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                          Αλλαγή Κλειδαριάς/ών: Απλές με Διάρρηξη
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>  Αλλαγή Κλειδαριάς/ών: Απλές Χωρίς Διάρρηξη</Form.Label>
                       <Form.Control  type="text" name="action_normal" id="action_normal" onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                    Αλλαγή Κλειδαριάς/ών: Απλές Χωρίς Διάρρηξη
                       </Form.Text>
                       </Form.Group>
                     </Form.Row>

                     <Form.Row>
                     <Col xs={3}>
                     <Form.Group  as={Col} >
                     <Form.Label>  Ανάκτηση Στοιχείων Διαχείρισης</Form.Label>
                     <Form.Control as="select"  name="management_available" id="management_available">
                       <option value="Όχι">Όχι</option>
                       <option value="Ναι">Ναι</option>

                     </Form.Control>   <Form.Text className="text-muted">
                    Ανάκτηση Στοιχείων Διαχείρισης
                     </Form.Text>
                     </Form.Group>
                     </Col>
                       <Form.Group  as={Col} >
                       <Form.Label>  Υπεύθυνος Διαχειριστής (Όνομα)</Form.Label>
                       <Form.Control  type="text" name="management_name" id="management_name" onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                      Υπεύθυνος Διαχειριστής (Όνομα)
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>Τηλέφωνο Επικοινωνίας Διαχειριστή</Form.Label>
                       <Form.Control  type="text" name="management_contact" id="management_contact" onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                       Τηλέφωνο Επικοινωνίας Διαχειριστή
                       </Form.Text>
                       </Form.Group>
                       <Col xs={2}>
                       <Form.Group  as={Col} >
                       <Form.Label>  Ανάκτηση Κλειδιών Εισόδου Κτηρίου</Form.Label>
                       <Form.Control as="select"  name="general_entrance" id="general_entrance">
                         <option value="Όχι">Όχι</option>
                         <option value="Ναι">Ναι</option>

                       </Form.Control>   <Form.Text className="text-muted">
                    Ανάκτηση Κλειδιών Εισόδου Κτηρίου
                       </Form.Text>
                       </Form.Group>
                       </Col>
                       </Form.Row>
                </div>

               </div>

            </div>
            {/*next*/}

               <div className="col-xl-12 gutter-t">
                 <div className={`row card `}>
                 <div className="col-xl-12 card-header">
                       <h3>HEATING</h3>
                 </div>
                   <div className="col-xl-12  card-body">
                         <Form.Row>

                           <Form.Group  as={Col} >
                           <Form.Label>Θέρμανση</Form.Label>
                           <Form.Control as="select"  name="heating" id="heating">
                             <option value="Not installed/Μη εγκατεστημένη">Μη εγκατεστημένη</option>
                             <option value="Low efficiency/Πετρέλαιο">Πετρέλαιο</option>
                             <option value="Low efficiency measured/Αυτόνομη">Αυτόνομη</option>
                             <option value="High efficiency/Φυσικό αέριο">Φυσικό αέριο</option>
                           </Form.Control>   <Form.Text className="text-muted">
                           Θέρμανση
                           </Form.Text>
                           </Form.Group>
                           <Form.Group  as={Col} >
                           <Form.Label>  Αναλυτικά ή άλλο</Form.Label>
                           <Form.Control  type="text" name="heating_other" id="heating_other" onChange={handleChange}  rows={5} />
                           <Form.Text className="text-muted">
                      Αναλυτικά ή άλλο
                           </Form.Text>
                           </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group  as={Col} >
                            <Form.Label>Συστημα Ζεστού Νερού Χρήσης</Form.Label>
                            <Form.Control  type="text" name="hot_water" id="hot_water" onChange={handleChange}  rows={5} />
                            <Form.Text className="text-muted">
                       Συστημα Ζεστού Νερού Χρήσης Πχ ηλεκτρικός θερμοσίφωνας, ηλιακός θερμοσίφωνας, boiler κτλ.
                            </Form.Text>
                            </Form.Group>
                           </Form.Row>
                    </div>

                   </div>

                </div>
        {/*next*/}
        {/*next*/}

           <div className="col-xl-12 gutter-t">
             <div className={`row card `}>
             <div className="col-xl-12 card-header">
                   <h3> ΑΜΕΣΟΙ ΚΙΝΔΥΝΟΙ ΠΕΡΙΜΕΤΡΟΥ & ΑΝΤΙΜΕΤΩΠΙΣΗ 					</h3>
             </div>
               <div className="col-xl-12  card-body">
                     <Form.Row>

                       <Form.Group  as={Col} >
                       <Form.Label>  Παραβίασης/Καταπάτησης Χώρου</Form.Label>
                       <Form.Control as="select"  name="danger_violation" id="danger_violation">
                       <option value="N/A">N/A</option>
                       <option value="Όχι">Όχι</option>
                       <option value="Ναι">Ναι</option>

                       </Form.Control>
                         <Form.Text className="text-muted">
                      Παραβίασης/Καταπάτησης Χώρου
                       </Form.Text>
                       </Form.Group>
                       <Form.Group  as={Col} >
                       <Form.Label>  Περιγραφή Κινδύνου</Form.Label>
                       <Form.Control  type="text" name="danger_violation_text" id="danger_violation_text" onChange={handleChange}  rows={5} />
                       <Form.Text className="text-muted">
                  Περιγραφή Κινδύνου
                       </Form.Text>
                       </Form.Group>
                      </Form.Row>
                      <Form.Row>

                        <Form.Group  as={Col} >
                        <Form.Label> Διαρροής/Εισροής Υδάτων</Form.Label>
                        <Form.Control as="select"  name="danger_water" id="danger_water">
                        <option value="N/A">N/A</option>
                        <option value="Όχι">Όχι</option>
                        <option value="Ναι">Ναι</option>

                        </Form.Control>
                          <Form.Text className="text-muted">
                       Διαρροής/Εισροής Υδάτων
                        </Form.Text>
                        </Form.Group>
                        <Form.Group  as={Col} >
                        <Form.Label>Περιγραφή Κινδύνου</Form.Label>
                        <Form.Control  type="text" name="danger_water_text" id="danger_water_text" onChange={handleChange}  rows={5} />
                        <Form.Text className="text-muted">
                   Περιγραφή Κινδύνου
                        </Form.Text>
                        </Form.Group>
                       </Form.Row>
                       <Form.Row>

                         <Form.Group  as={Col} >
                         <Form.Label>  Σωματικής Ακεραιότητας</Form.Label>
                         <Form.Control as="select"  name="danger_physical" id="danger_physical">
                         <option value="N/A">N/A</option>
                         <option value="Όχι">Όχι</option>
                         <option value="Ναι">Ναι</option>

                         </Form.Control>
                           <Form.Text className="text-muted">
                    Σωματικής Ακεραιότητας
                         </Form.Text>
                         </Form.Group>
                         <Form.Group  as={Col} >
                         <Form.Label>  Περιγραφή Κινδύνου</Form.Label>
                         <Form.Control  type="text" name="danger_physical_text" id="danger_physical_text" onChange={handleChange}  rows={5} />
                         <Form.Text className="text-muted">
                    Περιγραφή Κινδύνου
                         </Form.Text>
                         </Form.Group>
                        </Form.Row>
                        <Form.Row>

                          <Form.Group  as={Col} >
                          <Form.Label>Επικινδυνότητας Κατασκευής</Form.Label>
                          <Form.Control as="select"  name="danger_construction" id="danger_construction">
                          <option value="N/A">N/A</option>
                          <option value="Όχι">Όχι</option>
                          <option value="Ναι">Ναι</option>

                          </Form.Control>
                            <Form.Text className="text-muted">
                        Επικινδυνότητας Κατασκευής
                          </Form.Text>
                          </Form.Group>
                          <Form.Group  as={Col} >
                          <Form.Label> Περιγραφή Κινδύνου</Form.Label>
                          <Form.Control  type="text" name="danger_construction_text" id="danger_construction_text" onChange={handleChange}  rows={5} />
                          <Form.Text className="text-muted">
                     Περιγραφή Κινδύνου
                          </Form.Text>
                          </Form.Group>
                         </Form.Row>
                         <Form.Row>

                           <Form.Group  as={Col} >
                           <Form.Label>Πυρκαγιάς</Form.Label>
                           <Form.Control as="select"  name="danger_fire" id="danger_fire">
                           <option value="N/A">N/A</option>
                           <option value="Όχι">Όχι</option>
                           <option value="Ναι">Ναι</option>

                           </Form.Control>
                             <Form.Text className="text-muted">
                        Πυρκαγιάς
                           </Form.Text>
                           </Form.Group>
                           <Form.Group  as={Col} >
                           <Form.Label>  Περιγραφή Κινδύνου</Form.Label>
                           <Form.Control  type="text" name="danger_fire_text" id="danger_fire_text" onChange={handleChange}  rows={5} />
                           <Form.Text className="text-muted">
                      Περιγραφή Κινδύνου
                           </Form.Text>
                           </Form.Group>
                          </Form.Row>
                          <Form.Row>

                            <Form.Group  as={Col} >
                            <Form.Label>Περιβαντολλογικοί Κίνδυνοι</Form.Label>
                            <Form.Control as="select"  name="danger_environment" id="danger_environment">
                            <option value="N/A">N/A</option>
                            <option value="Όχι">Όχι</option>
                            <option value="Ναι">Ναι</option>

                            </Form.Control>
                              <Form.Text className="text-muted">
                          Περιβαντολλογικοί Κίνδυνοι
                            </Form.Text>
                            </Form.Group>
                            <Form.Group  as={Col} >
                            <Form.Label>   Περιγραφή Κινδύνου</Form.Label>
                            <Form.Control  type="text" name="danger_environment_text" id="danger_environment_text" onChange={handleChange}  rows={5} />
                            <Form.Text className="text-muted">
                       Περιγραφή Κινδύνου
                            </Form.Text>
                            </Form.Group>
                           </Form.Row>
                           <Form.Row>

                             <Form.Group  as={Col} >
                             <Form.Label>  Άλλο</Form.Label>
                             <Form.Control as="select"  name="danger_other" id="danger_other">
                             <option value="N/A">N/A</option>
                             <option value="Όχι">Όχι</option>
                             <option value="Ναι">Ναι</option>

                             </Form.Control>
                               <Form.Text className="text-muted">
                          Άλλο
                             </Form.Text>
                             </Form.Group>
                             <Form.Group  as={Col} >
                             <Form.Label>  Περιγραφή Κινδύνου</Form.Label>
                             <Form.Control  type="text" name="danger_other_text" id="danger_other_text" onChange={handleChange}  rows={5} />
                             <Form.Text className="text-muted">
                        Περιγραφή Κινδύνου
                             </Form.Text>
                             </Form.Group>
                            </Form.Row>

                </div>

               </div>

            </div>
    {/*next*/}


       <div className="col-xl-12 gutter-t">
         <div className={`row card `}>
         <div className="col-xl-12 card-header">
               <h3>ΕΥΡΗΜΑΤΑ ΑΥΤΟΨΙΑΣ 					</h3>
         </div>
           <div className="col-xl-12  card-body">

                 <Form.Group>
                    <Form.Label>Περιγραφή</Form.Label>
                    <Form.Control  as="textarea"  name="audit_comments" id="audit_comments" onChange={handleChange}  rows={10 } />
                    <Form.Text className="text-muted">
                  Ευρήματα αυτοψίας
                    </Form.Text>
                  </Form.Group>


            </div>

           </div>

        </div>
        {/*next*/}


           <div className="col-xl-12 gutter-t">
             <div className={`row card `}>
             <div className="col-xl-12 card-header">
                   <h3>O ΣΥΝΤΑΞΑΣ 					</h3>
             </div>
               <div className="col-xl-12  card-body">

                     <Form.Group>
                        <Form.Label>Περιγραφή</Form.Label>
                        <Form.Control  as="textarea"  name="surveyor" id="surveyor" onChange={handleChange}  rows={10 } />
                        <Form.Text className="text-muted">
                        Ο συντάξας
                        </Form.Text>
                      </Form.Group>


                </div>

               </div>

            </div>
{/*next*/}

     </div>
</div>
<div className="row">
  <div className="col-xl-12">
    <div className="row">

      <div className="col-xl-12">

          {data!==null?
          <div>
          <br/>
          {data.ErrorCode!=0?<>
            <Alert severity="error">Υπάρχει ήδη αυτος ο κωδικός ακινήτου</Alert>
          </>:

          <Alert>Επιτυχής αποθήκευση με κωδικό   :{data.insertedId}</Alert>
          }
          </div>
          :<></>}
          <br/><br/>
          {loading ?    <Loader
      type="Bars"
      color="#00BFFF"
      height={10}
      width={50}
      />:
              <button className="btn btn-light-primary btn-bold">
              Αποθήκευση </button> }
          <br/>
          </div>
        </div>
      </div>
      </div>
  </Form>
    </>

  );


}
export default InspectionReport;
/*
export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  return (<>My Page</>);
};
*/
