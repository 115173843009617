import React, {useState,useEffect} from 'react';
import Loader from "react-loader-spinner";
import {useSubheader} from "../../_metronic/layout";
import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@material-ui/lab/Alert';
import { Form,Button } from 'react-bootstrap';
import { ContentInventoryAll } from '../modules/Hometrix/ContentInventoryAll'
export const ContentAll = () => {
  const {
     isLoading,
     isAuthenticated,
     error,
     user,
     loginWithRedirect,
     logout,getAccessTokenSilently,
   } = useAuth0();
  const [selectedFile, setSelectedFile] = useState();
  const [childKey, setChildKey] = useState(0);
  const [dataTitle, setDataTitle] = useState();
	const [isSelected, setIsSelected] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const changeHandler = (e) => {
    if (e.target.id=='file') {
        setIsUploaded(false);

  		setSelectedFile(e.target.files[0]);
  		setIsSelected(true);
    }
      if (e.target.id=='title') {
        setDataTitle(e.target.value);
      }
  	};


  const suhbeader = useSubheader();
  suhbeader.setTitle("View all content");

  return(
  <div>

    <ContentInventoryAll  key={childKey}></ContentInventoryAll>

  </div>

	)
};
